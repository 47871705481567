import React, { useState } from 'react';
import { ExpenseFormData, ExpenseAllocation } from '../types';
import { X, Upload, Trash2, Plus } from 'lucide-react';
import { useProfile } from '../contexts/ProfileContext';

type ExpenseFormProps = {
  onClose: () => void;
  onSubmit: (data: ExpenseFormData) => void;
  onDelete?: () => void;
  initialData?: ExpenseFormData;
  loading?: boolean;
  artworks: Record<string, any>;
  collections: Record<string, any>;
};

const categories = [
  'Materials',
  'Tools',
  'Marketing',
  'Rent',
  'Utilities',
  'Events',
  'Shipping',
  'Other'
];

export default function ExpenseForm({ 
  onClose, 
  onSubmit, 
  onDelete, 
  initialData,
  loading,
  artworks,
  collections
}: ExpenseFormProps) {
  const { profile } = useProfile();
  const [selectedType, setSelectedType] = useState<'studio' | 'artwork' | 'collection'>(
    initialData?.type || 'studio'
  );
  const [linkedItems, setLinkedItems] = useState<ExpenseAllocation[]>(
    initialData?.linkedItems || []
  );
  const [invoice, setInvoice] = useState<ExpenseFormData['invoice']>(initialData?.invoice);
  const [totalAmount, setTotalAmount] = useState(initialData?.amount || 0);
  const [remainingAmount, setRemainingAmount] = useState(
    initialData ? initialData.amount - initialData.linkedItems.reduce((sum, item) => sum + item.amount, 0) : 0
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    
    const amount = Number(formData.get('amount'));
    if (isNaN(amount) || amount <= 0) {
      alert('Please enter a valid amount');
      return;
    }

    const date = formData.get('date') as string;
    if (!date) {
      alert('Please select a date');
      return;
    }

    const category = formData.get('category') as string;
    if (!category) {
      alert('Please select a category');
      return;
    }

    const data: ExpenseFormData = {
      description: formData.get('description') as string,
      amount,
      date,
      category,
      type: selectedType,
      linkedItems,
      invoice,
    };
    
    onSubmit(data);
  };

  const handleAddLink = (id: string, type: 'artwork' | 'collection') => {
    if (!linkedItems.some(item => item.id === id)) {
      const defaultAmount = remainingAmount;
      const newItem = { id, type, amount: defaultAmount };
      setLinkedItems([...linkedItems, newItem]);
      setRemainingAmount(0);
    }
  };

  const handleRemoveLink = (id: string) => {
    const item = linkedItems.find(i => i.id === id);
    if (item) {
      setRemainingAmount(prev => prev + item.amount);
      setLinkedItems(linkedItems.filter(i => i.id !== id));
    }
  };

  const handleAllocationChange = (id: string, newAmount: number) => {
    const item = linkedItems.find(i => i.id === id);
    if (!item) return;

    const oldAmount = item.amount;
    const difference = newAmount - oldAmount;

    if (difference > remainingAmount) {
      alert('Cannot allocate more than the remaining amount');
      return;
    }

    setLinkedItems(linkedItems.map(item => 
      item.id === id ? { ...item, amount: newAmount } : item
    ));
    setRemainingAmount(prev => prev - difference);
  };

  const handleTotalAmountChange = (newTotal: number) => {
    const currentAllocated = linkedItems.reduce((sum, item) => sum + item.amount, 0);
    setTotalAmount(newTotal);
    setRemainingAmount(newTotal - currentAllocated);
  };

  const handleInvoiceUrlAdd = () => {
    const url = prompt('Enter invoice URL:');
    if (url) {
      setInvoice({
        url,
        name: url.split('/').pop() || 'invoice'
      });
    }
  };

  const formatDateForInput = (dateString?: string) => {
    if (!dateString) return '';
    return new Date(dateString).toISOString().split('T')[0];
  };

  return (
    <form onSubmit={handleSubmit} className="p-6 space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Description
        </label>
        <input
          type="text"
          name="description"
          defaultValue={initialData?.description}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Amount ({profile.currency.symbol})
        </label>
        <input
          type="number"
          name="amount"
          value={totalAmount}
          onChange={(e) => handleTotalAmountChange(Number(e.target.value))}
          step="0.01"
          min="0.01"
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
          required
        />
        {remainingAmount > 0 && (
          <p className="mt-1 text-sm text-gray-500">
            Remaining to allocate: {profile.currency.symbol}{remainingAmount.toFixed(2)}
          </p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Date
        </label>
        <input
          type="date"
          name="date"
          defaultValue={formatDateForInput(initialData?.date)}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Category
        </label>
        <select
          name="category"
          defaultValue={initialData?.category}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
          required
        >
          <option value="">Select a category</option>
          {categories.map(category => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Type
        </label>
        <div className="flex gap-4">
          <label className="flex items-center gap-2">
            <input
              type="radio"
              checked={selectedType === 'studio'}
              onChange={() => setSelectedType('studio')}
              className="text-indigo-600 focus:ring-indigo-500"
            />
            <span className="text-sm text-gray-700">Studio</span>
          </label>
          <label className="flex items-center gap-2">
            <input
              type="radio"
              checked={selectedType === 'artwork'}
              onChange={() => setSelectedType('artwork')}
              className="text-indigo-600 focus:ring-indigo-500"
            />
            <span className="text-sm text-gray-700">Artwork</span>
          </label>
          <label className="flex items-center gap-2">
            <input
              type="radio"
              checked={selectedType === 'collection'}
              onChange={() => setSelectedType('collection')}
              className="text-indigo-600 focus:ring-indigo-500"
            />
            <span className="text-sm text-gray-700">Collection</span>
          </label>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Invoice
        </label>
        {invoice ? (
          <div className="flex items-center justify-between p-2 bg-gray-50 rounded-lg">
            <a
              href={invoice.url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-indigo-600 hover:text-indigo-700"
            >
              {invoice.name}
            </a>
            <button
              type="button"
              onClick={() => setInvoice(undefined)}
              className="p-1 hover:bg-gray-200 rounded-full"
            >
              <X className="h-4 w-4 text-gray-500" />
            </button>
          </div>
        ) : (
          <button
            type="button"
            onClick={handleInvoiceUrlAdd}
            className="flex items-center gap-2 px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
          >
            <Upload className="h-4 w-4" />
            <span>Add Invoice URL</span>
          </button>
        )}
      </div>

      {selectedType !== 'studio' && (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Link to {selectedType === 'artwork' ? 'Artworks' : 'Collections'}
          </label>
          <select
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 mb-4"
            onChange={(e) => handleAddLink(e.target.value, selectedType === 'artwork' ? 'artwork' : 'collection')}
            value=""
          >
            <option value="">Select to add...</option>
            {selectedType === 'artwork' ? (
              Object.values(artworks)
                .filter(art => !linkedItems.some(item => item.id === art.id))
                .map((art: any) => (
                  <option key={art.id} value={art.id}>
                    {art.title}
                  </option>
                ))
            ) : (
              Object.values(collections)
                .filter(collection => !linkedItems.some(item => item.id === collection.id))
                .map((collection: any) => (
                  <option key={collection.id} value={collection.id}>
                    {collection.name}
                  </option>
                ))
            )}
          </select>

          {linkedItems.length > 0 && (
            <div className="space-y-2">
              {linkedItems.map(item => {
                const itemData = item.type === 'artwork'
                  ? artworks[item.id]
                  : collections[item.id];

                return itemData ? (
                  <div
                    key={`${item.type}-${item.id}`}
                    className="flex items-center justify-between p-2 bg-gray-50 rounded-lg"
                  >
                    <span className="text-sm text-gray-900">
                      {item.type === 'artwork' ? itemData.title : itemData.name}
                    </span>
                    <div className="flex items-center gap-2">
                      <div className="flex items-center gap-1">
                        <span className="text-sm text-gray-500">{profile.currency.symbol}</span>
                        <input
                          type="number"
                          value={item.amount}
                          onChange={(e) => handleAllocationChange(item.id, Number(e.target.value))}
                          className="w-24 px-2 py-1 border rounded-md"
                          min="0"
                          step="0.01"
                          max={item.amount + remainingAmount}
                        />
                      </div>
                      <button
                        type="button"
                        onClick={() => handleRemoveLink(item.id)}
                        className="p-1 hover:bg-gray-200 rounded-full"
                      >
                        <X className="h-4 w-4 text-gray-500" />
                      </button>
                    </div>
                  </div>
                ) : null;
              })}
            </div>
          )}
        </div>
      )}

      <div className="flex items-center justify-between pt-6 border-t">
        {initialData && onDelete && (
          <button
            type="button"
            onClick={onDelete}
            className="flex items-center gap-2 px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg"
          >
            <Trash2 className="h-4 w-4" />
            Delete Expense
          </button>
        )}
        <div className="flex gap-3 ml-auto">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 border rounded-lg hover:bg-gray-50"
            disabled={loading}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
            disabled={loading || remainingAmount < 0}
          >
            {loading ? 'Saving...' : initialData ? 'Save Changes' : 'Add Expense'}
          </button>
        </div>
      </div>
    </form>
  );
}