import React, { useState, useEffect } from 'react';
import { Artist, Collection, ArtPiece } from '../types';
import { Instagram, Linkedin, Globe } from 'lucide-react';
import { useCollections } from '../hooks/useCollections';
import { useArtworks } from '../hooks/useArtworks';
import { useGallery } from '../hooks/useGallery';
import ImageGalleryModal from './ImageGalleryModal';

type PublicProfilePreviewProps = {
  profile: Artist;
};

export default function PublicProfilePreview({ profile }: PublicProfilePreviewProps) {
  const { fetchCollections } = useCollections();
  const { fetchArtworks } = useArtworks();
  const { fetchImages } = useGallery();
  const [collections, setCollections] = useState<Collection[]>([]);
  const [selectedCollection, setSelectedCollection] = useState<Collection | null>(null);
  const [artworks, setArtworks] = useState<Record<string, ArtPiece>>({});
  const [artworkImages, setArtworkImages] = useState<Record<string, string[]>>({});
  const [showGallery, setShowGallery] = useState(false);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [initialImageIndex, setInitialImageIndex] = useState(0);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [collectionsData, artworksData, galleryImages] = await Promise.all([
          fetchCollections(),
          fetchArtworks(),
          fetchImages()
        ]);
        
        setCollections(collectionsData);

        // Create artworks map
        const artworksMap = artworksData.reduce((acc: Record<string, ArtPiece>, artwork: ArtPiece) => {
          acc[artwork.id] = artwork;
          return acc;
        }, {});
        setArtworks(artworksMap);

        // Create artwork images map
        const imagesMap = artworksData.reduce((acc: Record<string, string[]>, artwork: ArtPiece) => {
          const linkedImages = galleryImages
            .filter(img => img.linkedArtworkId === artwork.id)
            .map(img => img.url);
          acc[artwork.id] = [...artwork.images, ...linkedImages];
          return acc;
        }, {});
        setArtworkImages(imagesMap);

        // Set featured collection if specified
        if (profile.featuredCollectionId) {
          const featured = collectionsData.find(c => c.id === profile.featuredCollectionId);
          if (featured) {
            setSelectedCollection(featured);
          }
        }
      } catch (err) {
        console.error('Error loading collections:', err);
      }
    };
    loadData();
  }, [fetchCollections, fetchArtworks, fetchImages, profile.featuredCollectionId]);

  const handleArtworkClick = (artworkId: string, imageIndex: number = 0) => {
    const images = artworkImages[artworkId] || [];
    if (images.length > 0) {
      setSelectedImages(images);
      setInitialImageIndex(imageIndex);
      setShowGallery(true);
    }
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
      {/* Artist Profile Section */}
      <div className="space-y-8">
        <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
          <div className="p-6 space-y-6">
            <div className="flex items-center gap-6">
              {profile.avatar && (
                <img
                  src={profile.avatar}
                  alt={profile.name}
                  className="h-24 w-24 rounded-full object-cover"
                />
              )}
              <div>
                <h2 className="text-2xl font-bold text-gray-900">{profile.name}</h2>
                {profile.companyLogo && (
                  <img
                    src={profile.companyLogo}
                    alt="Company Logo"
                    className="h-8 mt-2"
                  />
                )}
              </div>
            </div>

            {/* Bio & Statement */}
            <div className="space-y-4">
              <div>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">About</h3>
                <p className="text-gray-600">{profile.bio}</p>
              </div>
              {profile.artistStatement && (
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">Artist Statement</h3>
                  <p className="text-gray-600">{profile.artistStatement}</p>
                </div>
              )}
            </div>

            {/* Social Links */}
            <div>
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Connect</h3>
              <div className="flex flex-wrap gap-4">
                {profile.websiteUrl && (
                  <a
                    href={profile.websiteUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center gap-2 px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
                  >
                    <Globe className="h-4 w-4" />
                    Website
                  </a>
                )}
                {profile.instagramUrl && (
                  <a
                    href={profile.instagramUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center gap-2 px-4 py-2 bg-pink-100 text-pink-700 rounded-lg hover:bg-pink-200"
                  >
                    <Instagram className="h-4 w-4" />
                    Instagram
                  </a>
                )}
                {profile.linkedinUrl && (
                  <a
                    href={profile.linkedinUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center gap-2 px-4 py-2 bg-blue-100 text-blue-700 rounded-lg hover:bg-blue-200"
                  >
                    <Linkedin className="h-4 w-4" />
                    LinkedIn
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Featured Collection */}
      {selectedCollection && (
        <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
          <div className="aspect-video bg-gray-100 relative">
            {selectedCollection.coverImage ? (
              <img
                src={selectedCollection.coverImage}
                alt={selectedCollection.name}
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="absolute inset-0 flex items-center justify-center">
                <span className="text-gray-400">No cover image</span>
              </div>
            )}
          </div>
          
          <div className="p-6 space-y-6">
            <div>
              <h3 className="text-2xl font-bold text-gray-900 mb-2">{selectedCollection.name}</h3>
              <p className="text-gray-600">{selectedCollection.description}</p>
            </div>

            {selectedCollection.artistStatement && (
              <div>
                <h4 className="text-lg font-semibold text-gray-900 mb-2">Collection Statement</h4>
                <p className="text-gray-600">{selectedCollection.artistStatement}</p>
              </div>
            )}

            {/* Collection Artworks */}
            <div>
              <h4 className="text-lg font-semibold text-gray-900 mb-4">Artworks in Collection</h4>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {selectedCollection.pieces.map(pieceId => {
                  const artwork = artworks[pieceId];
                  if (!artwork) return null;

                  return (
                    <div 
                      key={pieceId} 
                      className="bg-gray-50 rounded-lg overflow-hidden cursor-pointer"
                      onClick={() => handleArtworkClick(artwork.id)}
                    >
                      <div className="aspect-square">
                        {artwork.images[0] && (
                          <img
                            src={artwork.images[0]}
                            alt={artwork.title}
                            className="w-full h-full object-cover hover:opacity-90 transition-opacity"
                          />
                        )}
                      </div>
                      <div className="p-4">
                        <h5 className="font-medium text-gray-900">{artwork.title}</h5>
                        {artwork.materials && (
                          <p className="text-sm text-gray-500 mt-1">
                            {artwork.materials.join(', ')}
                          </p>
                        )}
                        {artwork.status === 'available' && artwork.price && (
                          <p className="text-sm font-medium text-gray-900 mt-2">
                            {profile.currency.symbol}{artwork.price.toLocaleString()}
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Image Gallery Modal */}
      {showGallery && (
        <ImageGalleryModal
          images={selectedImages}
          initialIndex={initialImageIndex}
          onClose={() => setShowGallery(false)}
        />
      )}
    </div>
  );
}