import React from 'react';
import { LayoutGrid, List } from 'lucide-react';

type ViewToggleProps = {
  view: 'grid' | 'list';
  onViewChange: (view: 'grid' | 'list') => void;
  showTableView?: boolean;
};

export default function ViewToggle({ view, onViewChange }: ViewToggleProps) {
  return (
    <div className="flex bg-white border rounded-lg p-1">
      <button
        onClick={() => onViewChange('grid')}
        className={`p-2 rounded-md transition-colors ${
          view === 'grid'
            ? 'bg-gray-100 text-gray-900'
            : 'text-gray-500 hover:text-gray-700'
        }`}
      >
        <LayoutGrid className="h-5 w-5" />
      </button>
      <button
        onClick={() => onViewChange('list')}
        className={`p-2 rounded-md transition-colors ${
          view === 'list'
            ? 'bg-gray-100 text-gray-900'
            : 'text-gray-500 hover:text-gray-700'
        }`}
      >
        <List className="h-5 w-5" />
      </button>
    </div>
  );
}