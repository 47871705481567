import React from 'react';
import { useOnboarding } from '../contexts/OnboardingContext';
import StudioAssistant from '../components/StudioAssistant';
import DashboardContent from '../components/DashboardContent';
import LoadingSpinner from '../components/LoadingSpinner';

export default function Dashboard() {
  const { isOnboardingComplete, loading, refreshOnboarding } = useOnboarding();

  // Refresh onboarding status when dashboard loads
  React.useEffect(() => {
    refreshOnboarding();
  }, [refreshOnboarding]);

  if (loading) {
    return <LoadingSpinner />;
  }

  // Show Studio Assistant if onboarding is not complete
  if (!isOnboardingComplete) {
    return <StudioAssistant />;
  }

  return <DashboardContent />;
}