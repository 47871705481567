import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Image, Cloud, Tag, Share2, Search, Filter } from 'lucide-react';
import MainHeader from '../../components/MainHeader';
import Footer from '../../components/Footer';

export default function PhotoLibrary() {
  return (
    <>
      <Helmet>
        <title>Art Photo Management Software | Studio Planner</title>
        <meta 
          name="description" 
          content="Professional photo management software for artists. Organize artwork photos, manage digital assets, and showcase your work effectively."
        />
        <meta 
          name="keywords" 
          content="art photo management, artwork photography, digital asset management, artist portfolio"
        />
      </Helmet>

      <MainHeader />

      <main>
        {/* Hero Section */}
        <div className="bg-gradient-to-b from-cyan-50 to-white">
          <div className="container mx-auto px-6 py-24">
            <div className="max-w-3xl mx-auto text-center">
              <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
                Professional Photo Management
              </h1>
              <p className="text-xl text-gray-600 mb-8">
                Keep your artwork photos organized and accessible. Store, manage, and share your digital assets efficiently.
              </p>
              <div className="flex justify-center gap-4">
                <Link
                  to="/register"
                  className="px-8 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Features Grid */}
        <div className="container mx-auto px-6 py-24">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-cyan-100 text-cyan-600 rounded-lg flex items-center justify-center mb-4">
                <Image className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Photo Organization</h3>
              <p className="text-gray-600">
                Keep your artwork photos organized with intuitive management tools.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-blue-100 text-blue-600 rounded-lg flex items-center justify-center mb-4">
                <Cloud className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Cloud Storage</h3>
              <p className="text-gray-600">
                Store and access your high-resolution photos securely in the cloud.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-green-100 text-green-600 rounded-lg flex items-center justify-center mb-4">
                <Tag className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Smart Tagging</h3>
              <p className="text-gray-600">
                Tag and categorize photos for quick and easy searching.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-purple-100 text-purple-600 rounded-lg flex items-center justify-center mb-4">
                <Share2 className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Easy Sharing</h3>
              <p className="text-gray-600">
                Share photos with galleries, clients, or on social media.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-pink-100 text-pink-600 rounded-lg flex items-center justify-center mb-4">
                <Search className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Quick Search</h3>
              <p className="text-gray-600">
                Find photos instantly with powerful search capabilities.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-orange-100 text-orange-600 rounded-lg flex items-center justify-center mb-4">
                <Filter className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Advanced Filtering</h3>
              <p className="text-gray-600">
                Filter photos by date, collection, artwork, or custom criteria.
              </p>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="bg-white">
          <div className="container mx-auto px-6 py-24">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-bold text-gray-900 mb-6">
                Ready to organize your artwork photos?
              </h2>
              <p className="text-xl text-gray-600 mb-8">
                Join other artists who are using Studio Planner to manage their digital assets professionally.
              </p>
              <Link
                to="/register"
                className="inline-block px-8 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
}