import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updateProfile,
  User,
  deleteUser
} from 'firebase/auth';
import { doc, setDoc, getDoc, collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { auth, db } from './firebase';

export async function registerUser(email: string, password: string, name: string, inviteCode?: string) {
  let createdUser: User | null = null;

  try {
    // Validate inputs
    if (!email?.trim()) throw new Error('Email is required');
    if (!password?.trim()) throw new Error('Password is required');
    if (!name?.trim()) throw new Error('Name is required');
    
    // Create Firebase auth user
    const { user } = await createUserWithEmailAndPassword(auth, email, password);
    createdUser = user;
    
    // Update user profile with name
    await updateProfile(user, { displayName: name });

    // Generate referral code from name
    const nameBase = name.replace(/[^a-zA-Z]/g, '').substring(0, 3).toUpperCase();
    const timestamp = Date.now().toString(36).substring(4, 7);
    const randomChars = Math.random().toString(36).substring(2, 5).toUpperCase();
    const referralCode = `${nameBase}${timestamp}${randomChars}`;

    // Create base artist data
    const artistData: any = {
      name,
      email,
      bio: '',
      currency: { code: 'USD', symbol: '$' },
      storageLimit: 500 * 1024 * 1024, // 500MB base storage
      storageBonus: 0,
      referralCode,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      isPublic: false
    };

    // Find referrer if invite code was provided
    if (inviteCode?.trim()) {
      const q = query(
        collection(db, 'artists'),
        where('referralCode', '==', inviteCode.trim())
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs.length > 0) {
        artistData.referrerId = querySnapshot.docs[0].id;
      }
    }

    // Create artist profile
    const artistRef = doc(db, 'artists', user.uid);
    await setDoc(artistRef, artistData);

    // Verify the artist profile was created
    const artistDoc = await getDoc(artistRef);
    if (!artistDoc.exists()) {
      throw new Error('Failed to create artist profile');
    }

    return user;
  } catch (error: any) {
    // If user was created but profile creation failed, clean up the user
    if (createdUser) {
      try {
        await deleteUser(createdUser);
      } catch (deleteError) {
        console.error('Error cleaning up user after failed registration:', deleteError);
      }
    }

    // Map Firebase errors to user-friendly messages
    switch (error.code) {
      case 'auth/email-already-in-use':
        throw new Error('This email is already registered. Please try logging in instead.');
      case 'auth/weak-password':
        throw new Error('Password should be at least 6 characters long.');
      case 'auth/invalid-email':
        throw new Error('Please enter a valid email address.');
      case 'auth/network-request-failed':
        throw new Error('Network error. Please check your internet connection and try again.');
      case 'auth/too-many-requests':
        throw new Error('Too many attempts. Please try again later.');
      case 'auth/invalid-argument':
        throw new Error('Please check your registration details and try again.');
      default:
        console.error('Registration error:', error);
        throw new Error(error.message || 'Failed to create account. Please try again.');
    }
  }
}

export async function loginUser(email: string, password: string): Promise<User> {
  try {
    if (!email?.trim()) throw new Error('Email is required');
    if (!password?.trim()) throw new Error('Password is required');

    const { user } = await signInWithEmailAndPassword(auth, email, password);
    return user;
  } catch (error: any) {
    switch (error.code) {
      case 'auth/invalid-credential':
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        throw new Error('Invalid email or password.');
      case 'auth/too-many-requests':
        throw new Error('Too many failed attempts. Please try again later.');
      case 'auth/network-request-failed':
        throw new Error('Network error. Please check your internet connection.');
      default:
        console.error('Login error:', error);
        throw new Error('Failed to sign in. Please try again.');
    }
  }
}

export async function logoutUser(): Promise<void> {
  try {
    await signOut(auth);
  } catch (error) {
    console.error('Logout error:', error);
    throw new Error('Failed to log out. Please try again.');
  }
}

export async function resetUserPassword(email: string): Promise<void> {
  try {
    if (!email?.trim()) throw new Error('Email is required');
    await sendPasswordResetEmail(auth, email);
  } catch (error: any) {
    switch (error.code) {
      case 'auth/user-not-found':
        throw new Error('No account found with this email.');
      case 'auth/invalid-email':
        throw new Error('Please enter a valid email address.');
      case 'auth/network-request-failed':
        throw new Error('Network error. Please check your internet connection.');
      default:
        console.error('Password reset error:', error);
        throw new Error('Failed to send password reset email. Please try again.');
    }
  }
}