import { z } from 'zod';

// Define all artwork categories
export const artworkCategories = {
  jewelry: 'Jewellery',
  ceramics: 'Ceramics',
  glass: 'Glass'
} as const;

// Define types for each category
export const artworkTypes = {
  jewelry: [
    'Ring',
    'Necklace',
    'Pendant',
    'Brooch',
    'Bangle',
    'Pin',
    'Earrings',
    'Charm',
    'Anklet',
    'Bracelet'
  ],
  ceramics: [
    'Pottery',
    'Tiles',
    'Sculptures',
    'Vases',
    'Bowls',
    'Plates',
    'Porcelain',
    'Stoneware',
    'Earthenware',
    'Mosaics',
    'Figurines'
  ],
  glass: [
    'Stained Glass',
    'Blown Glass',
    'Glass Sculptures',
    'Glassware',
    'Mirrors',
    'Beads',
    'Fused Glass',
    'Lampworking'
  ]
} as const;

// Type for artwork categories
export type ArtworkCategory = keyof typeof artworkCategories;

// Type for artwork types
export type ArtworkType = `${typeof artworkCategories[ArtworkCategory]} - ${typeof artworkTypes[ArtworkCategory][number]}`;

// Define measurement fields for each type
export const measurementFields: Record<string, string[]> = {
  // Jewelry
  'Ring': ['innerDiameter', 'innerCircumference', 'bandWidth', 'bandThickness', 'stoneSize'],
  'Necklace': ['chainLength', 'pendantDropLength', 'claspSize', 'chainThickness'],
  'Pendant': ['height', 'width', 'thickness', 'bailSize'],
  'Brooch': ['length', 'width', 'thickness', 'pinLength', 'weight'],
  'Bangle': ['innerDiameter', 'circumference', 'width', 'thickness'],
  'Pin': ['length', 'decorativeElementSize', 'pinThickness'],
  'Earrings': ['dropLength', 'diameter', 'studSize', 'backingSize'],
  'Charm': ['height', 'width', 'thickness', 'bailSize'],
  'Anklet': ['length', 'chainThickness', 'claspSize'],
  'Bracelet': ['innerDiameter', 'circumference', 'chainLength', 'claspSize', 'thickness'],

  // Ceramics
  'Pottery': ['height', 'baseDiameter', 'openingDiameter', 'wallThickness', 'capacity'],
  'Tiles': ['length', 'width', 'thickness', 'weight'],
  'Sculptures': ['height', 'width', 'depth', 'weight', 'baseLength', 'baseWidth'],
  'Vases': ['height', 'baseDiameter', 'bodyDiameter', 'openingDiameter', 'wallThickness', 'volume'],
  'Bowls': ['rimDiameter', 'baseDiameter', 'height', 'volume', 'wallThickness'],
  'Plates': ['diameter', 'rimWidth', 'height', 'wallThickness'],
  'Porcelain': ['thickness', 'diameter', 'height', 'weight'],
  'Stoneware': ['height', 'width', 'depth', 'wallThickness', 'weight'],
  'Earthenware': ['height', 'width', 'depth', 'thickness', 'porosity'],
  'Mosaics': ['tileSize', 'thickness', 'panelHeight', 'panelWidth'],
  'Figurines': ['height', 'width', 'depth', 'weight', 'baseLength', 'baseWidth'],

  // Glass
  'Stained Glass': ['panelHeight', 'panelWidth', 'glassThickness', 'leadWidth'],
  'Blown Glass': ['height', 'openingDiameter', 'baseDiameter', 'volume', 'wallThickness'],
  'Glass Sculptures': ['height', 'width', 'depth', 'weight', 'baseLength', 'baseWidth'],
  'Glassware': ['height', 'baseDiameter', 'rimDiameter', 'volume', 'wallThickness'],
  'Mirrors': ['height', 'width', 'thickness', 'frameWidth', 'frameDepth'],
  'Beads': ['diameter', 'holeDiameter', 'length', 'thickness'],
  'Fused Glass': ['panelHeight', 'panelWidth', 'thickness', 'layers'],
  'Lampworking': ['length', 'diameter', 'shapeHeight', 'shapeWidth', 'holeSize']
};

// Define measurement labels
export const measurementLabels: Record<string, Record<string, string>> = {
  // Common measurements
  common: {
    height: 'Height (mm)',
    width: 'Width (mm)',
    depth: 'Depth (mm)',
    weight: 'Weight (g)',
    thickness: 'Thickness (mm)',
    diameter: 'Diameter (mm)',
    length: 'Length (mm)',
  },

  // Jewelry specific
  jewelry: {
    innerDiameter: 'Inner Diameter (mm)',
    innerCircumference: 'Inner Circumference (mm)',
    bandWidth: 'Band Width (mm)',
    bandThickness: 'Band Thickness (mm)',
    stoneSize: 'Stone Size (mm)',
    chainLength: 'Chain Length (mm)',
    pendantDropLength: 'Pendant Drop Length (mm)',
    claspSize: 'Clasp Size (mm)',
    chainThickness: 'Chain Thickness (mm)',
    bailSize: 'Bail Size (mm)',
    pinLength: 'Pin Length (mm)',
    dropLength: 'Drop Length (mm)',
    studSize: 'Stud Size (mm)',
    backingSize: 'Backing Size (mm)',
  },

  // Ceramics specific
  ceramics: {
    baseDiameter: 'Base Diameter (mm)',
    openingDiameter: 'Opening Diameter (mm)',
    wallThickness: 'Wall Thickness (mm)',
    capacity: 'Capacity (ml)',
    rimDiameter: 'Rim Diameter (mm)',
    rimWidth: 'Rim Width (mm)',
    volume: 'Volume (ml)',
    porosity: 'Porosity (%)',
    tileSize: 'Tile Size (mm)',
    panelHeight: 'Panel Height (mm)',
    panelWidth: 'Panel Width (mm)',
    baseLength: 'Base Length (mm)',
    baseWidth: 'Base Width (mm)',
  },

  // Glass specific
  glass: {
    glassThickness: 'Glass Thickness (mm)',
    leadWidth: 'Lead Width (mm)',
    frameWidth: 'Frame Width (mm)',
    frameDepth: 'Frame Depth (mm)',
    holeDiameter: 'Hole Diameter (mm)',
    layers: 'Number of Layers',
    shapeHeight: 'Shape Height (mm)',
    shapeWidth: 'Shape Width (mm)',
    holeSize: 'Hole Size (mm)',
  }
};

// Helper function to get measurement fields for a type
export function getMeasurementFields(type: string): string[] {
  return measurementFields[type] || [];
}

// Helper function to get measurement label
export function getMeasurementLabel(type: string, field: string): string {
  // Check type-specific labels first
  for (const category of Object.keys(measurementLabels)) {
    if (measurementLabels[category][field]) {
      return measurementLabels[category][field];
    }
  }
  // Fallback to common labels
  return measurementLabels.common[field] || field;
}

// Create Zod schemas for measurements
export const measurementSchema = z.object({
  value: z.number().optional(),
  unit: z.string().optional()
});

export const measurementsSchema = z.record(measurementSchema);

// Export type for measurements
export type Measurements = z.infer<typeof measurementsSchema>;