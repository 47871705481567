import { useState, useCallback } from 'react';
import { collection, addDoc, updateDoc, doc, deleteDoc, Timestamp, query, where, getDocs, orderBy, limit, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { BlogPost } from '../types';
import slugify from 'slugify';
import { useProfile } from '../contexts/ProfileContext';

export function useBlog() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { profile } = useProfile();

  const getAuthorData = () => ({
    name: profile?.name || 'Anonymous',
    avatar: profile?.avatar || '',
    bio: profile?.bio || ''
  });

  const fetchPosts = useCallback(async (options?: { 
    limit?: number;
    tag?: string;
  }) => {
    try {
      setLoading(true);
      setError(null);

      let q = query(
        collection(db, 'blog_posts'),
        orderBy('publishedAt', 'desc')
      );

      if (options?.tag) {
        q = query(q, where('tags', 'array-contains', options.tag));
      }

      if (options?.limit) {
        q = query(q, limit(options.limit));
      }

      const querySnapshot = await getDocs(q);
      const posts = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          publishedAt: data.publishedAt?.toDate()?.toISOString() || new Date().toISOString(),
          updatedAt: data.updatedAt?.toDate()?.toISOString(),
          author: data.author || getAuthorData()
        };
      }) as BlogPost[];

      return posts;
    } catch (err) {
      console.error('Error fetching blog posts:', err);
      setError(err as Error);
      return [];
    } finally {
      setLoading(false);
    }
  }, [profile]);

  const fetchPostBySlug = useCallback(async (slug: string) => {
    try {
      setLoading(true);
      setError(null);

      // First try to get from static HTML
      try {
        const response = await fetch(`/blog/${slug}.html`);
        if (response.ok) {
          const html = await response.text();
          // Parse the HTML to extract metadata
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, 'text/html');
          
          return {
            id: slug,
            title: doc.title,
            content: doc.querySelector('article')?.innerHTML || '',
            publishedAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            author: {
              name: doc.querySelector('meta[name="author"]')?.getAttribute('content') || 'Anonymous',
              avatar: '',
              bio: ''
            },
            tags: [],
            seo: {
              title: doc.title,
              description: doc.querySelector('meta[name="description"]')?.getAttribute('content') || '',
              keywords: doc.querySelector('meta[name="keywords"]')?.getAttribute('content')?.split(',') || [],
              ogImage: doc.querySelector('meta[property="og:image"]')?.getAttribute('content') || ''
            }
          } as BlogPost;
        }
      } catch (err) {
        console.warn('Failed to load static HTML, falling back to Firestore');
      }

      // Fallback to Firestore
      const q = query(
        collection(db, 'blog_posts'),
        where('slug', '==', slug),
        limit(1)
      );

      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        throw new Error('Post not found');
      }

      const doc = querySnapshot.docs[0];
      const data = doc.data();

      return {
        id: doc.id,
        ...data,
        publishedAt: data.publishedAt?.toDate()?.toISOString() || new Date().toISOString(),
        updatedAt: data.updatedAt?.toDate()?.toISOString(),
        author: data.author || getAuthorData()
      } as BlogPost;
    } catch (err) {
      console.error('Error fetching blog post:', err);
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [profile]);

  const addPost = useCallback(async (data: Omit<BlogPost, 'id' | 'slug' | 'author'>) => {
    try {
      setLoading(true);
      setError(null);

      const slug = slugify(data.title, { lower: true, strict: true });
      const now = Timestamp.now();
      
      const postData = {
        ...data,
        slug,
        author: getAuthorData(),
        publishedAt: now,
        updatedAt: now
      };

      const docRef = await addDoc(collection(db, 'blog_posts'), postData);
      return docRef.id;
    } catch (err) {
      console.error('Error adding blog post:', err);
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [profile]);

  const updatePost = useCallback(async (id: string, data: Partial<BlogPost>) => {
    try {
      setLoading(true);
      setError(null);

      const updates: any = {
        ...data,
        updatedAt: Timestamp.now()
      };

      if (data.title) {
        updates.slug = slugify(data.title, { lower: true, strict: true });
      }

      const docRef = doc(db, 'blog_posts', id);
      await updateDoc(docRef, updates);

      // Trigger static generation after update
      try {
        await fetch('/api/regenerate-static', { method: 'POST' });
      } catch (err) {
        console.warn('Failed to trigger static regeneration:', err);
      }
    } catch (err) {
      console.error('Error updating blog post:', err);
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const deletePost = useCallback(async (id: string) => {
    try {
      setLoading(true);
      setError(null);
      await deleteDoc(doc(db, 'blog_posts', id));
    } catch (err) {
      console.error('Error deleting blog post:', err);
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    fetchPosts,
    fetchPostBySlug,
    addPost,
    updatePost,
    deletePost,
    loading,
    error
  };
}