import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

type Screenshot = {
  url: string;
  alt: string;
  description: string;
};

const screenshots: Screenshot[] = [
  {
    url: "https://firebasestorage.googleapis.com/v0/b/studioplanner-5d3ea.firebasestorage.app/o/Screenshots%2FArt%20inventory%20management.png?alt=media&token=7a5b8713-2c8a-4c5e-a990-a920aa456a44",
    alt: "Art Inventory Management Interface",
    description: "Comprehensive artwork inventory management"
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/studioplanner-5d3ea.firebasestorage.app/o/Screenshots%2FPhoto%20management%20for%20artists.png?alt=media&token=134de3aa-340b-4d65-9347-82b9e2a8d7e7",
    alt: "Photo Gallery Management Interface",
    description: "Professional photo management tools"
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/studioplanner-5d3ea.firebasestorage.app/o/Screenshots%2FCalendar%20for%20artists.png?alt=media&token=9d63d1d6-8040-4cc3-8ddc-939067a592a7",
    alt: "Calendar and Task Management Interface",
    description: "Intuitive calendar and task management"
  }
];

export default function ScreenshotSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      handleNext();
    }, 5000);

    return () => clearInterval(timer);
  }, [currentIndex]);

  const handlePrevious = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentIndex((prev) => (prev === 0 ? screenshots.length - 1 : prev - 1));
    setTimeout(() => setIsTransitioning(false), 500);
  };

  const handleNext = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentIndex((prev) => (prev === screenshots.length - 1 ? 0 : prev + 1));
    setTimeout(() => setIsTransitioning(false), 500);
  };

  return (
    <div className="relative max-w-4xl mx-auto">
      <div className="aspect-[16/9] bg-gray-100 rounded-lg overflow-hidden">
        <div className="relative w-full h-full">
          {screenshots.map((screenshot, index) => (
            <div
              key={index}
              className={`absolute inset-0 transition-opacity duration-500 ${
                index === currentIndex ? 'opacity-100' : 'opacity-0'
              }`}
            >
              <img
                src={screenshot.url}
                alt={screenshot.alt}
                className="w-full h-full object-cover"
              />
              <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/50 to-transparent p-6">
                <p className="text-white text-lg font-medium">{screenshot.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Navigation Buttons */}
      <button
        onClick={handlePrevious}
        className="absolute left-4 top-1/2 -translate-y-1/2 p-2 bg-white/80 hover:bg-white rounded-full shadow-lg transition-colors"
        disabled={isTransitioning}
      >
        <ChevronLeft className="h-6 w-6" />
      </button>
      <button
        onClick={handleNext}
        className="absolute right-4 top-1/2 -translate-y-1/2 p-2 bg-white/80 hover:bg-white rounded-full shadow-lg transition-colors"
        disabled={isTransitioning}
      >
        <ChevronRight className="h-6 w-6" />
      </button>

      {/* Dots Navigation */}
      <div className="absolute -bottom-6 left-0 right-0 flex justify-center gap-2">
        {screenshots.map((_, index) => (
          <button
            key={index}
            onClick={() => {
              if (!isTransitioning) {
                setIsTransitioning(true);
                setCurrentIndex(index);
                setTimeout(() => setIsTransitioning(false), 500);
              }
            }}
            className={`w-2 h-2 rounded-full transition-all ${
              index === currentIndex
                ? 'bg-indigo-600 w-4'
                : 'bg-gray-300 hover:bg-gray-400'
            }`}
            disabled={isTransitioning}
          />
        ))}
      </div>
    </div>
  );
}