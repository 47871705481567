import React from 'react';
import { X, Palette, BoxesIcon, Image, HardDrive, Calendar, Building2, Receipt, Briefcase, Sparkles } from 'lucide-react';
import { Artist } from '../../types';
import { Timestamp } from 'firebase/firestore';

type UserMetrics = {
  totalArtworks: number;
  totalCollections: number;
  totalPhotos: number;
  totalStorage: number;
  totalTasks: number;
  totalOpenCalls: number;
  totalGalleries: number;
  totalExpenses: number;
  totalPromotions: number;
  onboardingComplete: boolean;
};

type UserProfileModalProps = {
  user: Artist | null;
  metrics: UserMetrics | null;
  loading?: boolean;
  onClose: () => void;
};

// Helper function to format Firestore timestamp
const formatDate = (timestamp: Timestamp | string) => {
  if (!timestamp) return '';
  const date = timestamp instanceof Timestamp ? timestamp.toDate() : new Date(timestamp);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

export default function UserProfileModal({ user, metrics, loading, onClose }: UserProfileModalProps) {
  if (!user) return null;

  const formatBytes = (bytes: number) => {
    if (!bytes) return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6 border-b sticky top-0 bg-white z-10">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold">User Profile</h2>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-lg"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>

        <div className="p-6">
          {/* User Info */}
          <div className="flex items-center gap-4 mb-8">
            {user.avatar ? (
              <img
                src={user.avatar}
                alt={user.name}
                className="h-20 w-20 rounded-full"
              />
            ) : (
              <div className="h-20 w-20 rounded-full bg-gray-200 flex items-center justify-center">
                <span className="text-2xl font-bold text-gray-400">
                  {user.name?.charAt(0)}
                </span>
              </div>
            )}
            <div>
              <h3 className="text-xl font-semibold">{user.name}</h3>
              <p className="text-gray-500">{user.email}</p>
              <p className="text-sm text-gray-500">
                Joined {formatDate(user.createdAt)}
              </p>
            </div>
          </div>

          {/* Metrics Grid */}
          {loading ? (
            <div className="flex items-center justify-center py-12">
              <div className="animate-spin rounded-full h-8 w-8 border-2 border-indigo-600 border-t-transparent"></div>
            </div>
          ) : metrics ? (
            <div className="grid grid-cols-2 gap-4">
              <div className="p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center gap-2 text-gray-600 mb-1">
                  <Palette className="h-4 w-4" />
                  <span className="text-sm">Artworks</span>
                </div>
                <div className="text-2xl font-bold">{metrics.totalArtworks}</div>
              </div>

              <div className="p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center gap-2 text-gray-600 mb-1">
                  <BoxesIcon className="h-4 w-4" />
                  <span className="text-sm">Collections</span>
                </div>
                <div className="text-2xl font-bold">{metrics.totalCollections}</div>
              </div>

              <div className="p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center gap-2 text-gray-600 mb-1">
                  <Image className="h-4 w-4" />
                  <span className="text-sm">Photos</span>
                </div>
                <div className="text-2xl font-bold">{metrics.totalPhotos}</div>
              </div>

              <div className="p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center gap-2 text-gray-600 mb-1">
                  <HardDrive className="h-4 w-4" />
                  <span className="text-sm">Storage Used</span>
                </div>
                <div className="text-2xl font-bold">{formatBytes(metrics.totalStorage)}</div>
              </div>

              <div className="p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center gap-2 text-gray-600 mb-1">
                  <Calendar className="h-4 w-4" />
                  <span className="text-sm">Tasks</span>
                </div>
                <div className="text-2xl font-bold">{metrics.totalTasks}</div>
              </div>

              <div className="p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center gap-2 text-gray-600 mb-1">
                  <Briefcase className="h-4 w-4" />
                  <span className="text-sm">Open Calls</span>
                </div>
                <div className="text-2xl font-bold">{metrics.totalOpenCalls}</div>
              </div>

              <div className="p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center gap-2 text-gray-600 mb-1">
                  <Building2 className="h-4 w-4" />
                  <span className="text-sm">Galleries</span>
                </div>
                <div className="text-2xl font-bold">{metrics.totalGalleries}</div>
              </div>

              <div className="p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center gap-2 text-gray-600 mb-1">
                  <Receipt className="h-4 w-4" />
                  <span className="text-sm">Expenses</span>
                </div>
                <div className="text-2xl font-bold">{metrics.totalExpenses}</div>
              </div>

              <div className="p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center gap-2 text-gray-600 mb-1">
                  <Sparkles className="h-4 w-4" />
                  <span className="text-sm">AI Promotions</span>
                </div>
                <div className="text-2xl font-bold">{metrics.totalPromotions}</div>
              </div>
            </div>
          ) : null}

          {/* Additional User Info */}
          <div className="mt-8 space-y-6">
            {user.bio && (
              <div>
                <h4 className="font-medium text-gray-900 mb-2">Bio</h4>
                <p className="text-gray-600">{user.bio}</p>
              </div>
            )}
            {user.artistStatement && (
              <div>
                <h4 className="font-medium text-gray-900 mb-2">Artist Statement</h4>
                <p className="text-gray-600">{user.artistStatement}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}