import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  organization: import.meta.env.VITE_OPENAI_ORG_ID,
  dangerouslyAllowBrowser: true
});

type PromotionType = 'instagram' | 'blog' | 'pr';
type ToneOfVoice = 'playful' | 'informative' | 'excited' | 'artistic';

export async function generatePromotion(
  type: PromotionType,
  tone: ToneOfVoice,
  artwork?: { 
    title: string; 
    description: string; 
    materials: string[]; 
    price: number; 
    measurements?: any;
    shopLink?: string;
    currency?: { code: string; symbol: string; }
  },
  collection?: { 
    name: string; 
    description: string; 
    artistStatement?: string 
  },
  followUpPrompt?: string
) {
  try {
    let prompt = '';
    let maxTokens = 0;

    // Build context from artwork or collection details
    const context = artwork
      ? `Artwork Details:
Title: ${artwork.title}
Description: ${artwork.description}
Materials: ${artwork.materials.join(', ')}
Price: ${artwork.currency?.symbol || '$'}${artwork.price}
${artwork.measurements ? `Measurements: ${Object.entries(artwork.measurements)
  .filter(([_, value]) => value)
  .map(([key, value]) => `${key}: ${value}`)
  .join(', ')}` : ''}
${artwork.shopLink ? `Shop Link: ${artwork.shopLink}` : ''}`
      : collection
      ? `Collection Details:
Name: ${collection.name}
Description: ${collection.description}
${collection.artistStatement ? `Artist Statement: ${collection.artistStatement}` : ''}`
      : '';

    // Add tone instruction
    const toneInstruction = {
      playful: "Use a light-hearted, fun, and engaging tone with playful language and emojis.",
      informative: "Use a professional, educational tone focusing on details and craftsmanship.",
      excited: "Use an enthusiastic, energetic tone with emphasis on uniqueness and appeal.",
      artistic: "Use a poetic, creative tone emphasizing artistic vision and inspiration."
    }[tone];

    // Build type-specific prompt
    switch (type) {
      case 'instagram':
        prompt = `Create an engaging Instagram post for:
${context}

Tone Instructions: ${toneInstruction}

Format the response as follows:
Main caption with emojis

[Double line break]

Dimensions (if provided)

[Double line break]

${artwork?.shopLink ? `Shop now: ${artwork.shopLink}

[Double line break]` : ''}

Relevant hashtags (at least 15)

${followUpPrompt || ''}

Note: Exclude any quote marks, numbers, or bullet points from the response.`;
        maxTokens = 300;
        break;

      case 'blog':
        prompt = `Write a detailed blog post about:
${context}

Tone Instructions: ${toneInstruction}

Include:
Engaging introduction
Technical details and craftsmanship
Artistic inspiration
Materials and process
Significance and meaning
${artwork?.shopLink ? `Call to action with shop link: ${artwork.shopLink}` : ''}

${followUpPrompt || ''}

Note: Write in a natural flow without numbers or bullet points.`;
        maxTokens = 1000;
        break;

      case 'pr':
        prompt = `Write a professional press release about:
${context}

Tone Instructions: ${toneInstruction}

Include:
Compelling headline
Location and date
Main announcement
Quotes from the artist
Background information
Contact information
${artwork?.shopLink ? `Where to purchase: ${artwork.shopLink}` : ''}

${followUpPrompt || ''}

Note: Format as a standard press release without numbers or bullet points.`;
        maxTokens = 800;
        break;
    }

    const response = await openai.chat.completions.create({
      model: "gpt-4",
      messages: [
        {
          role: "system",
          content: "You are a professional art marketing specialist who creates engaging content for artists. Write in a natural, authentic voice and avoid generic marketing language. Never use numbers, bullet points, or ordered lists in your responses."
        },
        {
          role: "user",
          content: prompt
        }
      ],
      max_tokens: maxTokens,
      temperature: 0.7,
    });

    return response.choices[0].message.content;
  } catch (error) {
    console.error('Error generating promotion:', error);
    throw error;
  }
}