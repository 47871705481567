import { useState, useCallback } from 'react';
import { collection, addDoc, updateDoc, doc, deleteDoc, Timestamp, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Gallery } from '../types';

export function useGalleries() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchGalleries = useCallback(async () => {
    if (!currentUser) return [];

    try {
      setLoading(true);
      setError(null);
      const q = query(
        collection(db, 'galleries'),
        where('artistId', '==', currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const galleries = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate().toISOString(),
        updatedAt: doc.data().updatedAt?.toDate()?.toISOString()
      })) as Gallery[];
      return galleries;
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  const addGallery = useCallback(async (data: Omit<Gallery, 'id' | 'artistId' | 'createdAt'>) => {
    if (!currentUser) throw new Error('User not authenticated');

    try {
      setLoading(true);
      setError(null);
      const docRef = await addDoc(collection(db, 'galleries'), {
        ...data,
        artistId: currentUser.uid,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now()
      });
      return docRef.id;
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  const updateGallery = useCallback(async (id: string, data: Partial<Gallery>) => {
    if (!currentUser) throw new Error('User not authenticated');

    try {
      setLoading(true);
      setError(null);
      const docRef = doc(db, 'galleries', id);
      await updateDoc(docRef, {
        ...data,
        updatedAt: Timestamp.now()
      });
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  const deleteGallery = useCallback(async (id: string) => {
    if (!currentUser) throw new Error('User not authenticated');

    try {
      setLoading(true);
      setError(null);
      await deleteDoc(doc(db, 'galleries', id));
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  const parseCsvLine = (line: string): string[] => {
    const result: string[] = [];
    let current = '';
    let inQuotes = false;
    
    for (let i = 0; i < line.length; i++) {
      const char = line[i];
      
      if (char === '"') {
        inQuotes = !inQuotes;
        continue;
      }
      
      if (char === ',' && !inQuotes) {
        result.push(current.trim());
        current = '';
        continue;
      }
      
      current += char;
    }
    
    result.push(current.trim());
    return result;
  };

  const importGalleries = useCallback(async (text: string) => {
    if (!currentUser) throw new Error('User not authenticated');

    try {
      setLoading(true);
      setError(null);

      // Split text into lines and parse each line
      const lines = text.split('\n')
        .map(line => line.trim())
        .filter(line => line.length > 0);

      const galleries = lines.map(line => {
        const [name, website, contactName, contactEmail] = parseCsvLine(line);
        
        if (!name || !website) return null;

        // Validate email format if provided
        if (contactEmail && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contactEmail)) {
          return null;
        }

        return {
          name,
          website: website.startsWith('http') ? website : `https://${website}`,
          contactName: contactName || '',
          contactEmail: contactEmail || '',
          description: ''
        };
      }).filter((gallery): gallery is NonNullable<typeof gallery> => gallery !== null);

      if (galleries.length === 0) {
        throw new Error('No valid galleries found in the imported data');
      }

      // Add galleries one by one to handle potential errors
      const results = await Promise.allSettled(
        galleries.map(gallery => addGallery(gallery))
      );

      // Count successful imports
      const successCount = results.filter(result => result.status === 'fulfilled').length;

      if (successCount === 0) {
        throw new Error('Failed to import any galleries');
      }

      return successCount;
    } catch (err) {
      console.error('Error importing galleries:', err);
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [currentUser, addGallery]);

  return {
    fetchGalleries,
    addGallery,
    updateGallery,
    deleteGallery,
    importGalleries,
    loading,
    error
  };
}