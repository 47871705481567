import React, { useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { 
  ChevronDown, 
  Palette,
  BoxesIcon,
  Image,
  Calculator,
  Calendar,
  Briefcase,
  Building2,
  Sparkles,
  ArrowRight
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';

const features = [
  {
    name: 'Art Inventory',
    description: 'Track and manage your artwork catalog',
    path: '/features/art-inventory',
    icon: Palette
  },
  {
    name: 'Collections',
    description: 'Organize artwork into collections',
    path: '/features/collections',
    icon: BoxesIcon
  },
  {
    name: 'Photo Library',
    description: 'Manage your artwork photography',
    path: '/features/photo-library',
    icon: Image
  },
  {
    name: 'Expense Management',
    description: 'Track costs and revenue',
    path: '/features/expense-management',
    icon: Calculator
  },
  {
    name: 'Tasks & Calendar',
    description: 'Stay organized and on schedule',
    path: '/features/tasks',
    icon: Calendar
  },
  {
    name: 'Open-Calls',
    description: 'Track exhibition opportunities',
    path: '/features/open-calls',
    icon: Briefcase
  },
  {
    name: 'Galleries & Fairs',
    description: 'Manage gallery relationships',
    path: '/features/galleries',
    icon: Building2
  },
  {
    name: 'AI Promotions',
    description: 'Generate marketing content',
    path: '/features/ai-promotions',
    icon: Sparkles
  },
];

export default function MainHeader() {
  const { currentUser } = useAuth();
  const [featuresOpen, setFeaturesOpen] = useState(false);
  const timeoutRef = useRef<number>();
  const submenuRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = useCallback(() => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }
    setFeaturesOpen(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    timeoutRef.current = window.setTimeout(() => {
      setFeaturesOpen(false);
    }, 100);
  }, []);

  return (
    <header className="bg-white border-b border-gray-200">
      <nav className="container mx-auto px-6 py-4">
        <div className="flex flex-col space-y-4">
          {/* Top Bar */}
          <div className="flex items-center justify-between">
            {/* Logo */}
            <Link to="/" className="flex-shrink-0">
              <img src="/logo.svg" alt="Studio Planner" className="h-24" />
            </Link>

            {/* CTA Buttons */}
            <div className="flex items-center gap-4">
              {currentUser ? (
                <Link
                  to="/app"
                  className="inline-flex items-center gap-2 px-6 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                >
                  Dashboard
                  <ArrowRight className="h-5 w-5" />
                </Link>
              ) : (
                <>
                  <Link
                    to="/login"
                    className="text-gray-600 hover:text-gray-900"
                  >
                    Sign in
                  </Link>
                  <Link
                    to="/register"
                    className="rounded-lg bg-indigo-600 px-4 py-2 text-white hover:bg-indigo-700"
                  >
                    Get Started
                  </Link>
                </>
              )}
            </div>
          </div>

          {/* Navigation */}
          <div className="flex items-center gap-8">
            {/* Features Dropdown */}
            <div 
              className="relative"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <button
                className="flex items-center gap-1 text-gray-600 hover:text-gray-900 py-2"
              >
                Features
                <ChevronDown className="h-4 w-4" />
              </button>

              {featuresOpen && (
                <>
                  <div className="absolute -bottom-2 left-0 h-2 w-full bg-transparent" />
                  <div
                    ref={submenuRef}
                    className="absolute left-0 top-full z-10 w-[480px] origin-top-left rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div className="p-4 grid grid-cols-2 gap-4">
                      {features.map((feature) => {
                        const Icon = feature.icon;
                        return (
                          <Link
                            key={feature.path}
                            to={feature.path}
                            className="flex items-start gap-3 rounded-lg p-3 hover:bg-gray-50 transition-colors"
                          >
                            <div className="flex-shrink-0">
                              <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-50 text-indigo-600">
                                <Icon className="h-6 w-6" />
                              </div>
                            </div>
                            <div>
                              <p className="font-medium text-gray-900">{feature.name}</p>
                              <p className="mt-1 text-sm text-gray-500">
                                {feature.description}
                              </p>
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>

            <Link to="/pricing" className="text-gray-600 hover:text-gray-900">
              Pricing
            </Link>
            <Link to="/blog" className="text-gray-600 hover:text-gray-900">
              Blog
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
}