import React from 'react';
import { Gift } from 'lucide-react';
import { Reward } from '../types';
import { format } from 'date-fns';

type RewardsTableProps = {
  rewards: Reward[];
  loading?: boolean;
};

const formatBytes = (bytes: number) => {
  if (!bytes) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

export default function RewardsTable({ rewards, loading }: RewardsTableProps) {
  if (loading) {
    return (
      <div className="animate-pulse">
        <div className="h-10 bg-gray-200 rounded mb-4"></div>
        <div className="space-y-3">
          {[1, 2, 3].map((i) => (
            <div key={i} className="h-20 bg-gray-200 rounded"></div>
          ))}
        </div>
      </div>
    );
  }

  if (rewards.length === 0) {
    return (
      <div className="text-center py-12 bg-white rounded-lg border border-gray-200">
        <Gift className="h-12 w-12 text-gray-400 mx-auto mb-4" />
        <h3 className="text-lg font-medium text-gray-900 mb-2">No rewards yet</h3>
        <p className="text-gray-500">
          Invite other artists to earn storage bonuses!
        </p>
      </div>
    );
  }

  return (
    <div className="overflow-x-auto">
      <table className="w-full">
        <thead>
          <tr className="border-b">
            <th className="text-left py-3 px-4 text-sm font-medium text-gray-500">Type</th>
            <th className="text-left py-3 px-4 text-sm font-medium text-gray-500">Description</th>
            <th className="text-left py-3 px-4 text-sm font-medium text-gray-500">Storage Bonus</th>
            <th className="text-left py-3 px-4 text-sm font-medium text-gray-500">Date</th>
          </tr>
        </thead>
        <tbody>
          {rewards.map((reward) => (
            <tr key={reward.id} className="border-b last:border-b-0">
              <td className="py-3 px-4">
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 capitalize">
                  {reward.type}
                </span>
              </td>
              <td className="py-3 px-4">
                <div>
                  <div className="font-medium text-gray-900">{reward.description}</div>
                  {reward.metadata?.invitedUserEmail && (
                    <div className="text-sm text-gray-500">
                      Invited: {reward.metadata.invitedUserEmail}
                    </div>
                  )}
                </div>
              </td>
              <td className="py-3 px-4 text-green-600 font-medium">
                +{formatBytes(reward.storageBonus)}
              </td>
              <td className="py-3 px-4 text-gray-500">
                {format(new Date(reward.createdAt), 'MMM d, yyyy')}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}