import { auth } from './firebase';

export async function fetchStripeSubscriptions() {
  const currentUser = auth.currentUser;
  if (!currentUser) {
    throw new Error('User must be logged in');
  }

  try {
    const token = await currentUser.getIdToken();
    const response = await fetch('/api/admin/subscriptions', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to fetch subscriptions');
    }

    const data = await response.json();
    return data;
  } catch (error: any) {
    console.error('Error fetching subscriptions:', error);
    throw new Error(error.message || 'Failed to fetch subscriptions');
  }
}