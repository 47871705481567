import React, { useState, useEffect } from 'react';
import { Plus, X, ExternalLink, Instagram, Building2, Mail, User, Trash2 } from 'lucide-react';
import PageHeader from '../components/PageHeader';
import SearchBar from '../components/SearchBar';
import SlidePanel from '../components/SlidePanel';
import GalleryFairForm from '../components/GalleryFairForm';
import { Gallery } from '../types';
import { useGalleries } from '../hooks/useGalleries';
import LoadingSpinner from '../components/LoadingSpinner';

export default function Galleries() {
  const { fetchGalleries, addGallery, updateGallery, deleteGallery, importGalleries, loading } = useGalleries();
  const [galleries, setGalleries] = useState<Gallery[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [editingGallery, setEditingGallery] = useState<Gallery | null>(null);
  const [showImportModal, setShowImportModal] = useState(false);
  const [importText, setImportText] = useState('');
  const [selectedGalleries, setSelectedGalleries] = useState<string[]>([]);

  useEffect(() => {
    const loadGalleries = async () => {
      try {
        const data = await fetchGalleries();
        setGalleries(data);
      } catch (err) {
        console.error('Error loading galleries:', err);
      }
    };
    loadGalleries();
  }, [fetchGalleries]);

  const handleAddGallery = async (data: Omit<Gallery, 'id'>) => {
    try {
      const id = await addGallery(data);
      const newGallery = { ...data, id } as Gallery;
      setGalleries([...galleries, newGallery]);
      setShowForm(false);
    } catch (err) {
      console.error('Error adding gallery:', err);
    }
  };

  const handleEditGallery = async (data: Partial<Gallery>) => {
    if (!editingGallery) return;
    
    try {
      await updateGallery(editingGallery.id, data);
      setGalleries(prev => prev.map(gallery =>
        gallery.id === editingGallery.id ? { ...gallery, ...data } : gallery
      ));
      setEditingGallery(null);
    } catch (err) {
      console.error('Error updating gallery:', err);
    }
  };

  const handleDeleteGallery = async () => {
    if (!editingGallery) return;
    
    if (window.confirm('Are you sure you want to delete this gallery?')) {
      try {
        await deleteGallery(editingGallery.id);
        setGalleries(prev => prev.filter(gallery => gallery.id !== editingGallery.id));
        setEditingGallery(null);
      } catch (err) {
        console.error('Error deleting gallery:', err);
      }
    }
  };

  const handleBulkDelete = async () => {
    if (selectedGalleries.length === 0) return;

    if (window.confirm(`Are you sure you want to delete ${selectedGalleries.length} selected galleries?`)) {
      try {
        await Promise.all(selectedGalleries.map(id => deleteGallery(id)));
        setGalleries(prev => prev.filter(gallery => !selectedGalleries.includes(gallery.id)));
        setSelectedGalleries([]);
      } catch (err) {
        console.error('Error deleting galleries:', err);
        alert('Some galleries could not be deleted. Please try again.');
      }
    }
  };

  const handleImport = async () => {
    try {
      const count = await importGalleries(importText);
      alert(`Successfully imported ${count} galleries`);
      const updatedGalleries = await fetchGalleries();
      setGalleries(updatedGalleries);
      setShowImportModal(false);
      setImportText('');
    } catch (err) {
      console.error('Error importing galleries:', err);
      alert('Failed to import galleries. Please check the format.');
    }
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedGalleries(filteredGalleries.map(gallery => gallery.id));
    } else {
      setSelectedGalleries([]);
    }
  };

  const handleSelectGallery = (id: string, checked: boolean) => {
    if (checked) {
      setSelectedGalleries(prev => [...prev, id]);
    } else {
      setSelectedGalleries(prev => prev.filter(galleryId => galleryId !== id));
    }
  };

  const filteredGalleries = galleries.filter(gallery =>
    gallery.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    gallery.description?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading && galleries.length === 0) {
    return <LoadingSpinner />;
  }

  // Show empty state if no galleries exist
  if (galleries.length === 0) {
    return (
      <div className="space-y-6">
        <PageHeader 
          title="Galleries & Fairs" 
          buttonLabel="Add Gallery"
          buttonIcon={Plus}
          onButtonClick={() => setShowForm(true)}
        />

        <div className="bg-white rounded-lg border border-gray-200 p-8">
          <div className="max-w-xl mx-auto text-center">
            <Building2 className="h-12 w-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">No galleries yet</h3>
            <p className="text-gray-500 mb-6">
              Start building your network by adding galleries and art fairs you want to work with.
              Track contact information, websites, and social media profiles.
            </p>
            <div className="flex items-center justify-center gap-4">
              <button
                onClick={() => setShowForm(true)}
                className="inline-flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
              >
                <Plus className="h-5 w-5" />
                Add Your First Gallery
              </button>
              <button
                onClick={() => setShowImportModal(true)}
                className="inline-flex items-center gap-2 px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
              >
                Import List
              </button>
            </div>
          </div>
        </div>

        <SlidePanel
          isOpen={showForm}
          onClose={() => setShowForm(false)}
          title="New Gallery"
        >
          <GalleryFairForm
            onClose={() => setShowForm(false)}
            onSubmit={handleAddGallery}
            loading={loading}
          />
        </SlidePanel>

        {showImportModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg w-full max-w-lg p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">Import Galleries</h2>
                <button
                  onClick={() => setShowImportModal(false)}
                  className="p-2 hover:bg-gray-100 rounded-lg"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
              
              <p className="text-sm text-gray-500 mb-4">
                Paste a list of galleries in the format:<br />
                <code className="text-xs bg-gray-100 p-1 rounded">Gallery Name, Website URL, Contact Name, Contact Email</code><br />
                One gallery per line. Contact Name and Email are optional.
              </p>

              <textarea
                value={importText}
                onChange={(e) => setImportText(e.target.value)}
                rows={8}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 mb-4"
                placeholder="Example:
Modern Art Gallery, modernart.com, John Smith, john@modernart.com
City Arts Fair, cityartsfair.com
Design Gallery, designgallery.com, Sarah Jones, sarah@designgallery.com"
              />

              <div className="flex justify-end gap-3">
                <button
                  onClick={() => setShowImportModal(false)}
                  className="px-4 py-2 border rounded-lg hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  onClick={handleImport}
                  className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                >
                  Import
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <PageHeader 
        title="Galleries & Fairs" 
        buttonLabel="Add Gallery"
        buttonIcon={Plus}
        onButtonClick={() => setShowForm(true)}
      />

      <div className="flex items-center justify-between gap-4">
        <div className="flex-1">
          <SearchBar 
            placeholder="Search galleries..." 
            onSearch={setSearchTerm}
          />
        </div>
        <div className="flex items-center gap-4">
          {selectedGalleries.length > 0 && (
            <button
              onClick={handleBulkDelete}
              className="inline-flex items-center gap-2 px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg"
            >
              <Trash2 className="h-5 w-5" />
              Delete Selected ({selectedGalleries.length})
            </button>
          )}
          <button
            onClick={() => setShowImportModal(true)}
            className="px-4 py-2 text-sm text-indigo-600 hover:text-indigo-700"
          >
            Import List
          </button>
        </div>
      </div>

      <div className="bg-white rounded-lg border border-gray-200">
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-50 border-b border-gray-200">
                <th className="px-6 py-3 text-left">
                  <input
                    type="checkbox"
                    checked={selectedGalleries.length === filteredGalleries.length}
                    onChange={(e) => handleSelectAll(e.target.checked)}
                    className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Links</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Contact</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Address</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {filteredGalleries.map((gallery) => (
                <tr 
                  key={gallery.id}
                  onClick={() => setEditingGallery(gallery)}
                  className="hover:bg-gray-50 cursor-pointer"
                >
                  <td className="px-6 py-4">
                    <input
                      type="checkbox"
                      checked={selectedGalleries.includes(gallery.id)}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleSelectGallery(gallery.id, e.target.checked);
                      }}
                      onClick={(e) => e.stopPropagation()}
                      className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">{gallery.name}</div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center gap-2">
                      {gallery.website && (
                        <a
                          href={gallery.website}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className="inline-flex items-center gap-1 text-sm text-indigo-600 hover:text-indigo-700"
                        >
                          <ExternalLink className="h-4 w-4" />
                          Website
                        </a>
                      )}
                      {gallery.instagram && (
                        <a
                          href={`https://instagram.com/${gallery.instagram.replace('@', '')}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className="inline-flex items-center gap-1 text-sm text-pink-600 hover:text-pink-700"
                        >
                          <Instagram className="h-4 w-4" />
                          {gallery.instagram}
                        </a>
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="space-y-1">
                      {gallery.contactName && (
                        <div className="flex items-center gap-1 text-sm text-gray-600">
                          <User className="h-4 w-4" />
                          {gallery.contactName}
                        </div>
                      )}
                      {gallery.contactEmail && (
                        <a
                          href={`mailto:${gallery.contactEmail}`}
                          onClick={(e) => e.stopPropagation()}
                          className="flex items-center gap-1 text-sm text-indigo-600 hover:text-indigo-700"
                        >
                          <Mail className="h-4 w-4" />
                          {gallery.contactEmail}
                        </a>
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-500 line-clamp-2">{gallery.description}</div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-500">{gallery.address}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <SlidePanel
        isOpen={showForm || !!editingGallery}
        onClose={() => {
          setShowForm(false);
          setEditingGallery(null);
        }}
        title={editingGallery ? 'Edit Gallery' : 'New Gallery'}
      >
        <GalleryFairForm
          onClose={() => {
            setShowForm(false);
            setEditingGallery(null);
          }}
          onSubmit={editingGallery ? handleEditGallery : handleAddGallery}
          onDelete={editingGallery ? handleDeleteGallery : undefined}
          initialData={editingGallery}
          loading={loading}
        />
      </SlidePanel>

      {showImportModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg w-full max-w-lg p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Import Galleries</h2>
              <button
                onClick={() => setShowImportModal(false)}
                className="p-2 hover:bg-gray-100 rounded-lg"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <p className="text-sm text-gray-500 mb-4">
              Paste a list of galleries in the format:<br />
              <code className="text-xs bg-gray-100 p-1 rounded">Gallery Name, Website URL, Contact Name, Contact Email</code><br />
              One gallery per line. Contact Name and Email are optional.
            </p>

            <textarea
              value={importText}
              onChange={(e) => setImportText(e.target.value)}
              rows={8}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 mb-4"
              placeholder="Example:
Modern Art Gallery, modernart.com, John Smith, john@modernart.com
City Arts Fair, cityartsfair.com
Design Gallery, designgallery.com, Sarah Jones, sarah@designgallery.com"
            />

            <div className="flex justify-end gap-3">
              <button
                onClick={() => setShowImportModal(false)}
                className="px-4 py-2 border rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleImport}
                className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
              >
                Import
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}