import { useState, useCallback } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { createCheckoutSession, createPortalSession } from '../lib/stripe';
import type { StripeSubscription } from '../types/stripe';
import { PLANS } from '../lib/stripe-utils';

export function useSubscription() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const getSubscription = useCallback(async () => {
    if (!currentUser) return null;

    try {
      setLoading(true);
      setError(null);
      const docRef = doc(db, 'subscriptions', currentUser.uid);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        // Return default free plan if no subscription exists
        return {
          status: 'active',
          price: {
            id: PLANS.student.id,
            product: PLANS.student.productId,
            lookup_key: 'student_monthly',
            unit_amount: 0,
            currency: 'gbp',
            recurring: {
              interval: 'month',
              interval_count: 1
            }
          }
        };
      }

      return docSnap.data() as StripeSubscription;
    } catch (err) {
      console.error('Error fetching subscription:', err);
      setError(err as Error);
      // Return default free plan on error to prevent UI issues
      return {
        status: 'active',
        price: {
          id: PLANS.student.id,
          product: PLANS.student.productId,
          lookup_key: 'student_monthly',
          unit_amount: 0,
          currency: 'gbp',
          recurring: {
            interval: 'month',
            interval_count: 1
          }
        }
      };
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  const subscribe = useCallback(async (priceId: string) => {
    if (!currentUser) throw new Error('User not authenticated');

    try {
      setLoading(true);
      setError(null);
      const { url } = await createCheckoutSession(priceId);
      if (!url) throw new Error('No checkout URL received');
      window.location.href = url;
    } catch (err) {
      console.error('Error subscribing:', err);
      setError(new Error('Failed to start subscription process. Please try again.'));
      throw err;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  const manageSubscription = useCallback(async () => {
    if (!currentUser) throw new Error('User not authenticated');

    try {
      setLoading(true);
      setError(null);
      const { url } = await createPortalSession();
      if (!url) throw new Error('No portal URL received');
      window.location.href = url;
    } catch (err) {
      console.error('Error managing subscription:', err);
      setError(new Error('Failed to open subscription management. Please try again.'));
      throw err;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  return {
    getSubscription,
    subscribe,
    manageSubscription,
    loading,
    error
  };
}