import React from 'react';
import { Circle, CheckCircle2, Calendar, ExternalLink, EyeOff, Eye, Plus } from 'lucide-react';
import { Task } from '../types';
import { format, isPast, isToday, parseISO } from 'date-fns';

type TaskListProps = {
  tasks: Task[];
  onTaskClick: (task: Task) => void;
  onStatusChange: (taskId: string, status: Task['status']) => void;
  onTaskUpdate?: (taskId: string, showInCalendar: boolean) => void;
  showCompleted?: boolean;
  onToggleShowCompleted?: () => void;
  onAddTask?: () => void;
};

// Helper function to validate and parse URLs
const getUrlHostname = (urlString: string) => {
  try {
    if (!urlString.startsWith('http://') && !urlString.startsWith('https://')) {
      urlString = 'https://' + urlString;
    }
    const url = new URL(urlString);
    return url.hostname;
  } catch (e) {
    return urlString;
  }
};

const getDueStatus = (dueDate?: string) => {
  if (!dueDate) return null;
  const date = parseISO(dueDate);
  if (isPast(date) && !isToday(date)) return 'overdue';
  if (isToday(date)) return 'today';
  return 'upcoming';
};

export default function TaskList({ 
  tasks,
  onTaskClick,
  onStatusChange,
  onTaskUpdate,
  showCompleted = true,
  onToggleShowCompleted,
  onAddTask
}: TaskListProps) {
  return (
    <div className="bg-white rounded-lg border border-gray-200">
      <div className="p-4 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-semibold text-gray-900">Tasks</h3>
          <div className="flex items-center gap-4">
            {onToggleShowCompleted && (
              <button
                onClick={onToggleShowCompleted}
                className="p-2 hover:bg-gray-100 rounded-lg"
                title={showCompleted ? 'Hide completed tasks' : 'Show completed tasks'}
              >
                {showCompleted ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
              </button>
            )}
            {onAddTask && (
              <button
                onClick={onAddTask}
                className="inline-flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
              >
                <Plus className="h-5 w-5" />
                Add Task
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="bg-gray-50 border-b border-gray-200">
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-10">Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Task</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">References</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Due Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-10">Calendar</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {tasks.length > 0 ? (
              tasks.map((task) => {
                const dueStatus = getDueStatus(task.dueDate);
                return (
                  <tr 
                    key={task.id}
                    onClick={() => onTaskClick(task)}
                    className="hover:bg-gray-50 transition-colors cursor-pointer"
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onStatusChange(task.id, task.status === 'completed' ? 'todo' : 'completed');
                        }}
                        className={`${
                          task.status === 'completed' ? 'text-green-500' : 'text-gray-400 hover:text-gray-600'
                        }`}
                      >
                        {task.status === 'completed' ? (
                          <CheckCircle2 className="h-5 w-5" />
                        ) : (
                          <Circle className="h-5 w-5" />
                        )}
                      </button>
                    </td>
                    <td className="px-6 py-4">
                      <div className={task.status === 'completed' ? 'text-gray-400 line-through' : 'text-gray-900'}>
                        {task.title}
                      </div>
                      {task.description && (
                        <div className={`text-sm mt-1 ${task.status === 'completed' ? 'text-gray-400' : 'text-gray-500'}`}>
                          {task.description}
                        </div>
                      )}
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex flex-wrap gap-2">
                        {task.links?.filter(link => link?.trim()).map((link, index) => (
                          <a
                            key={index}
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`inline-flex items-center gap-1 text-sm ${
                              task.status === 'completed'
                                ? 'text-gray-400 hover:text-gray-500'
                                : 'text-indigo-600 hover:text-indigo-700'
                            }`}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <ExternalLink className="h-3 w-3" />
                            {getUrlHostname(link)}
                          </a>
                        ))}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {task.dueDate && (
                        <span className={
                          task.status === 'completed'
                            ? 'text-gray-400'
                            : dueStatus === 'overdue'
                            ? 'text-red-600'
                            : dueStatus === 'today'
                            ? 'text-orange-600'
                            : 'text-green-600'
                        }>
                          {format(parseISO(task.dueDate), 'MMM d, yyyy')}
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {onTaskUpdate && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            onTaskUpdate(task.id, !task.showInCalendar);
                          }}
                          className={`p-1 rounded-lg ${
                            task.showInCalendar ? 'text-indigo-600 hover:bg-indigo-50' : 'text-gray-400 hover:bg-gray-100'
                          }`}
                        >
                          <Calendar className="h-4 w-4" />
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={5} className="px-6 py-8 text-center text-gray-500">
                  No tasks found. Create a new task to get started.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}