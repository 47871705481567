import React from 'react';
import { Outlet } from 'react-router-dom';
import { LayoutDashboard, BookOpen, Users, Crown } from 'lucide-react';
import Sidebar from './Sidebar';

export default function AdminLayout() {
  const navItems = [
    { icon: <LayoutDashboard size={24} />, label: 'Dashboard', path: '/admin' },
    { icon: <Users size={24} />, label: 'Users', path: '/admin/users' },
    { icon: <Crown size={24} />, label: 'Subscriptions', path: '/admin/subscriptions' },
    { icon: <BookOpen size={24} />, label: 'Blog Posts', path: '/admin/blog' },
  ];

  return (
    <div className="flex h-screen bg-gray-50">
      <Sidebar navItems={navItems} />
      <main className="flex-1 overflow-auto">
        <div className="container mx-auto px-6 py-8">
          <Outlet />
        </div>
      </main>
    </div>
  );
}