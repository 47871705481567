import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

type ErrorMessageProps = {
  message: string;
  backLink?: string;
  backText?: string;
};

export default function ErrorMessage({ 
  message, 
  backLink = '/blog',
  backText = 'Back to Blog'
}: ErrorMessageProps) {
  return (
    <div className="min-h-[50vh] flex items-center justify-center">
      <div className="text-center">
        <h1 className="text-2xl font-bold text-gray-900 mb-2">
          {message}
        </h1>
        <p className="text-gray-600 mb-4">
          The content you're looking for doesn't exist or has been removed.
        </p>
        <Link
          to={backLink}
          className="inline-flex items-center gap-2 text-indigo-600 hover:text-indigo-700"
        >
          <ArrowLeft className="h-4 w-4" />
          {backText}
        </Link>
      </div>
    </div>
  );
}