import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { 
  Palette, 
  BoxesIcon, 
  Calculator, 
  Calendar, 
  Image, 
  Sparkles,
  MessageSquare,
  ArrowRight
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import Footer from '../components/Footer';
import MainHeader from '../components/MainHeader';
import ScreenshotSlider from '../components/ScreenshotSlider';

export default function Home() {
  const { currentUser } = useAuth();

  return (
    <>
      <Helmet>
        <title>Studio Planner | Art Business Management Software</title>
        <meta 
          name="description" 
          content="Professional art business management software for artists. Manage inventory, track expenses, organize collections, and promote your work with AI-powered tools."
        />
      </Helmet>

      <MainHeader />

      {/* Hero Section */}
      <div className="bg-gradient-to-b from-indigo-50 to-white">
        <div className="container mx-auto px-6 py-24">
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-5xl font-bold text-gray-900 mb-6">
              Your Art Business, Enhanced by AI
            </h1>
            <p className="text-xl text-gray-600 mb-8">
              Streamline your workflow, showcase your work, and grow your art business with powerful tools and AI-powered content generation.
            </p>
            <div className="flex justify-center gap-4">
              {currentUser ? (
                <Link
                  to="/app"
                  className="inline-flex items-center gap-2 px-8 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 font-medium"
                >
                  Go to Dashboard
                  <ArrowRight className="h-5 w-5" />
                </Link>
              ) : (
                <>
                  <Link
                    to="/register"
                    className="px-8 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 font-medium"
                  >
                    Start Free
                  </Link>
                  <Link
                    to="/login"
                    className="px-8 py-3 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 font-medium"
                  >
                    Sign In
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Screenshot Slider Section */}
      <div className="bg-white py-24">
        <div className="container mx-auto px-6">
          <ScreenshotSlider />
        </div>
      </div>

      {/* Features Section */}
      <div className="container mx-auto px-6 py-24">
        <h2 className="sr-only">Features</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          <div className="text-center">
            <div className="w-12 h-12 bg-indigo-100 text-indigo-600 rounded-lg flex items-center justify-center mx-auto mb-4">
              <Palette className="h-6 w-6" />
            </div>
            <h3 className="text-lg font-semibold mb-2">Art Inventory</h3>
            <p className="text-gray-600">
              Track your artwork details, pricing, and availability in one place
            </p>
          </div>
          <div className="text-center">
            <div className="w-12 h-12 bg-indigo-100 text-indigo-600 rounded-lg flex items-center justify-center mx-auto mb-4">
              <BoxesIcon className="h-6 w-6" />
            </div>
            <h3 className="text-lg font-semibold mb-2">Collections</h3>
            <p className="text-gray-600">
              Organize your pieces into collections and manage them efficiently
            </p>
          </div>
          <div className="text-center">
            <div className="w-12 h-12 bg-indigo-100 text-indigo-600 rounded-lg flex items-center justify-center mx-auto mb-4">
              <Calculator className="h-6 w-6" />
            </div>
            <h3 className="text-lg font-semibold mb-2">Expense Tracking</h3>
            <p className="text-gray-600">
              Monitor costs and revenue with detailed financial tracking
            </p>
          </div>
          <div className="text-center">
            <div className="w-12 h-12 bg-indigo-100 text-indigo-600 rounded-lg flex items-center justify-center mx-auto mb-4">
              <Calendar className="h-6 w-6" />
            </div>
            <h3 className="text-lg font-semibold mb-2">Task Management</h3>
            <p className="text-gray-600">
              Stay organized with built-in task tracking and reminders
            </p>
          </div>
        </div>
      </div>

      {/* Rest of the sections remain unchanged */}
      {/* AI Features Section */}
      <div className="bg-gradient-to-b from-indigo-50 to-white py-24">
        <div className="container mx-auto px-6">
          <div className="max-w-2xl mx-auto text-center mb-16">
            <div className="w-16 h-16 bg-indigo-600 text-white rounded-2xl flex items-center justify-center mx-auto mb-6">
              <Sparkles className="h-8 w-8" />
            </div>
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              AI-Powered Content Creation
            </h2>
            <p className="text-gray-600">
              Let our AI studio help you create engaging content for your art business
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-200">
              <MessageSquare className="h-8 w-8 text-pink-600 mb-4" />
              <h3 className="text-lg font-semibold mb-2">Instagram Posts</h3>
              <p className="text-gray-600">Generate engaging social media captions with relevant hashtags and emojis</p>
            </div>
            <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-200">
              <Image className="h-8 w-8 text-blue-600 mb-4" />
              <h3 className="text-lg font-semibold mb-2">Blog Articles</h3>
              <p className="text-gray-600">Create detailed blog posts about your artwork and creative process</p>
            </div>
            <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-200">
              <MessageSquare className="h-8 w-8 text-purple-600 mb-4" />
              <h3 className="text-lg font-semibold mb-2">Press Releases</h3>
              <p className="text-gray-600">Generate professional press releases for your exhibitions and achievements</p>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="container mx-auto px-6 py-24">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">
            Ready to Transform Your Art Business?
          </h2>
          <p className="text-xl text-gray-600 mb-8">
            Join other artists who are using Studio Planner to streamline their work and create engaging content
          </p>
          {currentUser ? (
            <Link
              to="/app"
              className="inline-flex items-center gap-2 px-8 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 font-medium"
            >
              Go to Dashboard
              <ArrowRight className="h-5 w-5" />
            </Link>
          ) : (
            <Link
              to="/register"
              className="inline-flex items-center gap-2 px-8 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 font-medium"
            >
              Get Started Now
              <ArrowRight className="h-5 w-5" />
            </Link>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
}