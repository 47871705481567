import { useState, useCallback } from 'react';
import { collection, addDoc, updateDoc, doc, deleteDoc, Timestamp, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Promotion } from '../types';
import { generatePromotion } from '../lib/openai';

function cleanDataForFirestore(data: any) {
  const cleaned = { ...data };

  // Convert date strings to Timestamps
  if (cleaned.createdAt) {
    cleaned.createdAt = Timestamp.fromDate(new Date(cleaned.createdAt));
  }
  if (cleaned.updatedAt) {
    cleaned.updatedAt = Timestamp.fromDate(new Date(cleaned.updatedAt));
  }
  if (cleaned.publishedAt) {
    cleaned.publishedAt = cleaned.publishedAt ? Timestamp.fromDate(new Date(cleaned.publishedAt)) : null;
  }

  // Ensure required fields
  cleaned.status = cleaned.status || 'draft';
  cleaned.linkedArtworkId = cleaned.linkedArtworkId || null;
  cleaned.linkedCollectionId = cleaned.linkedCollectionId || null;

  return cleaned;
}

export function usePromotions() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchPromotions = useCallback(async () => {
    if (!currentUser) return [];

    try {
      setLoading(true);
      setError(null);
      const q = query(
        collection(db, 'promotions'),
        where('artistId', '==', currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const promotions = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate().toISOString(),
        updatedAt: doc.data().updatedAt?.toDate().toISOString(),
        publishedAt: doc.data().publishedAt?.toDate()?.toISOString()
      })) as Promotion[];
      return promotions;
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  const addPromotion = useCallback(async (data: Omit<Promotion, 'id' | 'artistId'>) => {
    if (!currentUser) throw new Error('User not authenticated');

    try {
      setLoading(true);
      setError(null);
      const cleanedData = cleanDataForFirestore({
        ...data,
        artistId: currentUser.uid,
        createdAt: data.createdAt || new Date().toISOString(),
        updatedAt: data.updatedAt || new Date().toISOString()
      });

      const docRef = await addDoc(collection(db, 'promotions'), cleanedData);
      return docRef.id;
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  const updatePromotion = useCallback(async (id: string, data: Partial<Promotion>) => {
    if (!currentUser) throw new Error('User not authenticated');

    try {
      setLoading(true);
      setError(null);
      const cleanedData = cleanDataForFirestore({
        ...data,
        updatedAt: new Date().toISOString()
      });

      const docRef = doc(db, 'promotions', id);
      await updateDoc(docRef, cleanedData);
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  const deletePromotion = useCallback(async (id: string) => {
    if (!currentUser) throw new Error('User not authenticated');

    try {
      setLoading(true);
      setError(null);
      await deleteDoc(doc(db, 'promotions', id));
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  const generateContent = useCallback(async (
    type: 'instagram' | 'blog' | 'pr',
    tone: 'playful' | 'informative' | 'excited' | 'artistic',
    artwork?: { title: string; description: string; materials: string[]; price: number; measurements?: any; shopLink?: string; currency?: { code: string; symbol: string; } },
    collection?: { name: string; description: string; artistStatement?: string },
    followUpPrompt?: string
  ) => {
    try {
      setLoading(true);
      setError(null);
      const content = await generatePromotion(type, tone, artwork, collection, followUpPrompt);
      return content;
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    fetchPromotions,
    addPromotion,
    updatePromotion,
    deletePromotion,
    generateContent,
    loading,
    error
  };
}