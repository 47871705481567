import React from 'react';
import { LucideIcon } from 'lucide-react';

type StatsCardProps = {
  label: string;
  value: string | number;
  icon: LucideIcon;
  color: string;
  change?: string;
};

export default function StatsCard({ label, value, icon: Icon, color, change }: StatsCardProps) {
  return (
    <div className="bg-white rounded-lg border border-gray-200 p-6">
      <div className="flex items-center justify-between mb-2">
        <div>
          <p className="text-sm font-medium text-gray-500">{label}</p>
          <p className="mt-2 text-3xl font-bold text-gray-900">{value}</p>
        </div>
        <div className={`p-3 rounded-full ${color}`}>
          <Icon className="h-6 w-6" />
        </div>
      </div>
      {change && (
        <div className="text-sm font-medium text-gray-500">
          {change.startsWith('-') ? (
            <span className="text-red-600">{change}</span>
          ) : change === 'N/A' ? (
            <span className="text-gray-400">{change}</span>
          ) : (
            <span className="text-green-600">+{change}</span>
          )}
          {change !== 'N/A' && ' vs previous month'}
        </div>
      )}
    </div>
  );
}