import React from 'react';
import { artworkCategories, artworkTypes, ArtworkCategory } from '../lib/artwork-types';

type ArtworkTypeSelectProps = {
  value: string;
  onChange: (value: string) => void;
  className?: string;
};

export default function ArtworkTypeSelect({ value, onChange, className = '' }: ArtworkTypeSelectProps) {
  const [selectedCategory, setSelectedCategory] = React.useState<ArtworkCategory>(
    () => {
      // Initialize category based on current value
      const [category] = value.split(' - ');
      return (Object.keys(artworkCategories).find(key => 
        artworkCategories[key as ArtworkCategory] === category
      ) as ArtworkCategory) || 'jewelry';
    }
  );

  const handleCategoryChange = (category: ArtworkCategory) => {
    setSelectedCategory(category);
    // Select first type in category by default
    onChange(`${artworkCategories[category]} - ${artworkTypes[category][0]}`);
  };

  return (
    <div className="space-y-4">
      {/* Category Selection */}
      <div className="flex gap-2">
        {Object.entries(artworkCategories).map(([key, label]) => (
          <button
            type="button"
            key={key}
            onClick={() => handleCategoryChange(key as ArtworkCategory)}
            className={`px-3 py-1.5 rounded-lg text-sm font-medium transition-colors ${
              selectedCategory === key
                ? 'bg-indigo-600 text-white'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
          >
            {label}
          </button>
        ))}
      </div>

      {/* Type Selection */}
      <select
        value={value.split(' - ')[1]}
        onChange={(e) => onChange(`${artworkCategories[selectedCategory]} - ${e.target.value}`)}
        className={`w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 ${className}`}
      >
        {artworkTypes[selectedCategory].map((type) => (
          <option key={type} value={type}>
            {type}
          </option>
        ))}
      </select>
    </div>
  );
}