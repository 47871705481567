import React, { useState, useEffect } from 'react';
import { Crown, Users, Gift, HardDrive, Copy, Check } from 'lucide-react';
import PageHeader from '../components/PageHeader';
import RewardsTable from '../components/RewardsTable';
import PricingOverlay from '../components/PricingOverlay';
import { useRewards } from '../hooks/useRewards';
import { useProfile } from '../contexts/ProfileContext';
import { useSubscription } from '../hooks/useSubscription';
import LoadingSpinner from '../components/LoadingSpinner';

// Helper function for formatting bytes
const formatBytes = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  if (bytes === 0) return '0 Byte';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.round((bytes / Math.pow(1024, i)) * 100) / 100 + ' ' + sizes[i];
};

export default function Membership() {
  const { profile, loading: profileLoading } = useProfile();
  const { fetchRewards, calculateTotalBonus, loading: rewardsLoading } = useRewards();
  const { subscribe, getSubscription, loading: subscriptionLoading } = useSubscription();
  const [copied, setCopied] = useState<'url' | 'code' | null>(null);
  const [rewards, setRewards] = useState<any[]>([]);
  const [totalBonus, setTotalBonus] = useState(0);
  const [loading, setLoading] = useState(true);
  const [subscription, setSubscription] = useState<any>(null);
  const [upgrading, setUpgrading] = useState(false);
  const [showPricing, setShowPricing] = useState(false);
  const [billingPeriod, setBillingPeriod] = useState<'monthly' | 'yearly'>('monthly');

  useEffect(() => {
    const loadData = async () => {
      if (!profile) return;
      
      try {
        setLoading(true);
        const [rewardsData, bonus, subscriptionData] = await Promise.all([
          fetchRewards(),
          calculateTotalBonus(),
          getSubscription()
        ]);
        setRewards(rewardsData);
        setTotalBonus(bonus);
        setSubscription(subscriptionData);
      } catch (err) {
        console.error('Error loading data:', err);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [profile, fetchRewards, calculateTotalBonus, getSubscription]);

  // Show loading spinner while profile is loading or rewards are being fetched
  if (profileLoading || loading || !profile) {
    return <LoadingSpinner />;
  }

  const inviteCode = profile.referralCode;
  const inviteUrl = `https://studio-planner.com/register?invite=${inviteCode}`;

  const handleCopy = async (type: 'url' | 'code') => {
    try {
      const textToCopy = type === 'url' ? inviteUrl : inviteCode;
      await navigator.clipboard.writeText(textToCopy);
      setCopied(type);
      setTimeout(() => setCopied(null), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleUpgrade = async (planId: string) => {
    try {
      setUpgrading(true);
      await subscribe(planId);
    } catch (err) {
      console.error('Error upgrading plan:', err);
      alert('Failed to upgrade plan. Please try again.');
    } finally {
      setUpgrading(false);
    }
  };

  const totalStorage = profile.storageLimit + totalBonus;
  const usedStorage = 125 * 1024 * 1024; // 125MB for demo
  const usedPercentage = (usedStorage / totalStorage) * 100;
  const bonusPercentage = (totalBonus / totalStorage) * 100;

  const currentPlanId = subscription?.price?.id || 'price_1QRFUJGrdPkCENDcnG95E7J0'; // Default to student plan

  return (
    <div className="space-y-6">
      <PageHeader title="Membership" />

      {/* Current Plan */}
      <div className="bg-white rounded-lg border border-gray-200 p-6">
        <div className="flex items-start justify-between">
          <div>
            <h2 className="text-xl font-semibold text-gray-900">Current Plan</h2>
            <p className="text-gray-500 mt-1">Your current subscription plan and features</p>
          </div>
          <button
            onClick={() => setShowPricing(true)}
            className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
            disabled={subscriptionLoading}
          >
            Manage Subscription
          </button>
        </div>

        <div className="mt-6">
          <div className="flex items-center gap-3 mb-4">
            <Crown className="h-5 w-5 text-indigo-600" />
            <h3 className="text-lg font-semibold text-gray-900">
              {subscription?.price?.lookup_key?.includes('professional') 
                ? 'Professional Artist' 
                : subscription?.price?.lookup_key?.includes('artist')
                ? 'Artist'
                : 'Student/Graduate'}
            </h3>
          </div>

          <div className="space-y-4">
            <div>
              <div className="flex justify-between text-sm text-gray-600 mb-1">
                <span>Storage Usage</span>
                <span>{formatBytes(usedStorage)} of {formatBytes(totalStorage)} used</span>
              </div>
              <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                <div className="h-full flex">
                  <div
                    className="h-full bg-indigo-600 transition-all"
                    style={{ width: `${usedPercentage}%` }}
                  />
                  <div
                    className="h-full bg-green-500 transition-all"
                    style={{ width: `${bonusPercentage}%` }}
                  />
                </div>
              </div>
              <div className="flex justify-between mt-1 text-sm">
                <span className="text-gray-600">Base: {formatBytes(profile.storageLimit)}</span>
                <span className="text-green-600">Bonus: +{formatBytes(totalBonus)}</span>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="p-4 bg-gray-50 rounded-lg">
                <div className="text-sm text-gray-600 mb-1">Artworks</div>
                <div className="text-lg font-semibold">45 / 100</div>
                <div className="h-1 bg-gray-200 rounded-full mt-2">
                  <div className="h-full bg-indigo-600 rounded-full" style={{ width: '45%' }} />
                </div>
              </div>

              <div className="p-4 bg-gray-50 rounded-lg">
                <div className="text-sm text-gray-600 mb-1">Collections</div>
                <div className="text-lg font-semibold">4 / 10</div>
                <div className="h-1 bg-gray-200 rounded-full mt-2">
                  <div className="h-full bg-indigo-600 rounded-full" style={{ width: '40%' }} />
                </div>
              </div>

              <div className="p-4 bg-gray-50 rounded-lg">
                <div className="text-sm text-gray-600 mb-1">AI Promotions</div>
                <div className="text-lg font-semibold">7 / 10 this month</div>
                <div className="h-1 bg-gray-200 rounded-full mt-2">
                  <div className="h-full bg-indigo-600 rounded-full" style={{ width: '70%' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Invite Section */}
      <div className="bg-white rounded-lg border border-gray-200 p-6">
        <div className="flex items-center gap-3 mb-6">
          <Users className="h-5 w-5 text-gray-400" />
          <h2 className="text-lg font-semibold text-gray-900">Invite Fellow Artists</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Your Invite URL
            </label>
            <div className="flex gap-2">
              <input
                type="text"
                value={inviteUrl}
                readOnly
                className="flex-1 px-3 py-2 border rounded-lg bg-gray-50"
              />
              <button
                onClick={() => handleCopy('url')}
                className="px-3 py-2 border rounded-lg hover:bg-gray-50"
              >
                {copied === 'url' ? <Check className="h-5 w-5 text-green-500" /> : <Copy className="h-5 w-5" />}
              </button>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Invite Code
            </label>
            <div className="flex gap-2">
              <input
                type="text"
                value={inviteCode}
                readOnly
                className="flex-1 px-3 py-2 border rounded-lg bg-gray-50"
              />
              <button
                onClick={() => handleCopy('code')}
                className="px-3 py-2 border rounded-lg hover:bg-gray-50"
              >
                {copied === 'code' ? <Check className="h-5 w-5 text-green-500" /> : <Copy className="h-5 w-5" />}
              </button>
            </div>
          </div>
        </div>

        <p className="text-sm text-gray-500 mt-4">
          Get 50MB extra storage for each artist who signs up using your invite code!
        </p>
      </div>

      {/* Rewards Section */}
      <div className="bg-white rounded-lg border border-gray-200 p-6">
        <div className="flex items-center gap-3 mb-6">
          <Gift className="h-5 w-5 text-gray-400" />
          <h2 className="text-lg font-semibold text-gray-900">Claimed Rewards</h2>
        </div>

        <RewardsTable rewards={rewards} loading={rewardsLoading} />
      </div>

      <PricingOverlay
        isOpen={showPricing}
        onClose={() => setShowPricing(false)}
        onUpgrade={handleUpgrade}
        currentPlan={currentPlanId}
        billingPeriod={billingPeriod}
        loading={upgrading}
      />
    </div>
  );
}