import React, { useState, useEffect } from 'react';
import { Plus, Receipt, Calculator } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import SearchBar from '../components/SearchBar';
import SlidePanel from '../components/SlidePanel';
import ExpenseForm from '../components/ExpenseForm';
import ExpenseFilterMenu from '../components/ExpenseFilterMenu';
import Pagination from '../components/Pagination';
import { useExpenses } from '../hooks/useExpenses';
import { useArtworks } from '../hooks/useArtworks';
import { useCollections } from '../hooks/useCollections';
import LoadingSpinner from '../components/LoadingSpinner';
import { Expense } from '../types';
import { useProfile } from '../contexts/ProfileContext';
import StatsCard from '../components/StatsCard';
import { startOfMonth, endOfMonth } from 'date-fns';

const ITEMS_PER_PAGE = 10;

export default function Expenses() {
  const { profile } = useProfile();
  const { fetchExpenses, addExpense, updateExpense, deleteExpense, loading: expensesLoading } = useExpenses();
  const { fetchArtworks, loading: artworksLoading } = useArtworks();
  const { fetchCollections, loading: collectionsLoading } = useCollections();
  
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const [artworks, setArtworks] = useState<Record<string, any>>({});
  const [collections, setCollections] = useState<Record<string, any>>({});
  const [searchTerm, setSearchTerm] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [editingExpense, setEditingExpense] = useState<Expense | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilters, setShowFilters] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    categories: [],
    types: [],
    dateRange: { start: null, end: null },
    amountRange: { min: null, max: null },
  });

  useEffect(() => {
    const loadData = async () => {
      try {
        const [expensesData, artworksData, collectionsData] = await Promise.all([
          fetchExpenses(),
          fetchArtworks(),
          fetchCollections()
        ]);
        setExpenses(expensesData);
        
        // Create maps for linked items
        const artworksMap = artworksData.reduce((acc: Record<string, any>, artwork: any) => {
          acc[artwork.id] = artwork;
          return acc;
        }, {});
        setArtworks(artworksMap);

        const collectionsMap = collectionsData.reduce((acc: Record<string, any>, collection: any) => {
          acc[collection.id] = collection;
          return acc;
        }, {});
        setCollections(collectionsMap);
      } catch (err) {
        console.error('Error loading data:', err);
      }
    };
    loadData();
  }, [fetchExpenses, fetchArtworks, fetchCollections]);

  const handleAddExpense = async (data: Omit<Expense, 'id'>) => {
    try {
      setLoading(true);
      const id = await addExpense(data);
      const newExpense = { ...data, id } as Expense;
      setExpenses([...expenses, newExpense]);
      setShowForm(false);
    } catch (err) {
      console.error('Error adding expense:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleEditExpense = async (data: Omit<Expense, 'id'>) => {
    if (!editingExpense) return;
    
    try {
      setLoading(true);
      await updateExpense(editingExpense.id, data);
      const updatedExpenses = expenses.map(expense =>
        expense.id === editingExpense.id
          ? { ...expense, ...data }
          : expense
      );
      setExpenses(updatedExpenses);
      setEditingExpense(null);
    } catch (err) {
      console.error('Error updating expense:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteExpense = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this expense?')) {
      return;
    }

    try {
      setLoading(true);
      await deleteExpense(id);
      setExpenses(expenses.filter(expense => expense.id !== id));
      setEditingExpense(null);
    } catch (err) {
      console.error('Error deleting expense:', err);
    } finally {
      setLoading(false);
    }
  };

  const getLinkedItemsText = (expense: Expense) => {
    if (expense.type === 'studio' || expense.linkedItems.length === 0) return null;

    const items = expense.linkedItems.map(item => {
      if (item.type === 'artwork') {
        const artwork = artworks[item.id];
        return artwork?.title;
      } else {
        const collection = collections[item.id];
        return collection?.name;
      }
    }).filter(Boolean);

    return items.join(', ');
  };

  // Calculate expense statistics
  const now = new Date();
  const thisMonthStart = startOfMonth(now);
  const thisMonthEnd = endOfMonth(now);
  const lastMonthStart = startOfMonth(new Date(now.getFullYear(), now.getMonth() - 1));
  const lastMonthEnd = endOfMonth(new Date(now.getFullYear(), now.getMonth() - 1));

  const thisMonthExpenses = expenses.filter(exp => {
    const expDate = new Date(exp.date);
    return expDate >= thisMonthStart && expDate <= thisMonthEnd;
  });

  const lastMonthExpenses = expenses.filter(exp => {
    const expDate = new Date(exp.date);
    return expDate >= lastMonthStart && expDate <= lastMonthEnd;
  });

  const thisMonthTotal = thisMonthExpenses.reduce((sum, exp) => sum + exp.amount, 0);
  const lastMonthTotal = lastMonthExpenses.reduce((sum, exp) => sum + exp.amount, 0);
  const yearToDate = expenses.reduce((sum, exp) => {
    const expDate = new Date(exp.date);
    return expDate.getFullYear() === now.getFullYear() ? sum + exp.amount : sum;
  }, 0);

  const stats = [
    {
      label: 'This Month',
      value: `${profile.currency.symbol}${thisMonthTotal.toLocaleString()}`,
      icon: Receipt,
      color: 'bg-pink-100 text-pink-600',
      change: lastMonthTotal ? `${Math.round((thisMonthTotal - lastMonthTotal) / lastMonthTotal * 100)}%` : 'N/A'
    },
    {
      label: 'Last Month',
      value: `${profile.currency.symbol}${lastMonthTotal.toLocaleString()}`,
      icon: Receipt,
      color: 'bg-indigo-100 text-indigo-600'
    },
    {
      label: 'Year to Date',
      value: `${profile.currency.symbol}${yearToDate.toLocaleString()}`,
      icon: Receipt,
      color: 'bg-cyan-100 text-cyan-600'
    }
  ];

  if (expensesLoading || artworksLoading || collectionsLoading) {
    return <LoadingSpinner />;
  }

  // Add empty state check after loading check
  if (expenses.length === 0) {
    return (
      <div className="space-y-6">
        <PageHeader 
          title="Expenses" 
          buttonLabel="Add Expense"
          buttonIcon={Plus}
          onButtonClick={() => setShowForm(true)}
        />

        {/* Stats Cards - Show zero state */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {stats.map((stat) => (
            <StatsCard key={stat.label} {...stat} />
          ))}
        </div>

        <div className="bg-white rounded-lg border border-gray-200 p-8">
          <div className="max-w-xl mx-auto text-center">
            <Calculator className="h-12 w-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">No expenses yet</h3>
            <p className="text-gray-500 mb-6">
              Start tracking your expenses to get insights into your art business costs.
              Add materials, tools, marketing expenses, and more.
            </p>
            <button
              onClick={() => setShowForm(true)}
              className="inline-flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
            >
              <Plus className="h-5 w-5" />
              Add Your First Expense
            </button>
          </div>
        </div>

        <SlidePanel
          isOpen={showForm}
          onClose={() => setShowForm(false)}
          title="New Expense"
        >
          <ExpenseForm
            onClose={() => setShowForm(false)}
            onSubmit={handleAddExpense}
            loading={loading}
            artworks={artworks}
            collections={collections}
          />
        </SlidePanel>
      </div>
    );
  }

  // Calculate active filters count
  const activeFilters = 
    filters.categories.length +
    filters.types.length +
    (filters.dateRange.start || filters.dateRange.end ? 1 : 0) +
    (filters.amountRange.min || filters.amountRange.max ? 1 : 0);

  // Filter expenses based on search and filters
  const filteredExpenses = expenses.filter(expense => {
    const matchesSearch = expense.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      expense.category.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesCategory = filters.categories.length === 0 || 
      filters.categories.includes(expense.category);

    const matchesType = filters.types.length === 0 || 
      filters.types.includes(expense.type);

    const matchesDateRange = 
      (!filters.dateRange.start || new Date(expense.date) >= new Date(filters.dateRange.start)) &&
      (!filters.dateRange.end || new Date(expense.date) <= new Date(filters.dateRange.end));

    const matchesAmountRange = 
      (!filters.amountRange.min || expense.amount >= filters.amountRange.min) &&
      (!filters.amountRange.max || expense.amount <= filters.amountRange.max);

    return matchesSearch && matchesCategory && matchesType && 
           matchesDateRange && matchesAmountRange;
  });

  const totalPages = Math.ceil(filteredExpenses.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const paginatedExpenses = filteredExpenses.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  return (
    <div className="space-y-6">
      <PageHeader 
        title="Expenses" 
        buttonLabel="Add Expense"
        buttonIcon={Plus}
        onButtonClick={() => setShowForm(true)}
      />

      {/* Stats */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {stats.map((stat) => (
          <StatsCard key={stat.label} {...stat} />
        ))}
      </div>

      <SearchBar 
        placeholder="Search expenses..." 
        onSearch={setSearchTerm}
        onFilterClick={() => setShowFilters(true)}
        activeFilters={activeFilters}
        showFilter={true}
      />

      {/* Expenses Table */}
      <div className="bg-white rounded-lg border border-gray-200">
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-50 border-b border-gray-200">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Category</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Linked Items</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Invoice</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {paginatedExpenses.map((expense) => (
                <tr 
                  key={expense.id} 
                  className="hover:bg-gray-50 cursor-pointer"
                  onClick={() => setEditingExpense(expense)}
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {new Date(expense.date).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {expense.description}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    <span className="px-2 py-1 text-xs font-medium rounded-full bg-gray-100 text-gray-800">
                      {expense.category}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900 capitalize">
                    {expense.type}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {getLinkedItemsText(expense) || <span className="text-gray-400">-</span>}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    {expense.invoice ? (
                      <a
                        href={expense.invoice.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}
                        className="text-indigo-600 hover:text-indigo-700"
                      >
                        View
                      </a>
                    ) : (
                      <span className="text-gray-400">-</span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                    {profile.currency.symbol}{expense.amount.toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
          totalItems={filteredExpenses.length}
          itemsPerPage={ITEMS_PER_PAGE}
        />
      </div>

      <ExpenseFilterMenu
        isOpen={showFilters}
        onClose={() => setShowFilters(false)}
        filters={filters}
        onChange={setFilters}
        categories={[
          'Materials',
          'Tools',
          'Marketing',
          'Rent',
          'Utilities',
          'Events',
          'Shipping',
          'Other'
        ]}
      />

      <SlidePanel
        isOpen={showForm || !!editingExpense}
        onClose={() => {
          setShowForm(false);
          setEditingExpense(null);
        }}
        title={editingExpense ? 'Edit Expense' : 'New Expense'}
      >
        <ExpenseForm
          onClose={() => {
            setShowForm(false);
            setEditingExpense(null);
          }}
          onSubmit={editingExpense ? handleEditExpense : handleAddExpense}
          onDelete={editingExpense ? () => handleDeleteExpense(editingExpense.id) : undefined}
          initialData={editingExpense}
          loading={loading}
          artworks={artworks}
          collections={collections}
        />
      </SlidePanel>
    </div>
  );
}