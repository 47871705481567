import React, { createContext, useContext, useState, useEffect } from 'react';
import { User, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../lib/firebase';
import { registerUser, loginUser, logoutUser, resetUserPassword } from '../lib/auth';

type AuthContextType = {
  currentUser: User | null;
  loading: boolean;
  error: Error | null;
  signUp: (email: string, password: string, name: string, inviteCode?: string) => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const signUp = async (email: string, password: string, name: string, inviteCode?: string) => {
    try {
      setError(null);
      const user = await registerUser(email, password, name, inviteCode);
      setCurrentUser(user);
    } catch (err) {
      setError(err as Error);
      throw err;
    }
  };

  const login = async (email: string, password: string) => {
    try {
      setError(null);
      const user = await loginUser(email, password);
      setCurrentUser(user);
    } catch (err) {
      setError(err as Error);
      throw err;
    }
  };

  const logout = async () => {
    try {
      setError(null);
      await logoutUser();
      setCurrentUser(null);
    } catch (err) {
      setError(err as Error);
      throw err;
    }
  };

  const resetPassword = async (email: string) => {
    try {
      setError(null);
      await resetUserPassword(email);
    } catch (err) {
      setError(err as Error);
      throw err;
    }
  };

  const value = {
    currentUser,
    loading,
    error,
    signUp,
    login,
    logout,
    resetPassword
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}