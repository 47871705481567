import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import MainHeader from '../components/MainHeader';
import Footer from '../components/Footer';

export default function Terms() {
  return (
    <>
      <Helmet>
        <title>Terms of Service | Studio Planner</title>
        <meta 
          name="description" 
          content="Studio Planner's terms of service outline the rules and guidelines for using our platform."
        />
      </Helmet>

      <MainHeader />

      <main className="bg-white">
        <div className="container mx-auto px-6 py-12">
          <div className="max-w-3xl mx-auto">
            <div className="prose prose-lg max-w-none">
              <h1 className="text-4xl font-bold text-gray-900 mb-4">Terms and Conditions</h1>
              <p className="text-lg text-gray-600 mb-8">Last updated: {new Date().toLocaleDateString('en-GB')}</p>

              <p className="text-gray-700 leading-relaxed">
                These Terms and Conditions ("Terms") govern your use of Studio-Planner.com ("the Service"), operated by IN ONES PRIME LTD, a company registered in the UK with company number 12466402.
              </p>

              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">1. Acceptance of Terms</h2>
              <p className="text-gray-700 leading-relaxed">
                By accessing or using Studio-Planner.com, you agree to be bound by these Terms. If you disagree with any part of the Terms, you may not use the Service.
              </p>

              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">2. Description of Service</h2>
              <p className="text-gray-700 mb-4">
                Studio-Planner.com provides Studio Management tools for Independent Artists, including:
              </p>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Artwork Inventory Management</li>
                <li>Collection Management</li>
                <li>Image and Photo Management</li>
                <li>Expense Tracking</li>
                <li>Task Management</li>
                <li>Open-Call and Competition Application Management</li>
              </ul>

              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">3. User Accounts</h2>
              
              <h3 className="text-xl font-semibold text-gray-900 mt-6 mb-3">3.1 Account Creation</h3>
              <p className="text-gray-700 leading-relaxed">
                You must create an account to use the Service. You are responsible for maintaining the confidentiality of your account and password.
              </p>

              <h3 className="text-xl font-semibold text-gray-900 mt-6 mb-3">3.2 Account Information</h3>
              <p className="text-gray-700 leading-relaxed">
                You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
              </p>

              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">4. Content Ownership and Licensing</h2>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>You retain all ownership rights to your content, including artwork and photos uploaded to the Service.</li>
                <li>By uploading content to the Service, you grant IN ONES PRIME LTD a non-exclusive, worldwide, royalty-free license to use, reproduce, and display your content for the purpose of providing and promoting the Service.</li>
                <li>You agree that IN ONES PRIME LTD may use your public profile information and uploaded images for marketing and promotional purposes related to the Service.</li>
              </ul>

              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">5. Use of AI Technology</h2>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>The Service utilizes OpenAI technology to assist users in generating promotional content. You acknowledge that AI-generated content may not be entirely original and could potentially infringe on third-party rights.</li>
                <li>You are solely responsible for reviewing and approving any AI-generated content before use.</li>
              </ul>

              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">6. User Conduct</h2>
              <p className="text-gray-700 mb-4">You agree not to:</p>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Use the Service for any illegal purpose or in violation of any laws</li>
                <li>Infringe upon the intellectual property rights of others</li>
                <li>Upload or transmit viruses or malicious code</li>
                <li>Attempt to gain unauthorized access to the Service or its related systems</li>
              </ul>

              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">7. Termination</h2>
              <p className="text-gray-700 leading-relaxed">
                IN ONES PRIME LTD reserves the right to terminate or suspend your account and access to the Service at its sole discretion, without notice, for conduct that it believes violates these Terms or is harmful to other users, IN ONES PRIME LTD, or third parties, or for any other reason.
              </p>

              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">8. Disclaimer of Warranties</h2>
              <p className="text-gray-700 leading-relaxed">
                The Service is provided "as is" and "as available" without any warranties of any kind, either express or implied.
              </p>

              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">9. Limitation of Liability</h2>
              <p className="text-gray-700 leading-relaxed">
                IN ONES PRIME LTD shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of or inability to use the Service.
              </p>

              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">10. Changes to Terms</h2>
              <p className="text-gray-700 leading-relaxed">
                IN ONES PRIME LTD reserves the right to modify or replace these Terms at any time. Your continued use of the Service after any changes constitute acceptance of the new Terms.
              </p>

              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">11. Governing Law</h2>
              <p className="text-gray-700 leading-relaxed">
                These Terms shall be governed by and construed in accordance with the laws of the United Kingdom.
              </p>

              <p className="text-gray-700 mt-8 pb-8 border-t pt-8">
                By using Studio-Planner.com, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
              </p>

              <p className="text-gray-700">
                See also our <Link to="/privacy" className="text-indigo-600 hover:text-indigo-700 font-medium">Privacy Policy</Link>.
              </p>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
}