import { useState, useCallback } from 'react';
import { collection, addDoc, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Reward } from '../types';

export function useRewards() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchRewards = useCallback(async () => {
    if (!currentUser) return [];

    try {
      setLoading(true);
      setError(null);
      const q = query(
        collection(db, 'rewards'),
        where('userId', '==', currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const rewards = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate().toISOString()
      })) as Reward[];
      return rewards;
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  const addReferralReward = useCallback(async (userId: string, invitedUserEmail: string) => {
    try {
      setLoading(true);
      setError(null);

      const reward = {
        userId,
        type: 'referral',
        description: 'Successfully invited a new member',
        storageBonus: 50 * 1024 * 1024, // 50MB in bytes
        createdAt: Timestamp.now(),
        metadata: {
          invitedUserEmail
        }
      };

      const docRef = await addDoc(collection(db, 'rewards'), reward);
      return docRef.id;
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const calculateTotalBonus = useCallback(async () => {
    if (!currentUser) return 0;

    try {
      const rewards = await fetchRewards();
      return rewards.reduce((total, reward) => total + reward.storageBonus, 0);
    } catch (err) {
      console.error('Error calculating total bonus:', err);
      return 0;
    }
  }, [currentUser, fetchRewards]);

  return {
    fetchRewards,
    addReferralReward,
    calculateTotalBonus,
    loading,
    error
  };
}