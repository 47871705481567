import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { doc, getDoc, setDoc, Timestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useProfile } from './ProfileContext';
import { useArtworks } from '../hooks/useArtworks';
import { useCollections } from '../hooks/useCollections';
import { useTasks } from '../hooks/useTasks';
import { usePromotions } from '../hooks/usePromotions';
import { useGallery } from '../hooks/useGallery';
import { useAuth } from './AuthContext';

type OnboardingStep = {
  id: string;
  title: string;
  description: string;
  completed: boolean;
  path: string;
};

type OnboardingContextType = {
  steps: OnboardingStep[];
  isOnboardingComplete: boolean;
  completeOnboarding: () => Promise<void>;
  loading: boolean;
  refreshOnboarding: () => Promise<void>;
};

const OnboardingContext = createContext<OnboardingContextType>({} as OnboardingContextType);

export function useOnboarding() {
  return useContext(OnboardingContext);
}

export function OnboardingProvider({ children }: { children: React.ReactNode }) {
  const { currentUser } = useAuth();
  const { profile } = useProfile();
  const { fetchArtworks } = useArtworks();
  const { fetchCollections } = useCollections();
  const { fetchTasks } = useTasks();
  const { fetchPromotions } = usePromotions();
  const { fetchImages } = useGallery();
  const [loading, setLoading] = useState(true);
  const [onboardingComplete, setOnboardingComplete] = useState<boolean>(false);
  const [steps, setSteps] = useState<OnboardingStep[]>([
    {
      id: 'profile',
      title: 'Complete Your Profile',
      description: 'Add your name, photo, logo, bio, and artist statement',
      completed: false,
      path: '/app/profile'
    },
    {
      id: 'photos',
      title: 'Upload Your First Photo',
      description: 'Add photos of your artwork to your gallery',
      completed: false,
      path: '/app/gallery'
    },
    {
      id: 'inventory',
      title: 'Add Your First Artwork',
      description: 'Create your first inventory item',
      completed: false,
      path: '/app/inventory'
    },
    {
      id: 'collection',
      title: 'Create a Collection',
      description: 'Organize your artwork into a collection',
      completed: false,
      path: '/app/collections'
    },
    {
      id: 'tasks',
      title: 'Plan Your Work',
      description: 'Create your first task',
      completed: false,
      path: '/app/tasks'
    },
    {
      id: 'promotion',
      title: 'Create a Promotion',
      description: 'Generate your first promotional content',
      completed: false,
      path: '/app/promotions'
    }
  ]);

  const fetchOnboardingStatus = useCallback(async () => {
    if (!currentUser) return false;

    try {
      const docRef = doc(db, 'artists', currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data().onboardingComplete || false;
      }
      return false;
    } catch (err) {
      console.error('Error fetching onboarding status:', err);
      return false;
    }
  }, [currentUser]);

  const checkProgress = useCallback(async () => {
    if (!profile || !currentUser) return;

    try {
      setLoading(true);
      
      // First check if onboarding is already complete in Firebase
      const isComplete = await fetchOnboardingStatus();
      if (isComplete) {
        setOnboardingComplete(true);
        setLoading(false);
        return;
      }

      const [artworks, collections, tasks, promotions, images] = await Promise.all([
        fetchArtworks(),
        fetchCollections(),
        fetchTasks(),
        fetchPromotions(),
        fetchImages()
      ]);

      setSteps(prev => prev.map(step => {
        switch (step.id) {
          case 'profile':
            return {
              ...step,
              completed: Boolean(
                profile?.name &&
                profile?.bio &&
                profile?.artistStatement &&
                (profile?.avatar || profile?.companyLogo)
              )
            };
          case 'photos':
            return { ...step, completed: Boolean(images?.length) };
          case 'inventory':
            return { ...step, completed: Boolean(artworks?.length) };
          case 'collection':
            return { ...step, completed: Boolean(collections?.length) };
          case 'tasks':
            return { ...step, completed: Boolean(tasks?.length) };
          case 'promotion':
            return { ...step, completed: Boolean(promotions?.length) };
          default:
            return step;
        }
      }));
    } catch (err) {
      console.error('Error checking onboarding progress:', err);
    } finally {
      setLoading(false);
    }
  }, [
    profile,
    currentUser,
    fetchArtworks,
    fetchCollections,
    fetchTasks,
    fetchPromotions,
    fetchImages,
    fetchOnboardingStatus
  ]);

  const completeOnboarding = useCallback(async () => {
    if (!currentUser) return;

    try {
      const docRef = doc(db, 'artists', currentUser.uid);
      await setDoc(docRef, {
        onboardingComplete: true,
        updatedAt: Timestamp.now()
      }, { merge: true });
      
      setOnboardingComplete(true);
    } catch (err) {
      console.error('Error completing onboarding:', err);
    }
  }, [currentUser]);

  const refreshOnboarding = useCallback(async () => {
    if (!onboardingComplete) {
      await checkProgress();
    }
  }, [onboardingComplete, checkProgress]);

  useEffect(() => {
    if (profile) {
      checkProgress();
    }
  }, [profile, checkProgress]);

  const value = {
    steps,
    isOnboardingComplete: onboardingComplete,
    completeOnboarding,
    loading,
    refreshOnboarding
  };

  return (
    <OnboardingContext.Provider value={value}>
      {children}
    </OnboardingContext.Provider>
  );
}