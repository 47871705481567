import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Palette, BoxesIcon, Image, Database, Cloud, BarChart } from 'lucide-react';
import MainHeader from '../../components/MainHeader';
import Footer from '../../components/Footer';

export default function ArtInventory() {
  return (
    <>
      <Helmet>
        <title>Art Inventory Management Software | Studio Planner</title>
        <meta 
          name="description" 
          content="Professional art inventory management software for artists. Track artwork details, manage collections, and organize your portfolio efficiently."
        />
        <meta 
          name="keywords" 
          content="art inventory software, artwork management, artist portfolio, collection management"
        />
      </Helmet>

      <MainHeader />

      <main>
        {/* Hero Section */}
        <div className="bg-gradient-to-b from-blue-50 to-white">
          <div className="container mx-auto px-6 py-24">
            <div className="max-w-3xl mx-auto text-center">
              <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
                Professional Art Inventory Management
              </h1>
              <p className="text-xl text-gray-600 mb-8">
                Keep track of your artwork, manage collections, and organize your portfolio with our comprehensive inventory system.
              </p>
              <div className="flex justify-center gap-4">
                <Link
                  to="/register"
                  className="px-8 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Featured Screenshots */}
        <div className="bg-white py-24">
          <div className="container mx-auto px-6">
            <div className="max-w-6xl mx-auto space-y-32">
              {/* First Screenshot Section */}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
                <div className="relative">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/studioplanner-5d3ea.firebasestorage.app/o/Screenshots%2FArt%20inventory%20for%20artists.png?alt=media&token=e9686d5f-f304-4a69-b129-aa32fe033464"
                    alt="Studio Planner Inventory Management Interface"
                    className="w-full rounded-lg shadow-xl"
                  />
                  <div className="absolute -bottom-6 -right-6 bg-blue-600 text-white px-6 py-3 rounded-lg shadow-lg">
                    <p className="text-sm font-medium">Inventory Management</p>
                  </div>
                </div>
                <div className="flex flex-col justify-center lg:pl-8">
                  <h2 className="text-3xl font-bold text-gray-900 mb-6">
                    Comprehensive Artwork Management
                  </h2>
                  <p className="text-lg text-gray-600 mb-8">
                    Track every detail of your artwork with our intuitive inventory system. From dimensions to pricing, keep all your artwork information organized and accessible.
                  </p>
                  <div className="space-y-6">
                    <div className="flex items-start gap-4">
                      <div className="p-3 bg-blue-100 rounded-lg">
                        <Database className="h-6 w-6 text-blue-600" />
                      </div>
                      <div>
                        <h3 className="font-semibold text-gray-900 text-lg mb-1">Detailed Records</h3>
                        <p className="text-gray-600">Track materials, dimensions, and pricing</p>
                      </div>
                    </div>
                    <div className="flex items-start gap-4">
                      <div className="p-3 bg-blue-100 rounded-lg">
                        <Image className="h-6 w-6 text-blue-600" />
                      </div>
                      <div>
                        <h3 className="font-semibold text-gray-900 text-lg mb-1">Visual Organization</h3>
                        <p className="text-gray-600">Manage artwork photos and documentation</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Second Screenshot Section */}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
                <div className="flex flex-col justify-center lg:pr-8 order-2 lg:order-1">
                  <h2 className="text-3xl font-bold text-gray-900 mb-6">
                    Smart Collection Management
                  </h2>
                  <p className="text-lg text-gray-600 mb-8">
                    Organize your artwork into collections and series. Track locations, availability, and sales history with ease.
                  </p>
                  <div className="space-y-6">
                    <div className="flex items-start gap-4">
                      <div className="p-3 bg-purple-100 rounded-lg">
                        <BoxesIcon className="h-6 w-6 text-purple-600" />
                      </div>
                      <div>
                        <h3 className="font-semibold text-gray-900 text-lg mb-1">Collection Organization</h3>
                        <p className="text-gray-600">Group artwork into meaningful collections</p>
                      </div>
                    </div>
                    <div className="flex items-start gap-4">
                      <div className="p-3 bg-purple-100 rounded-lg">
                        <BarChart className="h-6 w-6 text-purple-600" />
                      </div>
                      <div>
                        <h3 className="font-semibold text-gray-900 text-lg mb-1">Value Tracking</h3>
                        <p className="text-gray-600">Monitor collection values and sales performance</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative order-1 lg:order-2">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/studioplanner-5d3ea.firebasestorage.app/o/Screenshots%2FArt%20inventory%20management.png?alt=media&token=7a5b8713-2c8a-4c5e-a990-a920aa456a44"
                    alt="Studio Planner Collection Management Interface"
                    className="w-full rounded-lg shadow-xl"
                  />
                  <div className="absolute -bottom-6 -left-6 bg-purple-600 text-white px-6 py-3 rounded-lg shadow-lg">
                    <p className="text-sm font-medium">Collection Management</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Features Grid */}
        <div className="container mx-auto px-6 py-24">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-blue-100 text-blue-600 rounded-lg flex items-center justify-center mb-4">
                <Database className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Artwork Tracking</h3>
              <p className="text-gray-600">
                Track artwork details, pricing, and availability in one centralized system.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-purple-100 text-purple-600 rounded-lg flex items-center justify-center mb-4">
                <BoxesIcon className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Collection Management</h3>
              <p className="text-gray-600">
                Organize artworks into collections and manage series efficiently.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-pink-100 text-pink-600 rounded-lg flex items-center justify-center mb-4">
                <Image className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Photo Management</h3>
              <p className="text-gray-600">
                Store and organize high-quality photos of your artwork.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-green-100 text-green-600 rounded-lg flex items-center justify-center mb-4">
                <Cloud className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Cloud Storage</h3>
              <p className="text-gray-600">
                Securely store and access your artwork data from anywhere.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-orange-100 text-orange-600 rounded-lg flex items-center justify-center mb-4">
                <BarChart className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Analytics</h3>
              <p className="text-gray-600">
                Track sales, monitor inventory value, and analyze trends.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-red-100 text-red-600 rounded-lg flex items-center justify-center mb-4">
                <Palette className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Portfolio Generation</h3>
              <p className="text-gray-600">
                Create beautiful portfolios and catalogs from your inventory.
              </p>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="bg-white">
          <div className="container mx-auto px-6 py-24">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-bold text-gray-900 mb-6">
                Ready to organize your artwork?
              </h2>
              <p className="text-xl text-gray-600 mb-8">
                Join other artists who are using Studio Planner to manage their inventory professionally.
              </p>
              <Link
                to="/register"
                className="inline-block px-8 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
}