import React, { useState, useEffect } from 'react';
import { Plus, BoxesIcon } from 'lucide-react';
import PageHeader from '../components/PageHeader';
import SearchBar from '../components/SearchBar';
import Card from '../components/Card';
import SlidePanel from '../components/SlidePanel';
import CollectionForm from '../components/CollectionForm';
import CollectionDetails from '../components/CollectionDetails';
import ViewToggle from '../components/ViewToggle';
import Pagination from '../components/Pagination';
import { Collection } from '../types';
import { useCollections } from '../hooks/useCollections';
import { useArtworks } from '../hooks/useArtworks';
import LoadingSpinner from '../components/LoadingSpinner';
import { useProfile } from '../contexts/ProfileContext';

const ITEMS_PER_PAGE = 10;

export default function Collections() {
  const { profile } = useProfile();
  const { fetchCollections, addCollection, updateCollection, loading: collectionsLoading } = useCollections();
  const { fetchArtworks, loading: artworksLoading } = useArtworks();
  const [collections, setCollections] = useState<Collection[]>([]);
  const [artworks, setArtworks] = useState<Record<string, any>>({});
  const [searchTerm, setSearchTerm] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState<Collection | null>(null);
  const [editingCollection, setEditingCollection] = useState<Collection | null>(null);
  const [view, setView] = useState<'grid' | 'list'>('grid');
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [collectionsData, artworksData] = await Promise.all([
          fetchCollections(),
          fetchArtworks()
        ]);
        setCollections(collectionsData);
        
        // Create a map of artwork IDs to artwork data
        const artworksMap = artworksData.reduce((acc: Record<string, any>, artwork: any) => {
          acc[artwork.id] = artwork;
          return acc;
        }, {});
        setArtworks(artworksMap);
      } catch (err) {
        console.error('Error loading data:', err);
      }
    };
    loadData();
  }, [fetchCollections, fetchArtworks]);

  const handleAddCollection = async (data: Omit<Collection, 'id'>) => {
    try {
      setLoading(true);
      const id = await addCollection(data);
      const newCollection = { ...data, id } as Collection;
      setCollections([...collections, newCollection]);
      setShowForm(false);
    } catch (err) {
      console.error('Error adding collection:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleEditCollection = async (data: Partial<Collection>) => {
    if (!editingCollection) return;
    
    try {
      setLoading(true);
      await updateCollection(editingCollection.id, data);
      const updatedCollections = collections.map(collection =>
        collection.id === editingCollection.id
          ? { ...collection, ...data }
          : collection
      );
      setCollections(updatedCollections);
      setEditingCollection(null);
    } catch (err) {
      console.error('Error updating collection:', err);
    } finally {
      setLoading(false);
    }
  };

  const filteredCollections = collections.filter(collection =>
    collection.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    collection.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredCollections.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const paginatedCollections = filteredCollections.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  if (collectionsLoading || artworksLoading) {
    return <LoadingSpinner />;
  }

  const renderGridView = () => (
    <div>
      {paginatedCollections.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {paginatedCollections.map((collection) => {
            const collectionPieces = (collection.pieces || [])
              .map(id => artworks[id])
              .filter(Boolean);
            const totalValue = collectionPieces.reduce((sum, art) => sum + art.price, 0);

            return (
              <Card 
                key={collection.id}
                onClick={() => setSelectedCollection(collection)}
              >
                <div className="aspect-video bg-gray-100 relative overflow-hidden">
                  {collection.coverImage ? (
                    <img
                      src={collection.coverImage}
                      alt={collection.name}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center">
                      <BoxesIcon className="h-12 w-12 text-gray-400" />
                    </div>
                  )}
                </div>
                <div className="p-4">
                  <h3 className="text-lg font-semibold text-gray-900 line-clamp-1">{collection.name}</h3>
                  <p className="text-sm text-gray-500 mt-1 line-clamp-2">{collection.description}</p>
                  <div className="mt-4 flex justify-between items-center">
                    <div>
                      <p className="text-sm text-gray-500">Total Value</p>
                      <p className="text-lg font-bold text-gray-900">
                        {profile.currency.symbol}{totalValue.toLocaleString()}
                      </p>
                    </div>
                    <div className="text-right">
                      <p className="text-sm text-gray-500">Pieces</p>
                      <p className="text-lg font-bold text-gray-900">
                        {(collection.pieces || []).length}
                      </p>
                    </div>
                  </div>
                </div>
              </Card>
            );
          })}
        </div>
      ) : (
        <div className="bg-white rounded-lg border border-gray-200 p-8 text-center">
          <BoxesIcon className="h-12 w-12 text-gray-400 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">No collections found</h3>
          <p className="text-gray-500 mb-4">Get started by creating your first collection to organize your artworks.</p>
          <button
            onClick={() => setShowForm(true)}
            className="inline-flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
          >
            <Plus className="h-5 w-5" />
            New Collection
          </button>
        </div>
      )}
    </div>
  );

  const renderListView = () => (
    <div className="bg-white rounded-lg border border-gray-200">
      {paginatedCollections.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-50 border-b border-gray-200">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Image</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Pieces</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Value</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {paginatedCollections.map((collection) => {
                const collectionPieces = (collection.pieces || [])
                  .map(id => artworks[id])
                  .filter(Boolean);
                const totalValue = collectionPieces.reduce((sum, art) => sum + art.price, 0);

                return (
                  <tr 
                    key={collection.id}
                    onClick={() => setSelectedCollection(collection)}
                    className="hover:bg-gray-50 cursor-pointer"
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="h-12 w-12 bg-gray-100 rounded-lg overflow-hidden">
                        {collection.coverImage ? (
                          <img
                            src={collection.coverImage}
                            alt={collection.name}
                            className="h-full w-full object-cover"
                          />
                        ) : (
                          <div className="h-full w-full flex items-center justify-center">
                            <BoxesIcon className="h-6 w-6 text-gray-400" />
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">{collection.name}</div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500 line-clamp-2">{collection.description}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{(collection.pieces || []).length} pieces</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {profile.currency.symbol}{totalValue.toLocaleString()}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
            totalItems={filteredCollections.length}
            itemsPerPage={ITEMS_PER_PAGE}
          />
        </div>
      ) : (
        <div className="p-8 text-center">
          <BoxesIcon className="h-12 w-12 text-gray-400 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">No collections found</h3>
          <p className="text-gray-500 mb-4">Get started by creating your first collection to organize your artworks.</p>
          <button
            onClick={() => setShowForm(true)}
            className="inline-flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
          >
            <Plus className="h-5 w-5" />
            New Collection
          </button>
        </div>
      )}
    </div>
  );

  return (
    <div className="space-y-6">
      <PageHeader 
        title="Collections" 
        buttonLabel="New Collection"
        buttonIcon={Plus}
        onButtonClick={() => setShowForm(true)}
      />

      <div className="flex items-center justify-between gap-4">
        <div className="flex-1">
          <SearchBar 
            placeholder="Search collections..." 
            onSearch={setSearchTerm}
          />
        </div>
        <ViewToggle 
          view={view}
          onViewChange={setView}
          showTableView={false}
        />
      </div>

      {view === 'grid' ? renderGridView() : renderListView()}

      <SlidePanel
        isOpen={!!selectedCollection}
        onClose={() => setSelectedCollection(null)}
        title="Collection Details"
      >
        {selectedCollection && (
          <CollectionDetails
            collection={selectedCollection}
            artworks={artworks}
            onEdit={() => {
              setEditingCollection(selectedCollection);
              setSelectedCollection(null);
            }}
          />
        )}
      </SlidePanel>

      <SlidePanel
        isOpen={showForm || !!editingCollection}
        onClose={() => {
          setShowForm(false);
          setEditingCollection(null);
        }}
        title={editingCollection ? 'Edit Collection' : 'New Collection'}
      >
        <CollectionForm
          onClose={() => {
            setShowForm(false);
            setEditingCollection(null);
          }}
          onSubmit={editingCollection ? handleEditCollection : handleAddCollection}
          initialData={editingCollection}
          loading={loading}
        />
      </SlidePanel>
    </div>
  );
}