import React, { useState } from 'react';
import { X, Tag, Link as LinkIcon, Plus, Search } from 'lucide-react';
import { ArtPiece } from '../types';
import { useCollections } from '../hooks/useCollections';
import { useGallery } from '../hooks/useGallery';
import ImageUpload from './ImageUpload';
import ArtworkTypeSelect from './ArtworkTypeSelect';
import MeasurementsForm from './MeasurementsForm';

type ArtPieceFormProps = {
  onClose: () => void;
  onSubmit: (data: Omit<ArtPiece, 'id'>) => void;
  onDelete?: () => void;
  initialData?: ArtPiece;
  loading?: boolean;
};

export default function ArtPieceForm({ onClose, onSubmit, onDelete, initialData, loading }: ArtPieceFormProps) {
  const [formData, setFormData] = useState({
    title: initialData?.title || '',
    description: initialData?.description || '',
    type: initialData?.type || 'Ring',
    price: initialData?.price || 0,
    cost: initialData?.cost || 0,
    materials: initialData?.materials || [],
    size: initialData?.size || '',
    location: initialData?.location || '',
    images: initialData?.images || [],
    status: initialData?.status || 'available',
    shopLink: initialData?.shopLink || '',
    measurements: initialData?.measurements || {},
    collectionId: initialData?.collectionId
  });

  const [newMaterial, setNewMaterial] = useState('');

  const handleInputChange = (field: string, value: any) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleAddMaterial = () => {
    if (!newMaterial.trim()) return;
    if (!formData.materials.includes(newMaterial.trim())) {
      setFormData(prev => ({
        ...prev,
        materials: [...prev.materials, newMaterial.trim()]
      }));
    }
    setNewMaterial('');
  };

  const handleRemoveMaterial = (material: string) => {
    setFormData(prev => ({
      ...prev,
      materials: prev.materials.filter(m => m !== material)
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="p-6 space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Cover Image
        </label>
        <ImageUpload
          folder="artworks"
          currentUrl={formData.images[0]}
          onFileUpload={(url) => handleInputChange('images', [url, ...formData.images.slice(1)])}
          onFileRemove={() => handleInputChange('images', formData.images.slice(1))}
          artworkId={initialData?.id}
          title={formData.title}
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Title
          </label>
          <input
            type="text"
            value={formData.title}
            onChange={(e) => handleInputChange('title', e.target.value)}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Type
          </label>
          <ArtworkTypeSelect
            value={formData.type}
            onChange={(value) => handleInputChange('type', value)}
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Description
        </label>
        <textarea
          value={formData.description}
          onChange={(e) => handleInputChange('description', e.target.value)}
          rows={3}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Measurements
        </label>
        <MeasurementsForm
          type={formData.type}
          measurements={formData.measurements}
          onChange={(field, value) => {
            handleInputChange('measurements', {
              ...formData.measurements,
              [field]: value
            });
          }}
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Materials
        </label>
        <div className="space-y-2">
          <div className="flex flex-wrap gap-2 mb-2">
            {formData.materials.map((material) => (
              <span
                key={material}
                className="inline-flex items-center gap-1 px-2 py-1 bg-gray-100 rounded-full text-sm"
              >
                {material}
                <button
                  type="button"
                  onClick={() => handleRemoveMaterial(material)}
                  className="p-0.5 hover:bg-gray-200 rounded-full"
                >
                  <X className="h-3 w-3" />
                </button>
              </span>
            ))}
          </div>
          <div className="flex gap-2">
            <input
              type="text"
              value={newMaterial}
              onChange={(e) => setNewMaterial(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && (e.preventDefault(), handleAddMaterial())}
              className="flex-1 px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
              placeholder="Add material..."
            />
            <button
              type="button"
              onClick={handleAddMaterial}
              className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
            >
              Add
            </button>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Price
          </label>
          <input
            type="number"
            value={formData.price}
            onChange={(e) => handleInputChange('price', Number(e.target.value))}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
            min="0"
            step="0.01"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Cost
          </label>
          <input
            type="number"
            value={formData.cost}
            onChange={(e) => handleInputChange('cost', Number(e.target.value))}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
            min="0"
            step="0.01"
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Status
          </label>
          <select
            value={formData.status}
            onChange={(e) => handleInputChange('status', e.target.value)}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
          >
            <option value="available">Available</option>
            <option value="sold">Sold</option>
            <option value="reserved">Reserved</option>
            <option value="loaned">Loaned</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Location
          </label>
          <input
            type="text"
            value={formData.location}
            onChange={(e) => handleInputChange('location', e.target.value)}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Shop Link
        </label>
        <input
          type="url"
          value={formData.shopLink}
          onChange={(e) => handleInputChange('shopLink', e.target.value)}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
          placeholder="https://..."
        />
      </div>

      <div className="flex justify-end gap-3 mt-6">
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 border rounded-lg hover:bg-gray-50"
          disabled={loading}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={loading}
          className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
        >
          {loading ? 'Saving...' : initialData ? 'Save Changes' : 'Add Artwork'}
        </button>
      </div>
    </form>
  );
}