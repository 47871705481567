import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Link } from 'react-router-dom';
import { markdownComponents } from './MarkdownComponents';
import { ArrowLeft } from 'lucide-react';

type BlogPostContentProps = {
  content: string;
  tags: string[];
  author: {
    name: string;
    avatar?: string;
    bio?: string;
  };
};

export default function BlogPostContent({ content, tags, author }: BlogPostContentProps) {
  return (
    <div className="container mx-auto px-6">
      <div className="max-w-3xl mx-auto">
        {/* Tags */}
        <div className="flex flex-wrap gap-2 mb-8">
          {tags.map((tag) => (
            <Link
              key={tag}
              to={`/blog?tag=${encodeURIComponent(tag)}`}
              className="px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm hover:bg-gray-200"
            >
              {tag}
            </Link>
          ))}
        </div>

        {/* Article Content */}
        <div className="prose prose-lg max-w-none">
          <ReactMarkdown 
            remarkPlugins={[remarkGfm]}
            components={markdownComponents}
          >
            {content}
          </ReactMarkdown>
        </div>

        {/* Author Bio */}
        {author.bio && (
          <div className="mt-12 pt-8 border-t">
            <div className="flex items-center gap-4">
              {author.avatar && (
                <img
                  src={author.avatar}
                  alt={author.name}
                  className="w-16 h-16 rounded-full"
                />
              )}
              <div>
                <h3 className="font-semibold text-gray-900">About {author.name}</h3>
                <p className="text-gray-600 mt-1">
                  {author.bio}
                </p>
              </div>
            </div>
          </div>
        )}

        {/* Navigation */}
        <div className="mt-12">
          <Link
            to="/blog"
            className="inline-flex items-center gap-2 text-indigo-600 hover:text-indigo-700"
          >
            <ArrowLeft className="h-4 w-4" />
            Back to Blog
          </Link>
        </div>
      </div>
    </div>
  );
}