import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Crown, Users, DollarSign, ArrowUpRight } from 'lucide-react';
import LoadingSpinner from '../../components/LoadingSpinner';
import StatsCard from '../../components/StatsCard';
import SearchBar from '../../components/SearchBar';
import { fetchStripeSubscriptions } from '../../lib/stripe-admin';

export default function Subscriptions() {
  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadSubscriptions = async () => {
      try {
        setLoading(true);
        const data = await fetchStripeSubscriptions();
        setSubscriptions(data);
      } catch (err) {
        console.error('Error loading subscriptions:', err);
        setError('Failed to load subscriptions');
      } finally {
        setLoading(false);
      }
    };

    loadSubscriptions();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  // Calculate subscription statistics
  const totalSubscriptions = subscriptions.length;
  const activeSubscriptions = subscriptions.filter(sub => 
    sub.status === 'active' || sub.status === 'trialing'
  ).length;
  const monthlyRecurringRevenue = subscriptions.reduce((sum, sub) => {
    if (sub.status !== 'active' && sub.status !== 'trialing') return sum;
    const amount = sub.price?.unit_amount || 0;
    const interval = sub.price?.recurring?.interval;
    return sum + (interval === 'year' ? amount / 12 : amount);
  }, 0) / 100; // Convert from cents to pounds

  const stats = [
    {
      label: 'Total Subscriptions',
      value: totalSubscriptions,
      icon: Users,
      color: 'bg-blue-100 text-blue-600'
    },
    {
      label: 'Active Subscriptions',
      value: activeSubscriptions,
      icon: Crown,
      color: 'bg-green-100 text-green-600',
      change: totalSubscriptions ? `${Math.round((activeSubscriptions / totalSubscriptions) * 100)}%` : 'N/A'
    },
    {
      label: 'Monthly Revenue',
      value: `£${monthlyRecurringRevenue.toFixed(2)}`,
      icon: DollarSign,
      color: 'bg-indigo-100 text-indigo-600'
    }
  ];

  const filteredSubscriptions = subscriptions.filter(sub =>
    sub.user?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    sub.user?.email?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Helmet>
        <title>Subscription Management | Studio Planner Admin</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold text-gray-900">Subscriptions</h1>
        </div>

        {error && (
          <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg">
            {error}
          </div>
        )}

        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {stats.map((stat) => (
            <StatsCard key={stat.label} {...stat} />
          ))}
        </div>

        <SearchBar 
          placeholder="Search subscriptions..." 
          onSearch={setSearchTerm}
        />

        <div className="bg-white rounded-lg border border-gray-200">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-50 border-b border-gray-200">
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Plan</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Period End</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {filteredSubscriptions.map((subscription) => {
                  const planName = subscription.price?.lookup_key?.includes('professional')
                    ? 'Professional'
                    : subscription.price?.lookup_key?.includes('artist')
                    ? 'Artist'
                    : 'Student';

                  return (
                    <tr key={subscription.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4">
                        <div className="flex items-center">
                          {subscription.user?.avatar ? (
                            <img
                              src={subscription.user.avatar}
                              alt={subscription.user.name}
                              className="h-10 w-10 rounded-full"
                            />
                          ) : (
                            <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center">
                              <Users className="h-5 w-5 text-gray-400" />
                            </div>
                          )}
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">{subscription.user?.name}</div>
                            <div className="text-sm text-gray-500">{subscription.user?.email}</div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                          {planName}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                          subscription.status === 'active' || subscription.status === 'trialing'
                            ? 'bg-green-100 text-green-800'
                            : subscription.status === 'past_due'
                            ? 'bg-yellow-100 text-yellow-800'
                            : 'bg-red-100 text-red-800'
                        }`}>
                          {subscription.status.charAt(0).toUpperCase() + subscription.status.slice(1)}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          £{(subscription.price?.unit_amount / 100).toFixed(2)}/
                          {subscription.price?.recurring?.interval}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {new Date(subscription.current_period_end * 1000).toLocaleDateString()}
                        </div>
                        {subscription.cancel_at_period_end && (
                          <div className="text-sm text-red-600">
                            Cancels at period end
                          </div>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <a
                          href={`https://dashboard.stripe.com/subscriptions/${subscription.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-flex items-center gap-1 text-sm text-indigo-600 hover:text-indigo-700"
                        >
                          View in Stripe
                          <ArrowUpRight className="h-4 w-4" />
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}