import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Calendar, Clock, CheckCircle2, Bell, List, Calendar as CalendarIcon } from 'lucide-react';
import MainHeader from '../../components/MainHeader';
import Footer from '../../components/Footer';

export default function Tasks() {
  return (
    <>
      <Helmet>
        <title>Art Business Task Management Software | Studio Planner</title>
        <meta 
          name="description" 
          content="Professional task management software for artists. Track deadlines, manage projects, and stay organized with our comprehensive task management tools."
        />
        <meta 
          name="keywords" 
          content="art task management, artist project management, deadline tracking, studio organization"
        />
      </Helmet>

      <MainHeader />

      <main>
        {/* Hero Section */}
        <div className="bg-gradient-to-b from-orange-50 to-white">
          <div className="container mx-auto px-6 py-24">
            <div className="max-w-3xl mx-auto text-center">
              <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
                Stay Organized and On Track
              </h1>
              <p className="text-xl text-gray-600 mb-8">
                Manage your art projects, track deadlines, and organize your studio tasks efficiently with our professional task management tools.
              </p>
              <div className="flex justify-center gap-4">
                <Link
                  to="/register"
                  className="px-8 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Features Grid */}
        <div className="container mx-auto px-6 py-24">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-orange-100 text-orange-600 rounded-lg flex items-center justify-center mb-4">
                <Calendar className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Calendar View</h3>
              <p className="text-gray-600">
                Visualize your tasks and deadlines in an intuitive calendar interface.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-blue-100 text-blue-600 rounded-lg flex items-center justify-center mb-4">
                <Clock className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Deadline Tracking</h3>
              <p className="text-gray-600">
                Never miss a deadline with our comprehensive tracking system.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-green-100 text-green-600 rounded-lg flex items-center justify-center mb-4">
                <CheckCircle2 className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Task Progress</h3>
              <p className="text-gray-600">
                Track task completion and monitor project progress.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-purple-100 text-purple-600 rounded-lg flex items-center justify-center mb-4">
                <Bell className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Smart Reminders</h3>
              <p className="text-gray-600">
                Get notified about upcoming tasks and important deadlines.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-pink-100 text-pink-600 rounded-lg flex items-center justify-center mb-4">
                <List className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Task Lists</h3>
              <p className="text-gray-600">
                Organize tasks into lists and categories for better management.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-yellow-100 text-yellow-600 rounded-lg flex items-center justify-center mb-4">
                <CalendarIcon className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Project Planning</h3>
              <p className="text-gray-600">
                Plan and schedule your art projects with our planning tools.
              </p>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="bg-white">
          <div className="container mx-auto px-6 py-24">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-bold text-gray-900 mb-6">
                Ready to get organized?
              </h2>
              <p className="text-xl text-gray-600 mb-8">
                Join other artists who are using Studio Planner to manage their tasks professionally.
              </p>
              <Link
                to="/register"
                className="inline-block px-8 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
}