import React from 'react';
import { format } from 'date-fns';

type BlogPostHeroProps = {
  title: string;
  excerpt: string;
  coverImage: string;
  author: {
    name: string;
    avatar?: string;
  };
  publishedAt: string;
};

export default function BlogPostHero({ 
  title, 
  excerpt, 
  coverImage, 
  author, 
  publishedAt 
}: BlogPostHeroProps) {
  return (
    <div className="w-full h-[60vh] relative bg-gray-900 mb-12">
      <img
        src={coverImage}
        alt={title}
        className="w-full h-full object-cover opacity-50"
      />
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="max-w-4xl mx-auto px-6 text-center text-white">
          <h1 className="text-5xl font-bold mb-6">{title}</h1>
          <p className="text-xl opacity-90 mb-8">{excerpt}</p>
          <div className="flex items-center justify-center gap-4 text-sm">
            {author.avatar && (
              <img
                src={author.avatar}
                alt={author.name}
                className="w-12 h-12 rounded-full border-2 border-white"
              />
            )}
            <div className="text-left">
              <div className="font-medium">{author.name}</div>
              <time dateTime={publishedAt} className="opacity-75">
                {format(new Date(publishedAt), 'MMMM d, yyyy')}
              </time>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}