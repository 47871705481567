import React, { useRef, useState } from 'react';
import { Upload, X } from 'lucide-react';
import { useStorage, StorageFolder } from '../lib/storage';
import { useGallery } from '../hooks/useGallery';

type ImageUploadProps = {
  onFileUpload: (url: string) => void;
  onFileRemove?: () => void;
  currentUrl?: string;
  folder: StorageFolder;
  accept?: string;
  maxSize?: number; // in MB
  artworkId?: string;
  title?: string;
};

export default function ImageUpload({
  onFileUpload,
  onFileRemove,
  currentUrl,
  folder,
  accept = 'image/*',
  maxSize = 5,
  artworkId,
  title
}: ImageUploadProps) {
  const { upload, remove } = useStorage();
  const { addImage } = useGallery();
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Check file size
    if (file.size > maxSize * 1024 * 1024) {
      alert(`File size must be less than ${maxSize}MB`);
      return;
    }

    try {
      setUploading(true);
      // Upload to storage
      const url = await upload(file, folder);

      // Add to gallery if it's an artwork cover
      if (folder === 'artworks' && artworkId) {
        await addImage({
          url,
          title: `Cover image for ${title || 'artwork'}`,
          description: '',
          tags: ['cover'],
          linkedArtworkId: artworkId
        });
      }

      onFileUpload(url);
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Failed to upload file. Please try again.');
    } finally {
      setUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleRemove = async () => {
    if (!currentUrl || !onFileRemove) return;

    try {
      await remove(currentUrl);
      onFileRemove();
    } catch (error) {
      console.error('Error removing file:', error);
      alert('Failed to remove file. Please try again.');
    }
  };

  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        accept={accept}
        onChange={handleFileSelect}
        className="hidden"
      />
      
      {currentUrl ? (
        <div className="relative group">
          <img
            src={currentUrl}
            alt="Uploaded file"
            className="w-full h-full object-cover rounded-lg"
          />
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all">
            <div className="flex gap-2">
              <button
                type="button"
                onClick={() => fileInputRef.current?.click()}
                className="p-2 bg-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                disabled={uploading}
              >
                <Upload className="h-4 w-4 text-gray-600" />
              </button>
              {onFileRemove && (
                <button
                  type="button"
                  onClick={handleRemove}
                  className="p-2 bg-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                >
                  <X className="h-4 w-4 text-gray-600" />
                </button>
              )}
            </div>
          </div>
          {uploading && (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="animate-spin rounded-full h-8 w-8 border-2 border-white border-t-transparent"></div>
            </div>
          )}
        </div>
      ) : (
        <button
          type="button"
          onClick={() => fileInputRef.current?.click()}
          className="w-full h-32 flex flex-col items-center justify-center border-2 border-dashed border-gray-300 rounded-lg hover:border-gray-400 transition-colors"
          disabled={uploading}
        >
          <Upload className="h-8 w-8 text-gray-400 mb-2" />
          <span className="text-sm text-gray-500">
            {uploading ? 'Uploading...' : 'Click to upload'}
          </span>
        </button>
      )}
    </div>
  );
}