import React from 'react';
import { X } from 'lucide-react';
import { useProfile } from '../contexts/ProfileContext';
import { artworkTypes } from '../lib/artwork-types';

export type Filters = {
  types: string[];
  locations: string[];
  status: string[];
  collections: string[];
  priceRange: {
    min: number | null;
    max: number | null;
  };
  costRange: {
    min: number | null;
    max: number | null;
  };
};

type FilterMenuProps = {
  isOpen: boolean;
  onClose: () => void;
  filters: Filters;
  onChange: (filters: Filters) => void;
  locations: string[];
  collections: { id: string; name: string; }[];
  artworks: any[]; // Add artworks prop
};

export function countActiveFilters(filters: Filters): number {
  let count = 0;
  
  // Count array filters
  count += filters.types.length;
  count += filters.locations.length;
  count += filters.status.length;
  count += filters.collections.length;
  
  // Count price range filters
  if (filters.priceRange.min !== null) count++;
  if (filters.priceRange.max !== null) count++;
  
  // Count cost range filters
  if (filters.costRange.min !== null) count++;
  if (filters.costRange.max !== null) count++;
  
  return count;
}

export default function FilterMenu({ 
  isOpen, 
  onClose, 
  filters, 
  onChange,
  locations = [],
  collections = [],
  artworks = [] // Add artworks parameter
}: FilterMenuProps) {
  const { profile } = useProfile();

  // Get unique artwork types that exist in the artist's inventory
  const existingTypes = Array.from(new Set(artworks.map(art => art.type)));

  // Group existing types by category
  const groupedTypes = existingTypes.reduce((acc, type) => {
    const [category] = type.split(' - ');
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(type);
    return acc;
  }, {} as Record<string, string[]>);

  const handleCheckboxChange = (
    category: keyof Pick<Filters, 'types' | 'locations' | 'status' | 'collections'>,
    value: string
  ) => {
    const currentValues = filters[category];
    const newValues = currentValues.includes(value)
      ? currentValues.filter(v => v !== value)
      : [...currentValues, value];
    
    onChange({
      ...filters,
      [category]: newValues
    });
  };

  const handleRangeChange = (
    category: 'priceRange' | 'costRange',
    field: 'min' | 'max',
    value: string
  ) => {
    const numValue = value ? Number(value) : null;
    onChange({
      ...filters,
      [category]: {
        ...filters[category],
        [field]: numValue
      }
    });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-y-0 right-0 w-80 bg-white shadow-xl transform transition-transform duration-300 ease-in-out z-40 overflow-y-auto">
      <div className="p-6 space-y-6">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-semibold">Filters</h3>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="space-y-6">
          {/* Type Filter */}
          {Object.entries(groupedTypes).length > 0 && (
            <div>
              <h4 className="font-medium mb-2">Type</h4>
              {Object.entries(groupedTypes).map(([category, types]) => (
                <div key={category} className="mb-4">
                  <h5 className="text-sm font-medium text-gray-500 mb-2">{category}</h5>
                  {types.map(type => (
                    <label key={type} className="flex items-center gap-2 mb-2">
                      <input
                        type="checkbox"
                        checked={filters.types.includes(type)}
                        onChange={() => handleCheckboxChange('types', type)}
                        className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      <span className="text-sm text-gray-700">{type.split(' - ')[1]}</span>
                    </label>
                  ))}
                </div>
              ))}
            </div>
          )}

          {/* Location Filter */}
          {locations.length > 0 && (
            <div>
              <h4 className="font-medium mb-2">Location</h4>
              {locations.map(location => (
                <label key={location} className="flex items-center gap-2 mb-2">
                  <input
                    type="checkbox"
                    checked={filters.locations.includes(location)}
                    onChange={() => handleCheckboxChange('locations', location)}
                    className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <span className="text-sm text-gray-700">{location}</span>
                </label>
              ))}
            </div>
          )}

          {/* Status Filter */}
          <div>
            <h4 className="font-medium mb-2">Status</h4>
            {['available', 'sold', 'reserved', 'loaned'].map(status => (
              <label key={status} className="flex items-center gap-2 mb-2">
                <input
                  type="checkbox"
                  checked={filters.status.includes(status)}
                  onChange={() => handleCheckboxChange('status', status)}
                  className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <span className="text-sm text-gray-700 capitalize">{status}</span>
              </label>
            ))}
          </div>

          {/* Collection Filter */}
          {collections.length > 0 && (
            <div>
              <h4 className="font-medium mb-2">Collection</h4>
              {collections.map(collection => (
                <label key={collection.id} className="flex items-center gap-2 mb-2">
                  <input
                    type="checkbox"
                    checked={filters.collections.includes(collection.id)}
                    onChange={() => handleCheckboxChange('collections', collection.id)}
                    className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <span className="text-sm text-gray-700">{collection.name}</span>
                </label>
              ))}
            </div>
          )}

          {/* Price Range Filter */}
          <div>
            <h4 className="font-medium mb-2">Price Range ({profile.currency.symbol})</h4>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <label className="text-sm text-gray-600">Min</label>
                <input
                  type="number"
                  value={filters.priceRange.min || ''}
                  onChange={(e) => handleRangeChange('priceRange', 'min', e.target.value)}
                  className="w-full px-2 py-1 border rounded-lg"
                  placeholder="Min"
                />
              </div>
              <div>
                <label className="text-sm text-gray-600">Max</label>
                <input
                  type="number"
                  value={filters.priceRange.max || ''}
                  onChange={(e) => handleRangeChange('priceRange', 'max', e.target.value)}
                  className="w-full px-2 py-1 border rounded-lg"
                  placeholder="Max"
                />
              </div>
            </div>
          </div>

          {/* Cost Range Filter */}
          <div>
            <h4 className="font-medium mb-2">Cost Range ({profile.currency.symbol})</h4>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <label className="text-sm text-gray-600">Min</label>
                <input
                  type="number"
                  value={filters.costRange.min || ''}
                  onChange={(e) => handleRangeChange('costRange', 'min', e.target.value)}
                  className="w-full px-2 py-1 border rounded-lg"
                  placeholder="Min"
                />
              </div>
              <div>
                <label className="text-sm text-gray-600">Max</label>
                <input
                  type="number"
                  value={filters.costRange.max || ''}
                  onChange={(e) => handleRangeChange('costRange', 'max', e.target.value)}
                  className="w-full px-2 py-1 border rounded-lg"
                  placeholder="Max"
                />
              </div>
            </div>
          </div>

          <div className="pt-4 border-t">
            <button
              onClick={() => onChange({
                types: [],
                locations: [],
                status: [],
                collections: [],
                priceRange: { min: null, max: null },
                costRange: { min: null, max: null },
              })}
              className="w-full px-4 py-2 text-sm text-gray-600 hover:text-gray-900"
            >
              Clear All Filters
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}