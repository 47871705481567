import React, { useRef, useState, useCallback } from 'react';
import { Upload, X, Loader, Film } from 'lucide-react';
import { useStorage, StorageFolder } from '../lib/storage';

type MultiFileUploadProps = {
  onFilesUpload: (urls: string[]) => void;
  folder: StorageFolder;
  accept?: string;
  maxSize?: number; // in MB per file
  allowVideo?: boolean;
};

export default function MultiFileUpload({
  onFilesUpload,
  folder,
  accept = 'image/*,video/*',
  maxSize = 50, // Default 50MB to accommodate videos
  allowVideo = true
}: MultiFileUploadProps) {
  const { upload } = useStorage();
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState<{ [key: string]: number }>({});
  const [dragActive, setDragActive] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const isVideoFile = (file: File) => file.type.startsWith('video/');

  const handleFiles = async (files: File[]) => {
    if (files.length === 0) return;

    // Check file sizes and types
    const invalidFiles = files.filter(file => {
      if (file.size > maxSize * 1024 * 1024) return true;
      if (!allowVideo && isVideoFile(file)) return true;
      return false;
    });

    if (invalidFiles.length > 0) {
      const videoMessage = !allowVideo ? ' Videos are not allowed.' : '';
      alert(`Some files exceed the ${maxSize}MB limit or are not supported.${videoMessage}`);
    }

    const validFiles = files.filter(file => {
      if (file.size > maxSize * 1024 * 1024) return false;
      if (!allowVideo && isVideoFile(file)) return false;
      return true;
    });

    if (validFiles.length === 0) return;

    try {
      setUploading(true);
      const uploadPromises = validFiles.map(async file => {
        try {
          const url = await upload(file, folder);
          setProgress(prev => ({
            ...prev,
            [file.name]: 100
          }));
          return url;
        } catch (error) {
          console.error(`Error uploading ${file.name}:`, error);
          return null;
        }
      });

      const urls = (await Promise.all(uploadPromises)).filter((url): url is string => url !== null);
      if (urls.length > 0) {
        onFilesUpload(urls);
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      alert('Some files failed to upload. Please try again.');
    } finally {
      setUploading(false);
      setProgress({});
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleFilesSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    handleFiles(files);
  };

  const handleDrag = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  }, []);

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const files = Array.from(e.dataTransfer.files);
    handleFiles(files);
  }, []);

  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        accept={accept}
        multiple
        onChange={handleFilesSelect}
        className="hidden"
      />
      
      <div
        onClick={() => fileInputRef.current?.click()}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        className={`w-full h-48 flex flex-col items-center justify-center border-2 border-dashed rounded-lg transition-colors cursor-pointer ${
          dragActive
            ? 'border-indigo-500 bg-indigo-50'
            : 'border-gray-300 hover:border-gray-400'
        }`}
      >
        {uploading ? (
          <>
            <Loader className="h-8 w-8 text-gray-400 mb-2 animate-spin" />
            <span className="text-sm text-gray-500">Uploading files...</span>
          </>
        ) : (
          <>
            <div className="flex items-center gap-2 mb-2">
              <Upload className="h-8 w-8 text-gray-400" />
              {allowVideo && <Film className="h-8 w-8 text-gray-400" />}
            </div>
            <span className="text-sm text-gray-500">
              Drag and drop {allowVideo ? 'photos or videos' : 'photos'} here or click to browse
            </span>
            <span className="text-xs text-gray-400 mt-1">
              Maximum file size: {maxSize}MB per file
            </span>
            {allowVideo && (
              <span className="text-xs text-gray-400">
                Supported formats: Images (JPG, PNG, GIF) and Videos (MP4, WebM)
              </span>
            )}
          </>
        )}
      </div>

      {Object.keys(progress).length > 0 && (
        <div className="mt-4 space-y-2">
          {Object.entries(progress).map(([filename, percent]) => (
            <div key={filename} className="text-sm">
              <div className="flex justify-between mb-1">
                <span className="text-gray-700">{filename}</span>
                <span className="text-gray-500">{percent}%</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-1.5">
                <div
                  className="bg-indigo-600 h-1.5 rounded-full transition-all duration-300"
                  style={{ width: `${percent}%` }}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}