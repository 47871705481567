import React, { useState } from 'react';
import { ArrowLeft, Search, Plus } from 'lucide-react';

type PromotionStep2Props = {
  type: 'instagram' | 'blog' | 'pr';
  artworks: Record<string, any>;
  collections: Record<string, any>;
  onSelect: (artwork: any, collection: any) => void;
  onBack: () => void;
};

export default function PromotionStep2({ type, artworks, collections, onSelect, onBack }: PromotionStep2Props) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState<'artwork' | 'collection'>('artwork');

  // Convert objects to arrays and ensure all required fields exist
  const artworksList = Object.values(artworks).map(art => ({
    ...art,
    title: art.title || 'Untitled',
    description: art.description || '',
    images: art.images || []
  }));

  const collectionsList = Object.values(collections).map(col => ({
    ...col,
    name: col.name || 'Untitled Collection',
    description: col.description || '',
    coverImage: col.coverImage || ''
  }));

  // Filter with null checks
  const filteredArtworks = artworksList.filter(art => 
    art.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    art.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredCollections = collectionsList.filter(col =>
    col.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    col.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-4">
        <button
          onClick={onBack}
          className="p-2 hover:bg-gray-100 rounded-lg"
        >
          <ArrowLeft className="h-5 w-5" />
        </button>
        <h2 className="text-2xl font-bold">Select Content</h2>
      </div>

      <div className="flex gap-4 border-b">
        <button
          onClick={() => setSelectedType('artwork')}
          className={`px-4 py-2 border-b-2 transition-colors ${
            selectedType === 'artwork'
              ? 'border-indigo-600 text-indigo-600'
              : 'border-transparent text-gray-500 hover:text-gray-700'
          }`}
        >
          Artworks
        </button>
        <button
          onClick={() => setSelectedType('collection')}
          className={`px-4 py-2 border-b-2 transition-colors ${
            selectedType === 'collection'
              ? 'border-indigo-600 text-indigo-600'
              : 'border-transparent text-gray-500 hover:text-gray-700'
          }`}
        >
          Collections
        </button>
      </div>

      <div className="relative">
        <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={`Search ${selectedType === 'artwork' ? 'artworks' : 'collections'}...`}
          className="w-full pl-10 pr-4 py-2 border rounded-lg"
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {selectedType === 'artwork' ? (
          filteredArtworks.length > 0 ? (
            filteredArtworks.map((artwork) => (
              <button
                key={artwork.id}
                onClick={() => onSelect(artwork, null)}
                className="flex items-start gap-4 p-4 border rounded-lg hover:bg-gray-50 text-left"
              >
                {artwork.images?.[0] ? (
                  <img
                    src={artwork.images[0]}
                    alt={artwork.title}
                    className="w-24 h-24 object-cover rounded-lg"
                  />
                ) : (
                  <div className="w-24 h-24 bg-gray-100 rounded-lg flex items-center justify-center">
                    <Plus className="h-6 w-6 text-gray-400" />
                  </div>
                )}
                <div>
                  <h3 className="font-medium">{artwork.title}</h3>
                  <p className="text-sm text-gray-500 line-clamp-2">{artwork.description}</p>
                </div>
              </button>
            ))
          ) : (
            <div className="col-span-2 text-center py-8 text-gray-500">
              No artworks found matching your search.
            </div>
          )
        ) : (
          filteredCollections.length > 0 ? (
            filteredCollections.map((collection) => (
              <button
                key={collection.id}
                onClick={() => onSelect(null, collection)}
                className="flex items-start gap-4 p-4 border rounded-lg hover:bg-gray-50 text-left"
              >
                {collection.coverImage ? (
                  <img
                    src={collection.coverImage}
                    alt={collection.name}
                    className="w-24 h-24 object-cover rounded-lg"
                  />
                ) : (
                  <div className="w-24 h-24 bg-gray-100 rounded-lg flex items-center justify-center">
                    <Plus className="h-6 w-6 text-gray-400" />
                  </div>
                )}
                <div>
                  <h3 className="font-medium">{collection.name}</h3>
                  <p className="text-sm text-gray-500 line-clamp-2">{collection.description}</p>
                </div>
              </button>
            ))
          ) : (
            <div className="col-span-2 text-center py-8 text-gray-500">
              No collections found matching your search.
            </div>
          )
        )}
      </div>
    </div>
  );
}