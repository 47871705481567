import React from 'react';
import { X } from 'lucide-react';
import { useProfile } from '../contexts/ProfileContext';

export type ExpenseFilters = {
  categories: string[];
  types: ('studio' | 'artwork' | 'collection')[];
  dateRange: {
    start: string | null;
    end: string | null;
  };
  amountRange: {
    min: number | null;
    max: number | null;
  };
  hasInvoice?: boolean;
  hasLinks?: boolean;
};

type ExpenseFilterMenuProps = {
  isOpen: boolean;
  onClose: () => void;
  filters: ExpenseFilters;
  onChange: (filters: ExpenseFilters) => void;
  categories: string[];
};

export default function ExpenseFilterMenu({
  isOpen,
  onClose,
  filters,
  onChange,
  categories,
}: ExpenseFilterMenuProps) {
  const { profile } = useProfile();

  const handleCheckboxChange = (
    category: 'categories' | 'types',
    value: string
  ) => {
    const currentValues = filters[category];
    const newValues = currentValues.includes(value)
      ? currentValues.filter(v => v !== value)
      : [...currentValues, value];
    
    onChange({
      ...filters,
      [category]: newValues
    });
  };

  const handleRangeChange = (
    category: 'dateRange' | 'amountRange',
    field: 'start' | 'end' | 'min' | 'max',
    value: string
  ) => {
    const numValue = value ? (category === 'amountRange' ? Number(value) : value) : null;
    onChange({
      ...filters,
      [category]: {
        ...filters[category],
        [field]: numValue
      }
    });
  };

  const handleToggleChange = (field: 'hasInvoice' | 'hasLinks') => {
    onChange({
      ...filters,
      [field]: !filters[field]
    });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-y-0 right-0 w-80 bg-white shadow-xl transform transition-transform duration-300 ease-in-out z-40 overflow-y-auto">
      <div className="p-6 space-y-6">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-semibold">Filters</h3>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="space-y-6">
          <div>
            <h4 className="font-medium mb-2">Category</h4>
            {categories.map(category => (
              <label key={category} className="flex items-center gap-2 mb-2">
                <input
                  type="checkbox"
                  checked={filters.categories.includes(category)}
                  onChange={() => handleCheckboxChange('categories', category)}
                  className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <span className="text-sm text-gray-700">{category}</span>
              </label>
            ))}
          </div>

          <div>
            <h4 className="font-medium mb-2">Type</h4>
            {['studio', 'artwork', 'collection'].map(type => (
              <label key={type} className="flex items-center gap-2 mb-2">
                <input
                  type="checkbox"
                  checked={filters.types.includes(type as any)}
                  onChange={() => handleCheckboxChange('types', type)}
                  className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <span className="text-sm text-gray-700 capitalize">{type}</span>
              </label>
            ))}
          </div>

          <div>
            <h4 className="font-medium mb-2">Date Range</h4>
            <div className="space-y-2">
              <div>
                <label className="text-sm text-gray-600">From</label>
                <input
                  type="date"
                  value={filters.dateRange.start || ''}
                  onChange={(e) => handleRangeChange('dateRange', 'start', e.target.value)}
                  className="w-full px-2 py-1 border rounded-lg"
                />
              </div>
              <div>
                <label className="text-sm text-gray-600">To</label>
                <input
                  type="date"
                  value={filters.dateRange.end || ''}
                  onChange={(e) => handleRangeChange('dateRange', 'end', e.target.value)}
                  className="w-full px-2 py-1 border rounded-lg"
                />
              </div>
            </div>
          </div>

          <div>
            <h4 className="font-medium mb-2">Amount Range</h4>
            <div className="space-y-2">
              <div>
                <label className="text-sm text-gray-600">Min ({profile.currency.symbol})</label>
                <input
                  type="number"
                  value={filters.amountRange.min || ''}
                  onChange={(e) => handleRangeChange('amountRange', 'min', e.target.value)}
                  className="w-full px-2 py-1 border rounded-lg"
                  placeholder="Min amount"
                />
              </div>
              <div>
                <label className="text-sm text-gray-600">Max ({profile.currency.symbol})</label>
                <input
                  type="number"
                  value={filters.amountRange.max || ''}
                  onChange={(e) => handleRangeChange('amountRange', 'max', e.target.value)}
                  className="w-full px-2 py-1 border rounded-lg"
                  placeholder="Max amount"
                />
              </div>
            </div>
          </div>

          <div>
            <h4 className="font-medium mb-2">Additional Filters</h4>
            <div className="space-y-2">
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={filters.hasInvoice}
                  onChange={() => handleToggleChange('hasInvoice')}
                  className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <span className="text-sm text-gray-700">Has Invoice</span>
              </label>
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={filters.hasLinks}
                  onChange={() => handleToggleChange('hasLinks')}
                  className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <span className="text-sm text-gray-700">Has Linked Items</span>
              </label>
            </div>
          </div>

          <div className="pt-4 border-t">
            <button
              onClick={() => onChange({
                categories: [],
                types: [],
                dateRange: { start: null, end: null },
                amountRange: { min: null, max: null },
                hasInvoice: undefined,
                hasLinks: undefined,
              })}
              className="w-full px-4 py-2 text-sm text-gray-600 hover:text-gray-900"
            >
              Clear All Filters
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}