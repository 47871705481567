import { useState, useCallback } from 'react';
import { collection, addDoc, updateDoc, doc, deleteDoc, Timestamp, query, where, getDocs, runTransaction } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { ArtPiece } from '../types';

// Helper function to clean data before sending to Firestore
function cleanDataForFirestore(data: any) {
  // Remove undefined values and convert empty strings to null
  const cleaned = Object.entries(data).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      // Convert empty strings to null for optional fields
      if (typeof value === 'string' && value.trim() === '') {
        acc[key] = null;
      } else {
        acc[key] = value;
      }
    }
    return acc;
  }, {} as Record<string, any>);

  // Convert any Date objects to Timestamps
  if (cleaned.createdAt && !(cleaned.createdAt instanceof Timestamp)) {
    cleaned.createdAt = Timestamp.fromDate(new Date(cleaned.createdAt));
  }
  if (cleaned.updatedAt && !(cleaned.updatedAt instanceof Timestamp)) {
    cleaned.updatedAt = Timestamp.fromDate(new Date(cleaned.updatedAt));
  }

  // Ensure arrays are defined
  cleaned.materials = cleaned.materials || [];
  cleaned.images = cleaned.images || [];

  // Ensure measurements is an object or null
  if (!cleaned.measurements || Object.keys(cleaned.measurements).length === 0) {
    cleaned.measurements = null;
  }

  // Ensure numeric fields are numbers
  if (cleaned.price) cleaned.price = Number(cleaned.price);
  if (cleaned.cost) cleaned.cost = Number(cleaned.cost);

  // Ensure optional fields are null if empty
  if (!cleaned.collectionId) cleaned.collectionId = null;
  if (!cleaned.shopLink) cleaned.shopLink = null;
  if (!cleaned.size) cleaned.size = null;

  return cleaned;
}

export function useArtworks() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchArtworks = useCallback(async () => {
    if (!currentUser) return [];

    try {
      setLoading(true);
      setError(null);
      const q = query(
        collection(db, 'artworks'),
        where('artistId', '==', currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const artworks = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate().toISOString(),
        updatedAt: doc.data().updatedAt?.toDate()?.toISOString()
      })) as ArtPiece[];
      setLoading(false);
      return artworks;
    } catch (err) {
      setError(err as Error);
      setLoading(false);
      throw err;
    }
  }, [currentUser]);

  const addArtwork = useCallback(async (data: Omit<ArtPiece, 'id'>) => {
    if (!currentUser) throw new Error('User not authenticated');

    setLoading(true);
    setError(null);
    try {
      const cleanedData = cleanDataForFirestore({
        ...data,
        artistId: currentUser.uid,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now()
      });

      const docRef = await addDoc(collection(db, 'artworks'), cleanedData);

      // If artwork is part of a collection, update the collection
      if (cleanedData.collectionId) {
        const collectionRef = doc(db, 'collections', cleanedData.collectionId);
        await runTransaction(db, async (transaction) => {
          const collectionDoc = await transaction.get(collectionRef);
          if (collectionDoc.exists()) {
            const pieces = collectionDoc.data().pieces || [];
            if (!pieces.includes(docRef.id)) {
              transaction.update(collectionRef, {
                pieces: [...pieces, docRef.id],
                updatedAt: Timestamp.now()
              });
            }
          }
        });
      }

      setLoading(false);
      return docRef.id;
    } catch (err) {
      setError(err as Error);
      setLoading(false);
      throw err;
    }
  }, [currentUser]);

  const updateArtwork = useCallback(async (id: string, data: Partial<ArtPiece>) => {
    if (!currentUser) throw new Error('User not authenticated');
    if (!id) throw new Error('Artwork ID is required');

    try {
      setLoading(true);
      setError(null);

      // Get current artwork data
      const artworkRef = doc(db, 'artworks', id);
      const artworkDoc = await getDocs(query(collection(db, 'artworks')));
      const currentArtwork = artworkDoc.docs.find(doc => doc.id === id);
      
      if (!currentArtwork) {
        throw new Error('Artwork not found');
      }

      const currentData = currentArtwork.data();
      const cleanedData = cleanDataForFirestore({
        ...data,
        artistId: currentUser.uid,
        updatedAt: Timestamp.now()
      });

      await runTransaction(db, async (transaction) => {
        const oldCollectionId = currentData.collectionId;
        const newCollectionId = cleanedData.collectionId;

        // Remove from old collection if needed
        if (oldCollectionId && (!newCollectionId || oldCollectionId !== newCollectionId)) {
          const oldCollectionRef = doc(db, 'collections', oldCollectionId);
          const oldCollectionDoc = await transaction.get(oldCollectionRef);
          
          if (oldCollectionDoc.exists()) {
            const oldPieces = oldCollectionDoc.data().pieces || [];
            transaction.update(oldCollectionRef, {
              pieces: oldPieces.filter((p: string) => p !== id),
              updatedAt: Timestamp.now()
            });
          }
        }

        // Add to new collection if needed
        if (newCollectionId && (!oldCollectionId || oldCollectionId !== newCollectionId)) {
          const newCollectionRef = doc(db, 'collections', newCollectionId);
          const newCollectionDoc = await transaction.get(newCollectionRef);
          
          if (!newCollectionDoc.exists()) {
            throw new Error('New collection not found');
          }

          const newPieces = newCollectionDoc.data().pieces || [];
          if (!newPieces.includes(id)) {
            transaction.update(newCollectionRef, {
              pieces: [...newPieces, id],
              updatedAt: Timestamp.now()
            });
          }
        }

        // Update artwork with cleaned data
        transaction.update(artworkRef, cleanedData);
      });

      setLoading(false);
    } catch (err) {
      console.error('Error updating artwork:', err);
      setError(err as Error);
      setLoading(false);
      throw err;
    }
  }, [currentUser]);

  const deleteArtwork = useCallback(async (id: string) => {
    if (!currentUser) throw new Error('User not authenticated');

    setLoading(true);
    setError(null);
    try {
      await runTransaction(db, async (transaction) => {
        const artworkRef = doc(db, 'artworks', id);
        const artworkDoc = await transaction.get(artworkRef);
        
        if (!artworkDoc.exists()) {
          throw new Error('Artwork not found');
        }

        const { collectionId } = artworkDoc.data();

        // Remove from collection if it belongs to one
        if (collectionId) {
          const collectionRef = doc(db, 'collections', collectionId);
          const collectionDoc = await transaction.get(collectionRef);
          if (collectionDoc.exists()) {
            const pieces = collectionDoc.data().pieces || [];
            transaction.update(collectionRef, {
              pieces: pieces.filter(p => p !== id),
              updatedAt: Timestamp.now()
            });
          }
        }

        // Delete the artwork document
        transaction.delete(artworkRef);
      });
      setLoading(false);
    } catch (err) {
      setError(err as Error);
      setLoading(false);
      throw err;
    }
  }, [currentUser]);

  return {
    fetchArtworks,
    addArtwork,
    updateArtwork,
    deleteArtwork,
    loading,
    error
  };
}