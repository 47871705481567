import React from 'react';
import { Globe, Lock, ExternalLink } from 'lucide-react';
import { Link } from 'react-router-dom';

type PublicProfileToggleProps = {
  isPublic: boolean;
  onChange: (isPublic: boolean) => void;
  profileId?: string;
};

export default function PublicProfileToggle({ isPublic, onChange, profileId }: PublicProfileToggleProps) {
  return (
    <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
      <div className="flex items-center gap-3">
        {isPublic ? (
          <Globe className="h-5 w-5 text-green-600" />
        ) : (
          <Lock className="h-5 w-5 text-gray-600" />
        )}
        <div>
          <h3 className="font-medium text-gray-900">Public Profile</h3>
          <p className="text-sm text-gray-500">
            {isPublic 
              ? 'Your profile is visible to everyone' 
              : 'Your profile is private'}
          </p>
          {isPublic && profileId && (
            <a
              href={`/artist/${profileId}`}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center gap-1 text-sm text-indigo-600 hover:text-indigo-700 mt-2"
            >
              View public profile
              <ExternalLink className="h-3 w-3" />
            </a>
          )}
        </div>
      </div>
      <button
        onClick={() => onChange(!isPublic)}
        className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
          isPublic ? 'bg-green-600' : 'bg-gray-200'
        }`}
      >
        <span
          className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
            isPublic ? 'translate-x-6' : 'translate-x-1'
          }`}
        />
      </button>
    </div>
  );
}