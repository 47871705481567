import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Task } from '../types';
import { useTasks } from '../hooks/useTasks';
import { useArtworks } from '../hooks/useArtworks';
import { useExpenses } from '../hooks/useExpenses';
import { useProfile } from '../contexts/ProfileContext';
import TaskCalendar from './TaskCalendar';
import TaskList from './TaskList';
import LoadingSpinner from './LoadingSpinner';
import { BarChart, TrendingUp, DollarSign } from 'lucide-react';

export default function DashboardContent() {
  const navigate = useNavigate();
  const { profile } = useProfile();
  const { fetchTasks, updateTask, loading: tasksLoading } = useTasks();
  const { fetchArtworks } = useArtworks();
  const { fetchExpenses } = useExpenses();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [showCompleted, setShowCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({
    totalArtworks: 0,
    totalValue: 0,
    monthlyExpenses: 0
  });

  useEffect(() => {
    const loadData = async () => {
      try {
        const [tasksData, artworksData, expensesData] = await Promise.all([
          fetchTasks(),
          fetchArtworks(),
          fetchExpenses()
        ]);
        
        setTasks(tasksData);

        // Calculate stats
        const now = new Date();
        const monthStart = new Date(now.getFullYear(), now.getMonth(), 1);
        
        const totalValue = artworksData.reduce((sum, art) => sum + (art.price || 0), 0);
        const monthlyExpenses = expensesData
          .filter(exp => new Date(exp.date) >= monthStart)
          .reduce((sum, exp) => sum + exp.amount, 0);
        
        setStats({
          totalArtworks: artworksData.length,
          totalValue,
          monthlyExpenses
        });

      } catch (err) {
        console.error('Error loading data:', err);
      }
    };
    loadData();
  }, [fetchTasks, fetchArtworks, fetchExpenses]);

  const handleTaskClick = (task: Task) => {
    navigate('/app/tasks', { state: { selectedTaskId: task.id } });
  };

  const handleStatusChange = async (taskId: string, status: Task['status']) => {
    try {
      setLoading(true);
      await updateTask(taskId, { status });
      setTasks(prevTasks => 
        prevTasks.map(task =>
          task.id === taskId ? { ...task, status } : task
        )
      );
    } catch (err) {
      console.error('Error updating task status:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleCalendarToggle = async (taskId: string, showInCalendar: boolean) => {
    try {
      setLoading(true);
      await updateTask(taskId, { showInCalendar });
      setTasks(prevTasks => 
        prevTasks.map(task =>
          task.id === taskId ? { ...task, showInCalendar } : task
        )
      );
    } catch (err) {
      console.error('Error updating task calendar status:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddTask = () => {
    navigate('/app/tasks', { state: { showForm: true } });
  };

  // Filter tasks based on completion status
  const filteredTasks = tasks.filter(task => showCompleted || task.status !== 'completed');

  if (tasksLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="space-y-8">
      <h1 className="text-2xl font-bold text-gray-900">Dashboard</h1>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <button
          onClick={() => navigate('/app/inventory')}
          className="bg-white p-6 rounded-lg border border-gray-200 hover:border-indigo-500 hover:shadow-md transition-all text-left"
        >
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-sm font-medium text-gray-500">Total Artworks</h3>
            <div className="p-2 bg-blue-100 text-blue-600 rounded-lg">
              <BarChart className="h-5 w-5" />
            </div>
          </div>
          <p className="text-2xl font-bold">{stats.totalArtworks}</p>
        </button>

        <button
          onClick={() => navigate('/app/collections')}
          className="bg-white p-6 rounded-lg border border-gray-200 hover:border-indigo-500 hover:shadow-md transition-all text-left"
        >
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-sm font-medium text-gray-500">Total Value</h3>
            <div className="p-2 bg-green-100 text-green-600 rounded-lg">
              <TrendingUp className="h-5 w-5" />
            </div>
          </div>
          <p className="text-2xl font-bold">{profile.currency.symbol}{stats.totalValue.toLocaleString()}</p>
        </button>

        <button
          onClick={() => navigate('/app/expenses')}
          className="bg-white p-6 rounded-lg border border-gray-200 hover:border-indigo-500 hover:shadow-md transition-all text-left"
        >
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-sm font-medium text-gray-500">Monthly Expenses</h3>
            <div className="p-2 bg-red-100 text-red-600 rounded-lg">
              <DollarSign className="h-5 w-5" />
            </div>
          </div>
          <p className="text-2xl font-bold">{profile.currency.symbol}{stats.monthlyExpenses.toLocaleString()}</p>
        </button>
      </div>

      <div className="bg-white rounded-lg border border-gray-200 p-4">
        <TaskCalendar
          tasks={tasks}
          onTaskClick={handleTaskClick}
          onTaskAdd={handleAddTask}
          onTaskUpdate={updateTask}
          initialView="dayGridMonth"
        />
      </div>

      <TaskList
        tasks={filteredTasks}
        onTaskClick={handleTaskClick}
        onStatusChange={handleStatusChange}
        onTaskUpdate={handleCalendarToggle}
        showCompleted={showCompleted}
        onToggleShowCompleted={() => setShowCompleted(!showCompleted)}
        onAddTask={handleAddTask}
      />
    </div>
  );
}