import React, { useState, useEffect } from 'react';
import { Plus, Trash2, ExternalLink, Calendar, CheckCircle2, XCircle, Search } from 'lucide-react';
import { Opportunity, Task, Gallery } from '../types';
import { useNavigate } from 'react-router-dom';
import { useTasks } from '../hooks/useTasks';
import { useGalleries } from '../hooks/useGalleries';

type OpportunityFormProps = {
  onClose: () => void;
  onSubmit: (data: Partial<Opportunity>) => void;
  onDelete?: () => void;
  initialData?: Opportunity;
  loading?: boolean;
  linkedTasks?: Task[];
  onTaskClick?: (taskId: string) => void;
};

export default function OpportunityForm({
  onClose,
  onSubmit,
  onDelete,
  initialData,
  loading,
  linkedTasks = [],
  onTaskClick
}: OpportunityFormProps) {
  const navigate = useNavigate();
  const { addTask } = useTasks();
  const { fetchGalleries, addGallery } = useGalleries();
  const [formData, setFormData] = useState({
    name: initialData?.name || '',
    description: initialData?.description || '',
    galleryName: initialData?.galleryName || '',
    link: initialData?.link || '',
    deadline: initialData?.deadline ? new Date(initialData.deadline).toISOString().split('T')[0] : '',
    applied: initialData?.applied || false,
    accepted: initialData?.accepted,
    notes: initialData?.notes || ''
  });
  const [error, setError] = useState<string | null>(null);
  const [galleries, setGalleries] = useState<Gallery[]>([]);
  const [showGallerySearch, setShowGallerySearch] = useState(false);
  const [gallerySearch, setGallerySearch] = useState('');
  const [creatingGallery, setCreatingGallery] = useState(false);
  const [savingGallery, setSavingGallery] = useState(false);

  useEffect(() => {
    const loadGalleries = async () => {
      try {
        const data = await fetchGalleries();
        setGalleries(data);
      } catch (err) {
        console.error('Error loading galleries:', err);
      }
    };
    loadGalleries();
  }, [fetchGalleries]);

  const handleInputChange = (field: string, value: any) => {
    setFormData(prev => ({ ...prev, [field]: value }));
    setError(null);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!formData.name.trim()) {
      setError('Title is required');
      return;
    }
    if (!formData.deadline) {
      setError('Deadline is required');
      return;
    }

    onSubmit(formData);
  };

  const handleCreateTask = async () => {
    if (!initialData?.id) return;

    try {
      const taskId = await addTask({
        title: `Application: ${formData.name}`,
        description: `Prepare application for ${formData.galleryName || 'opportunity'}`,
        status: 'todo',
        dueDate: formData.deadline || undefined,
        isAllDay: true,
        showInCalendar: true,
        relatedOpportunityId: initialData.id,
        links: formData.link ? [formData.link] : []
      });

      if (taskId) {
        navigate('/app/tasks', { state: { selectedTaskId: taskId } });
      }
    } catch (err) {
      console.error('Error creating task:', err);
    }
  };

  const handleGallerySelect = (gallery: Gallery) => {
    handleInputChange('galleryName', gallery.name);
    setShowGallerySearch(false);
    setGallerySearch('');
  };

  const handleCreateGallery = async () => {
    if (!gallerySearch.trim()) return;

    try {
      setSavingGallery(true);
      const id = await addGallery({
        name: gallerySearch,
        website: '',
        description: ''
      });

      const updatedGalleries = await fetchGalleries();
      setGalleries(updatedGalleries);
      const newGallery = updatedGalleries.find(g => g.id === id);
      if (newGallery) {
        handleGallerySelect(newGallery);
      }
      setCreatingGallery(false);
    } catch (err) {
      console.error('Error creating gallery:', err);
    } finally {
      setSavingGallery(false);
    }
  };

  const filteredGalleries = galleries.filter(gallery =>
    gallery.name.toLowerCase().includes(gallerySearch.toLowerCase())
  );

  return (
    <form onSubmit={handleSubmit} className="p-6 space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Title *
        </label>
        <input
          type="text"
          value={formData.name}
          onChange={(e) => handleInputChange('name', e.target.value)}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Gallery/Organization Name
        </label>
        <div className="relative">
          {formData.galleryName ? (
            <div className="flex items-center justify-between p-2 bg-gray-50 rounded-lg">
              <span className="text-sm">{formData.galleryName}</span>
              <button
                type="button"
                onClick={() => handleInputChange('galleryName', '')}
                className="p-1 hover:bg-gray-200 rounded-full"
              >
                <Trash2 className="h-4 w-4" />
              </button>
            </div>
          ) : (
            <>
              <div className="flex gap-2">
                <div className="flex-1 relative">
                  <input
                    type="text"
                    value={gallerySearch}
                    onChange={(e) => setGallerySearch(e.target.value)}
                    onFocus={() => setShowGallerySearch(true)}
                    placeholder="Search or create gallery..."
                    className="w-full pl-9 pr-3 py-2 border rounded-lg"
                  />
                  <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
                </div>
              </div>
              {showGallerySearch && (
                <div className="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-48 overflow-y-auto">
                  {filteredGalleries.map((gallery) => (
                    <button
                      key={gallery.id}
                      type="button"
                      onClick={() => handleGallerySelect(gallery)}
                      className="w-full flex items-center gap-3 p-2 hover:bg-gray-50 text-left"
                    >
                      <div>
                        <div className="font-medium">{gallery.name}</div>
                        {gallery.website && (
                          <div className="text-sm text-gray-500">{gallery.website}</div>
                        )}
                      </div>
                    </button>
                  ))}
                  {gallerySearch && !filteredGalleries.length && !creatingGallery && (
                    <button
                      type="button"
                      onClick={() => setCreatingGallery(true)}
                      className="w-full flex items-center gap-2 p-2 hover:bg-gray-50 text-left text-indigo-600"
                    >
                      <Plus className="h-4 w-4" />
                      Create "{gallerySearch}"
                    </button>
                  )}
                  {creatingGallery && (
                    <div className="p-2 space-y-2">
                      <p className="text-sm text-gray-500">
                        Create new gallery "{gallerySearch}"?
                      </p>
                      <div className="flex gap-2">
                        <button
                          type="button"
                          onClick={handleCreateGallery}
                          disabled={savingGallery}
                          className="flex-1 px-3 py-1 bg-indigo-600 text-white text-sm rounded-md hover:bg-indigo-700 disabled:opacity-50"
                        >
                          {savingGallery ? 'Creating...' : 'Create'}
                        </button>
                        <button
                          type="button"
                          onClick={() => setCreatingGallery(false)}
                          disabled={savingGallery}
                          className="flex-1 px-3 py-1 border text-sm rounded-md hover:bg-gray-50 disabled:opacity-50"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Description
        </label>
        <textarea
          value={formData.description}
          onChange={(e) => handleInputChange('description', e.target.value)}
          rows={3}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Link
        </label>
        <input
          type="url"
          value={formData.link}
          onChange={(e) => handleInputChange('link', e.target.value)}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
          placeholder="https://"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Application Deadline *
        </label>
        <input
          type="date"
          value={formData.deadline}
          onChange={(e) => handleInputChange('deadline', e.target.value)}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
          required
        />
      </div>

      <div className="space-y-4">
        <div className="flex items-center gap-4">
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={formData.applied}
              onChange={(e) => handleInputChange('applied', e.target.checked)}
              className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <span className="text-sm text-gray-700">Applied</span>
          </label>

          {formData.applied && (
            <div className="flex items-center gap-4">
              <label className="flex items-center gap-2">
                <input
                  type="radio"
                  checked={formData.accepted === true}
                  onChange={() => handleInputChange('accepted', true)}
                  className="text-indigo-600 focus:ring-indigo-500"
                />
                <span className="text-sm text-gray-700">Accepted</span>
              </label>
              <label className="flex items-center gap-2">
                <input
                  type="radio"
                  checked={formData.accepted === false}
                  onChange={() => handleInputChange('accepted', false)}
                  className="text-indigo-600 focus:ring-indigo-500"
                />
                <span className="text-sm text-gray-700">Rejected</span>
              </label>
              <button
                type="button"
                onClick={() => handleInputChange('accepted', undefined)}
                className="text-sm text-gray-500 hover:text-gray-700"
              >
                Clear
              </button>
            </div>
          )}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Notes
        </label>
        <textarea
          value={formData.notes}
          onChange={(e) => handleInputChange('notes', e.target.value)}
          rows={4}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
          placeholder="Add any additional notes or requirements..."
        />
      </div>

      {error && (
        <div className="text-sm text-red-600">
          {error}
        </div>
      )}

      {/* Linked Tasks Section */}
      {initialData && (
        <div className="pt-6 border-t">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-semibold text-gray-900">Tasks</h3>
            <button
              type="button"
              onClick={handleCreateTask}
              className="inline-flex items-center gap-2 px-4 py-2 text-sm text-indigo-600 hover:text-indigo-700"
            >
              <Plus className="h-4 w-4" />
              Create Task
            </button>
          </div>

          {linkedTasks.length > 0 ? (
            <div className="space-y-2">
              {linkedTasks.map((task) => (
                <div
                  key={task.id}
                  onClick={() => onTaskClick?.(task.id)}
                  className="flex items-center justify-between p-3 bg-gray-50 rounded-lg cursor-pointer hover:bg-gray-100"
                >
                  <div className="flex items-center gap-3">
                    {task.status === 'completed' ? (
                      <CheckCircle2 className="h-5 w-5 text-green-500" />
                    ) : (
                      <Calendar className="h-5 w-5 text-gray-400" />
                    )}
                    <div>
                      <div className={`font-medium ${
                        task.status === 'completed' ? 'text-gray-400 line-through' : 'text-gray-900'
                      }`}>
                        {task.title}
                      </div>
                      {task.dueDate && (
                        <div className="text-sm text-gray-500">
                          Due {new Date(task.dueDate).toLocaleDateString()}
                        </div>
                      )}
                    </div>
                  </div>
                  {task.showInCalendar && (
                    <Calendar className="h-4 w-4 text-indigo-600" />
                  )}
                </div>
              ))}
            </div>
          ) : (
            <p className="text-center text-gray-500 py-4">
              No tasks created yet. Create a task to track your application progress.
            </p>
          )}
        </div>
      )}

      <div className="flex items-center justify-between pt-6 border-t">
        {initialData && onDelete && (
          <button
            type="button"
            onClick={onDelete}
            className="flex items-center gap-2 px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg"
          >
            <Trash2 className="h-4 w-4" />
            Delete Opportunity
          </button>
        )}
        <div className="flex gap-3 ml-auto">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 border rounded-lg hover:bg-gray-50"
            disabled={loading}
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
          >
            {loading ? 'Saving...' : initialData ? 'Save Changes' : 'Add Opportunity'}
          </button>
        </div>
      </div>
    </form>
  );
}