import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircle2, ArrowRight, Sparkles } from 'lucide-react';
import { useOnboarding } from '../contexts/OnboardingContext';
import LoadingSpinner from './LoadingSpinner';

export default function StudioAssistant() {
  const navigate = useNavigate();
  const { steps, completeOnboarding, loading } = useOnboarding();

  if (loading) {
    return <LoadingSpinner />;
  }

  const completedSteps = steps.filter(step => step.completed).length;
  const progress = (completedSteps / steps.length) * 100;
  const allStepsCompleted = steps.every(step => step.completed);

  const handleComplete = async () => {
    await completeOnboarding();
    navigate('/app');
  };

  if (allStepsCompleted) {
    return (
      <div className="min-h-[80vh] flex items-center justify-center">
        <div className="max-w-xl text-center">
          <div className="w-24 h-24 bg-indigo-100 rounded-full flex items-center justify-center mx-auto mb-6">
            <Sparkles className="h-12 w-12 text-indigo-600" />
          </div>
          <h1 className="text-3xl font-bold text-gray-900 mb-4">
            Congratulations! You're All Set!
          </h1>
          <p className="text-lg text-gray-600 mb-8">
            Your studio is ready for your creative journey. May your art inspire and touch hearts around the world.
          </p>
          <button
            onClick={handleComplete}
            className="inline-flex items-center gap-2 px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 text-lg font-medium"
          >
            Let's make some beautiful Art!
            <ArrowRight className="h-5 w-5" />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto py-12">
      <div className="text-center mb-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-4">
          Welcome to Your Studio Assistant
        </h1>
        <p className="text-lg text-gray-600">
          Let's set up your studio together. Complete these steps to get started with managing your art business.
        </p>
      </div>

      {/* Progress Bar */}
      <div className="mb-12">
        <div className="flex justify-between items-center mb-2">
          <span className="text-sm font-medium text-gray-700">
            {completedSteps} of {steps.length} steps completed
          </span>
          <span className="text-sm font-medium text-indigo-600">
            {Math.round(progress)}%
          </span>
        </div>
        <div className="h-2 bg-gray-200 rounded-full">
          <div
            className="h-full bg-indigo-600 rounded-full transition-all duration-500"
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>

      {/* Steps */}
      <div className="space-y-4">
        {steps.map((step) => (
          <div
            key={step.id}
            className={`p-6 bg-white rounded-lg border ${
              step.completed
                ? 'border-green-200 bg-green-50'
                : 'border-gray-200 hover:border-indigo-200 hover:bg-indigo-50'
            } transition-colors cursor-pointer`}
            onClick={() => navigate(step.path)}
          >
            <div className="flex items-start gap-4">
              <div className={`p-2 rounded-full ${
                step.completed
                  ? 'bg-green-100 text-green-600'
                  : 'bg-gray-100 text-gray-400'
              }`}>
                <CheckCircle2 className="h-6 w-6" />
              </div>
              <div className="flex-1">
                <h3 className="text-lg font-semibold text-gray-900 mb-1">
                  {step.title}
                </h3>
                <p className="text-gray-600">{step.description}</p>
              </div>
              <ArrowRight className={`h-5 w-5 ${
                step.completed ? 'text-green-600' : 'text-gray-400'
              }`} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}