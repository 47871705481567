import React, { useState } from 'react';
import { Task } from '../types';
import { Upload, X, Plus, Link as LinkIcon } from 'lucide-react';
import { useStorage } from '../lib/storage';
import { format } from 'date-fns';

type TaskFormProps = {
  onClose: () => void;
  onSubmit: (data: Partial<Task>) => void;
  onDelete?: () => void;
  initialData?: Partial<Task>;
  loading?: boolean;
};

export default function TaskForm({ onClose, onSubmit, onDelete, initialData, loading }: TaskFormProps) {
  const { upload, remove } = useStorage();
  const [uploadingFile, setUploadingFile] = useState(false);
  const [files, setFiles] = useState<{ url: string; name: string; }[]>(initialData?.files || []);
  const [links, setLinks] = useState<string[]>(initialData?.links || []);
  const [newLink, setNewLink] = useState('');
  const [showInCalendar, setShowInCalendar] = useState(initialData?.showInCalendar ?? false);
  const [isAllDay, setIsAllDay] = useState(initialData?.isAllDay ?? true);
  const [startTime, setStartTime] = useState(initialData?.startDate ? format(new Date(initialData.startDate), 'HH:mm') : '09:00');
  const [endTime, setEndTime] = useState(initialData?.endDate ? format(new Date(initialData.endDate), 'HH:mm') : '10:00');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    
    const data: Partial<Task> = {
      title: formData.get('title') as string,
      description: formData.get('description') as string,
      status: formData.get('status') as Task['status'],
      files,
      links,
      showInCalendar,
      isAllDay
    };

    // Handle date and time
    const date = formData.get('date') as string;
    if (date) {
      if (isAllDay) {
        data.dueDate = date;
        data.startDate = date;
        data.endDate = date;
      } else {
        const startDateTime = `${date}T${startTime}`;
        const endDateTime = `${date}T${endTime}`;
        data.dueDate = startDateTime;
        data.startDate = startDateTime;
        data.endDate = endDateTime;
      }
    }

    onSubmit(data);
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    try {
      setUploadingFile(true);
      const url = await upload(file, 'files');
      setFiles([...files, { url, name: file.name }]);
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Failed to upload file. Please try again.');
    } finally {
      setUploadingFile(false);
      if (e.target) e.target.value = '';
    }
  };

  const handleRemoveFile = async (url: string) => {
    try {
      await remove(url);
      setFiles(files.filter(f => f.url !== url));
    } catch (error) {
      console.error('Error removing file:', error);
      alert('Failed to remove file. Please try again.');
    }
  };

  const handleAddLink = () => {
    if (!newLink.trim()) return;
    if (!links.includes(newLink.trim())) {
      setLinks([...links, newLink.trim()]);
    }
    setNewLink('');
  };

  const handleRemoveLink = (link: string) => {
    setLinks(links.filter(l => l !== link));
  };

  const formatDateForInput = (dateString?: string) => {
    if (!dateString) return '';
    return dateString.split('T')[0];
  };

  return (
    <form onSubmit={handleSubmit} className="p-6 space-y-6">
      {/* Status Toggle */}
      <div className="flex items-center justify-between">
        <label className="text-sm font-medium text-gray-700">Status</label>
        <button
          type="button"
          onClick={() => {
            const newStatus = initialData?.status === 'completed' ? 'todo' : 'completed';
            onSubmit({ ...initialData, status: newStatus });
          }}
          className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
            initialData?.status === 'completed' ? 'bg-green-600' : 'bg-gray-200'
          }`}
        >
          <span
            className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
              initialData?.status === 'completed' ? 'translate-x-6' : 'translate-x-1'
            }`}
          />
        </button>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Title
        </label>
        <input
          type="text"
          name="title"
          defaultValue={initialData?.title}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Description
        </label>
        <textarea
          name="description"
          defaultValue={initialData?.description}
          rows={3}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
        />
      </div>

      {/* Calendar Options */}
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <label className="text-sm font-medium text-gray-700">Add to Calendar</label>
          <button
            type="button"
            onClick={() => setShowInCalendar(!showInCalendar)}
            className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
              showInCalendar ? 'bg-indigo-600' : 'bg-gray-200'
            }`}
          >
            <span
              className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                showInCalendar ? 'translate-x-6' : 'translate-x-1'
              }`}
            />
          </button>
        </div>

        {showInCalendar && (
          <>
            <div className="flex items-center justify-between">
              <label className="text-sm font-medium text-gray-700">All Day</label>
              <button
                type="button"
                onClick={() => setIsAllDay(!isAllDay)}
                className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
                  isAllDay ? 'bg-indigo-600' : 'bg-gray-200'
                }`}
              >
                <span
                  className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                    isAllDay ? 'translate-x-6' : 'translate-x-1'
                  }`}
                />
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Date
                </label>
                <input
                  type="date"
                  name="date"
                  defaultValue={formatDateForInput(initialData?.dueDate)}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                />
              </div>

              {!isAllDay && (
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Start Time
                    </label>
                    <input
                      type="time"
                      value={startTime}
                      onChange={(e) => {
                        setStartTime(e.target.value);
                        // If end time is earlier than start time, update it
                        if (e.target.value > endTime) {
                          setEndTime(e.target.value);
                        }
                      }}
                      className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      End Time
                    </label>
                    <input
                      type="time"
                      value={endTime}
                      onChange={(e) => setEndTime(e.target.value)}
                      min={startTime}
                      className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      {/* Files */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Files
        </label>
        <div className="space-y-2">
          {files.map((file, index) => (
            <div key={index} className="flex items-center justify-between p-2 bg-gray-50 rounded-lg">
              <a
                href={file.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm text-indigo-600 hover:text-indigo-700"
              >
                {file.name}
              </a>
              <button
                type="button"
                onClick={() => handleRemoveFile(file.url)}
                className="p-1 hover:bg-gray-200 rounded-full"
              >
                <X className="h-4 w-4 text-gray-500" />
              </button>
            </div>
          ))}
          <div>
            <input
              type="file"
              onChange={handleFileUpload}
              className="hidden"
              id="file-upload"
              disabled={uploadingFile}
            />
            <button
              type="button"
              onClick={() => document.getElementById('file-upload')?.click()}
              disabled={uploadingFile}
              className="flex items-center gap-2 px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50 disabled:opacity-50"
            >
              {uploadingFile ? (
                <>
                  <Upload className="h-4 w-4 animate-spin" />
                  Uploading...
                </>
              ) : (
                <>
                  <Plus className="h-4 w-4" />
                  Add File
                </>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Links */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Reference Links
        </label>
        <div className="space-y-2">
          {links.map((link, index) => (
            <div key={index} className="flex items-center justify-between p-2 bg-gray-50 rounded-lg">
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm text-indigo-600 hover:text-indigo-700"
              >
                {link}
              </a>
              <button
                type="button"
                onClick={() => handleRemoveLink(link)}
                className="p-1 hover:bg-gray-200 rounded-full"
              >
                <X className="h-4 w-4 text-gray-500" />
              </button>
            </div>
          ))}
          <div className="flex gap-2">
            <div className="flex-1 relative">
              <LinkIcon className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
              <input
                type="url"
                value={newLink}
                onChange={(e) => setNewLink(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && (e.preventDefault(), handleAddLink())}
                className="w-full pl-9 pr-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                placeholder="Add reference link..."
              />
            </div>
            <button
              type="button"
              onClick={handleAddLink}
              className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
            >
              Add
            </button>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between pt-6 border-t">
        {initialData && onDelete && (
          <button
            type="button"
            onClick={onDelete}
            className="px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg"
          >
            Delete Task
          </button>
        )}
        <div className="flex gap-3 ml-auto">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 border rounded-lg hover:bg-gray-50"
            disabled={loading}
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
          >
            {loading ? 'Saving...' : initialData ? 'Save Changes' : 'Create Task'}
          </button>
        </div>
      </div>
    </form>
  );
}