import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Sparkles, MessageSquare, Newspaper, Megaphone, Palette, Brain, Wand2 } from 'lucide-react';
import MainHeader from '../../components/MainHeader';
import Footer from '../../components/Footer';

export default function AIPromotions() {
  return (
    <>
      <Helmet>
        <title>AI-Powered Art Marketing & Promotion Tools | Studio Planner</title>
        <meta 
          name="description" 
          content="Professional AI-powered marketing tools for artists. Generate engaging social media content, blog posts, and press releases to promote your artwork effectively."
        />
        <meta 
          name="keywords" 
          content="ai art marketing, artist promotion tools, social media content generator, art press releases, artist blog writing"
        />
      </Helmet>

      <MainHeader />

      <main>
        {/* Hero Section */}
        <div className="bg-gradient-to-b from-violet-50 to-white">
          <div className="container mx-auto px-6 py-24">
            <div className="max-w-3xl mx-auto text-center">
              <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
                AI-Powered Art Marketing Made Easy
              </h1>
              <p className="text-xl text-gray-600 mb-8">
                Generate professional marketing content for your artwork with our AI studio. Create engaging social media posts, blog articles, and press releases in minutes.
              </p>
              <div className="flex justify-center gap-4">
                <Link
                  to="/register"
                  className="px-8 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Featured Screenshots */}
        <div className="bg-white py-24">
          <div className="container mx-auto px-6">
            <div className="max-w-6xl mx-auto space-y-32">
              {/* First Screenshot Section */}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
                <div className="relative">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/studioplanner-5d3ea.firebasestorage.app/o/Screenshots%2FAI%20Promotion.png?alt=media&token=d0e70de4-aa81-4a92-9843-79289d6a4385"
                    alt="Studio Planner AI Promotions Interface"
                    className="w-full rounded-lg shadow-xl"
                  />
                  <div className="absolute -bottom-6 -right-6 bg-violet-600 text-white px-6 py-3 rounded-lg shadow-lg">
                    <p className="text-sm font-medium">AI Content Studio</p>
                  </div>
                </div>
                <div className="flex flex-col justify-center lg:pl-8">
                  <h2 className="text-3xl font-bold text-gray-900 mb-6">
                    AI-Powered Content Creation
                  </h2>
                  <p className="text-lg text-gray-600 mb-8">
                    Generate professional marketing content with just a few clicks. Our AI understands art and helps you create compelling stories about your work.
                  </p>
                  <div className="space-y-6">
                    <div className="flex items-start gap-4">
                      <div className="p-3 bg-violet-100 rounded-lg">
                        <Brain className="h-6 w-6 text-violet-600" />
                      </div>
                      <div>
                        <h3 className="font-semibold text-gray-900 text-lg mb-1">Smart Content Generation</h3>
                        <p className="text-gray-600">AI that understands art and creates engaging content</p>
                      </div>
                    </div>
                    <div className="flex items-start gap-4">
                      <div className="p-3 bg-violet-100 rounded-lg">
                        <Wand2 className="h-6 w-6 text-violet-600" />
                      </div>
                      <div>
                        <h3 className="font-semibold text-gray-900 text-lg mb-1">Customizable Tone</h3>
                        <p className="text-gray-600">Adjust the writing style to match your voice</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Second Screenshot Section */}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
                <div className="flex flex-col justify-center lg:pr-8 order-2 lg:order-1">
                  <h2 className="text-3xl font-bold text-gray-900 mb-6">
                    Multi-Platform Content Generation
                  </h2>
                  <p className="text-lg text-gray-600 mb-8">
                    Create content for different platforms with one click. From Instagram captions to press releases, our AI adapts to your needs.
                  </p>
                  <div className="space-y-6">
                    <div className="flex items-start gap-4">
                      <div className="p-3 bg-pink-100 rounded-lg">
                        <MessageSquare className="h-6 w-6 text-pink-600" />
                      </div>
                      <div>
                        <h3 className="font-semibold text-gray-900 text-lg mb-1">Social Media Ready</h3>
                        <p className="text-gray-600">Generate engaging posts with relevant hashtags</p>
                      </div>
                    </div>
                    <div className="flex items-start gap-4">
                      <div className="p-3 bg-pink-100 rounded-lg">
                        <Newspaper className="h-6 w-6 text-pink-600" />
                      </div>
                      <div>
                        <h3 className="font-semibold text-gray-900 text-lg mb-1">Professional Writing</h3>
                        <p className="text-gray-600">Create blog posts and press releases instantly</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative order-1 lg:order-2">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/studioplanner-5d3ea.firebasestorage.app/o/Screenshots%2FAI%20promotion%20for%20artists.png?alt=media&token=b4990a88-0de8-43e7-9d3f-8e8a2ee37cc3"
                    alt="Studio Planner AI Content Generation Interface"
                    className="w-full rounded-lg shadow-xl"
                  />
                  <div className="absolute -bottom-6 -left-6 bg-pink-600 text-white px-6 py-3 rounded-lg shadow-lg">
                    <p className="text-sm font-medium">Multi-Platform Content</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Rest of the component remains the same */}
      </main>

      <Footer />
    </>
  );
}