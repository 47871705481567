import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Check, ChevronDown, ChevronUp } from 'lucide-react';
import MainHeader from '../components/MainHeader';
import Footer from '../components/Footer';

type FAQItem = {
  question: string;
  answer: string;
};

export default function Pricing() {
  const [billingPeriod, setBillingPeriod] = useState<'monthly' | 'yearly'>('monthly');
  const [expandedFaq, setExpandedFaq] = useState<number | null>(null);

  const plans = [
    {
      id: 'student',
      name: 'Student/Graduate',
      price: 'FREE',
      description: 'Perfect for art students and recent graduates',
      features: [
        '500MB Storage',
        'Up to 100 Artworks',
        'Up to 10 Collections',
        '10 AI Promotions/month'
      ],
      cta: 'Start Free',
      highlight: false
    },
    {
      id: 'artist',
      name: 'Artist',
      price: billingPeriod === 'monthly' ? 9 : 90,
      period: billingPeriod,
      description: 'For professional artists and creators',
      features: [
        '2GB Storage',
        'Up to 200 Artworks',
        'Up to 15 Collections',
        '30 AI Promotions/month'
      ],
      cta: 'Get Started',
      highlight: true
    },
    {
      id: 'professional',
      name: 'Professional Artist',
      price: billingPeriod === 'monthly' ? 25 : 250,
      period: billingPeriod,
      description: 'For established artists and studios',
      features: [
        '5GB Storage',
        'Up to 500 Artworks',
        'Up to 20 Collections',
        '50 AI Promotions/month'
      ],
      cta: 'Get Started',
      highlight: false
    }
  ];

  const faqs: FAQItem[] = [
    {
      question: 'Do I have to be a Student to use the Student/Graduate Plan?',
      answer: 'No, this plan is designed with students in mind, but if you have a small inventory you are welcome to use it.'
    },
    {
      question: 'Can I upgrade or downgrade my plan?',
      answer: 'Yes, you can change your plan at any time. When upgrading, you will be charged the prorated difference. When downgrading, your new rate will apply at the start of your next billing cycle.'
    },
    {
      question: 'What happens when I reach my storage limit?',
      answer: 'You will receive notifications as you approach your storage limit. You can either upgrade to a higher plan with more storage or manage your existing storage by removing unused files.'
    },
    {
      question: 'What payment methods do you accept?',
      answer: 'We accept all major credit cards, including Visa, Mastercard, and American Express. For annual plans, we also accept bank transfers.'
    },
    {
      question: 'Can I cancel my subscription?',
      answer: 'Yes, you can cancel your subscription at any time. You will continue to have access to your plan until the end of your current billing period.'
    },
    {
      question: 'Is my data secure?',
      answer: 'Yes, we take security seriously. All your data is encrypted both in transit and at rest. We use industry-standard security practices and regularly perform security audits.'
    }
  ];

  return (
    <>
      <Helmet>
        <title>Pricing - Studio Planner | Art Business Management Software</title>
        <meta 
          name="description" 
          content="Choose the perfect plan for your art business. From students to professional artists, we have a package that fits your needs."
        />
      </Helmet>

      <MainHeader />

      <main className="bg-gray-50">
        {/* Hero Section */}
        <div className="pt-24 pb-12">
          <div className="container mx-auto px-6">
            <div className="max-w-2xl mx-auto text-center">
              <h1 className="text-4xl font-bold text-gray-900 mb-4">
                Simple, Transparent Pricing
              </h1>
              <p className="text-xl text-gray-600">
                Choose the perfect plan for your art business
              </p>
            </div>

            {/* Billing Toggle */}
            <div className="flex items-center justify-center mt-8">
              <button
                onClick={() => setBillingPeriod('monthly')}
                className={`px-4 py-2 text-sm font-medium rounded-l-lg ${
                  billingPeriod === 'monthly'
                    ? 'bg-indigo-600 text-white'
                    : 'bg-white text-gray-700 hover:bg-gray-50'
                }`}
              >
                Monthly
              </button>
              <button
                onClick={() => setBillingPeriod('yearly')}
                className={`px-4 py-2 text-sm font-medium rounded-r-lg ${
                  billingPeriod === 'yearly'
                    ? 'bg-indigo-600 text-white'
                    : 'bg-white text-gray-700 hover:bg-gray-50'
                }`}
              >
                Yearly
                <span className="ml-1 text-xs">
                  (Save 17%)
                </span>
              </button>
            </div>
          </div>
        </div>

        {/* Pricing Cards */}
        <div className="container mx-auto px-6 pb-24">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {plans.map((plan) => (
              <div
                key={plan.id}
                className={`relative rounded-2xl ${
                  plan.highlight
                    ? 'border-2 border-indigo-600 shadow-xl'
                    : 'border border-gray-200'
                } bg-white`}
              >
                {plan.highlight && (
                  <div className="absolute -top-5 left-0 right-0 mx-auto w-32">
                    <div className="text-center px-3 py-1 bg-indigo-600 text-white text-sm font-medium rounded-full">
                      Most Popular
                    </div>
                  </div>
                )}

                <div className="p-8">
                  <h3 className="text-xl font-bold text-gray-900 mb-2">{plan.name}</h3>
                  <p className="text-gray-500 mb-6">{plan.description}</p>

                  <div className="mb-6">
                    {typeof plan.price === 'number' ? (
                      <div className="flex items-baseline">
                        <span className="text-4xl font-bold">£{plan.price}</span>
                        <span className="text-gray-500 ml-2">
                          /{plan.period === 'monthly' ? 'month' : 'year'}
                        </span>
                      </div>
                    ) : (
                      <div className="text-4xl font-bold text-green-600">FREE</div>
                    )}
                  </div>

                  <ul className="space-y-4 mb-8">
                    {plan.features.map((feature, index) => (
                      <li key={index} className="flex items-start gap-3">
                        <Check className="h-5 w-5 text-green-500 flex-shrink-0 mt-0.5" />
                        <span className="text-gray-600">{feature}</span>
                      </li>
                    ))}
                  </ul>

                  <Link
                    to="/register"
                    className={`block w-full text-center py-3 px-4 rounded-lg ${
                      plan.highlight
                        ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                        : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                    }`}
                  >
                    {plan.cta}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="container mx-auto px-6 py-24">
          <div className="max-w-3xl mx-auto">
            <h2 className="text-3xl font-bold text-gray-900 text-center mb-12">
              Frequently Asked Questions
            </h2>

            <div className="space-y-4">
              {faqs.map((faq, index) => (
                <div
                  key={index}
                  className="bg-white border border-gray-200 rounded-lg overflow-hidden"
                >
                  <button
                    onClick={() => setExpandedFaq(expandedFaq === index ? null : index)}
                    className="w-full px-6 py-4 text-left flex items-center justify-between hover:bg-gray-50"
                  >
                    <h3 className="text-lg font-semibold text-gray-900">
                      {faq.question}
                    </h3>
                    {expandedFaq === index ? (
                      <ChevronUp className="h-5 w-5 text-gray-500" />
                    ) : (
                      <ChevronDown className="h-5 w-5 text-gray-500" />
                    )}
                  </button>
                  {expandedFaq === index && (
                    <div className="px-6 pb-4">
                      <p className="text-gray-600">{faq.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
}