import React from 'react';
import { X, Calendar } from 'lucide-react';

export type GalleryFilters = {
  tags: string[];
  linkedTypes: ('artwork' | 'collection')[];
  dateRange: {
    start: string | null;
    end: string | null;
  };
  linkedArtworkId?: string;
  linkedCollectionId?: string;
};

type GalleryFilterMenuProps = {
  isOpen: boolean;
  onClose: () => void;
  filters: GalleryFilters;
  onChange: (filters: GalleryFilters) => void;
  tags: string[];
};

export default function GalleryFilterMenu({ 
  isOpen, 
  onClose, 
  filters, 
  onChange,
  tags = []
}: GalleryFilterMenuProps) {
  const handleCheckboxChange = (
    category: 'tags' | 'linkedTypes',
    value: string
  ) => {
    const currentValues = filters[category];
    const newValues = currentValues.includes(value)
      ? currentValues.filter(v => v !== value)
      : [...currentValues, value];
    
    onChange({
      ...filters,
      [category]: newValues
    });
  };

  const handleDateChange = (field: 'start' | 'end', value: string) => {
    onChange({
      ...filters,
      dateRange: {
        ...filters.dateRange,
        [field]: value || null
      }
    });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-y-0 right-0 w-80 bg-white shadow-xl transform transition-transform duration-300 ease-in-out z-40 overflow-y-auto">
      <div className="p-6 space-y-6">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-semibold">Filters</h3>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="space-y-6">
          <div>
            <h4 className="font-medium mb-2">Tags</h4>
            {tags.map(tag => (
              <label key={tag} className="flex items-center gap-2 mb-2">
                <input
                  type="checkbox"
                  checked={filters.tags.includes(tag)}
                  onChange={() => handleCheckboxChange('tags', tag)}
                  className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <span className="text-sm text-gray-700">{tag}</span>
              </label>
            ))}
          </div>

          <div>
            <h4 className="font-medium mb-2">Linked To</h4>
            <label className="flex items-center gap-2 mb-2">
              <input
                type="checkbox"
                checked={filters.linkedTypes.includes('artwork')}
                onChange={() => handleCheckboxChange('linkedTypes', 'artwork')}
                className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <span className="text-sm text-gray-700">Artwork</span>
            </label>
            <label className="flex items-center gap-2 mb-2">
              <input
                type="checkbox"
                checked={filters.linkedTypes.includes('collection')}
                onChange={() => handleCheckboxChange('linkedTypes', 'collection')}
                className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <span className="text-sm text-gray-700">Collection</span>
            </label>
          </div>

          <div>
            <h4 className="font-medium mb-2">Date Range</h4>
            <div className="space-y-2">
              <div>
                <label className="text-sm text-gray-600">From</label>
                <div className="relative">
                  <input
                    type="date"
                    value={filters.dateRange.start || ''}
                    onChange={(e) => handleDateChange('start', e.target.value)}
                    className="w-full px-2 py-1 border rounded-lg pl-8"
                  />
                  <Calendar className="absolute left-2 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
                </div>
              </div>
              <div>
                <label className="text-sm text-gray-600">To</label>
                <div className="relative">
                  <input
                    type="date"
                    value={filters.dateRange.end || ''}
                    onChange={(e) => handleDateChange('end', e.target.value)}
                    className="w-full px-2 py-1 border rounded-lg pl-8"
                  />
                  <Calendar className="absolute left-2 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
                </div>
              </div>
            </div>
          </div>

          <div className="pt-4 border-t">
            <button
              onClick={() => onChange({
                tags: [],
                linkedTypes: [],
                dateRange: { start: null, end: null },
                linkedArtworkId: undefined,
                linkedCollectionId: undefined
              })}
              className="w-full px-4 py-2 text-sm text-gray-600 hover:text-gray-900"
            >
              Clear All Filters
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}