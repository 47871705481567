import { useState, useCallback } from 'react';
import { collection, addDoc, updateDoc, doc, deleteDoc, Timestamp, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Expense } from '../types';

function cleanDataForFirestore(data: any) {
  const cleaned = { ...data };

  // Convert date string to Timestamp
  if (cleaned.date && typeof cleaned.date === 'string') {
    cleaned.date = Timestamp.fromDate(new Date(cleaned.date));
  }

  // Ensure arrays are defined
  cleaned.linkedItems = cleaned.linkedItems || [];

  // Remove undefined values
  Object.keys(cleaned).forEach(key => {
    if (cleaned[key] === undefined) {
      delete cleaned[key];
    }
  });

  return cleaned;
}

export function useExpenses() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchExpenses = useCallback(async () => {
    if (!currentUser) return [];

    try {
      setLoading(true);
      setError(null);
      const q = query(
        collection(db, 'expenses'),
        where('artistId', '==', currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const expenses = querySnapshot.docs.map(doc => {
        const data = doc.data();
        // Convert Timestamp back to ISO string for the frontend
        return {
          id: doc.id,
          ...data,
          date: data.date?.toDate().toISOString().split('T')[0],
          createdAt: data.createdAt?.toDate().toISOString(),
          updatedAt: data.updatedAt?.toDate().toISOString(),
        };
      }) as Expense[];
      setLoading(false);
      return expenses;
    } catch (err) {
      setError(err as Error);
      setLoading(false);
      throw err;
    }
  }, [currentUser]);

  const addExpense = useCallback(async (data: Omit<Expense, 'id'>) => {
    if (!currentUser) throw new Error('User not authenticated');

    setLoading(true);
    setError(null);
    try {
      const cleanedData = cleanDataForFirestore({
        ...data,
        artistId: currentUser.uid,
        createdAt: Timestamp.now()
      });

      const docRef = await addDoc(collection(db, 'expenses'), cleanedData);
      setLoading(false);
      return docRef.id;
    } catch (err) {
      setError(err as Error);
      setLoading(false);
      throw err;
    }
  }, [currentUser]);

  const updateExpense = useCallback(async (id: string, data: Partial<Expense>) => {
    if (!currentUser) throw new Error('User not authenticated');

    setLoading(true);
    setError(null);
    try {
      const cleanedData = cleanDataForFirestore({
        ...data,
        updatedAt: Timestamp.now()
      });

      const docRef = doc(db, 'expenses', id);
      await updateDoc(docRef, cleanedData);
      setLoading(false);
    } catch (err) {
      setError(err as Error);
      setLoading(false);
      throw err;
    }
  }, [currentUser]);

  const deleteExpense = useCallback(async (id: string) => {
    if (!currentUser) throw new Error('User not authenticated');

    setLoading(true);
    setError(null);
    try {
      await deleteDoc(doc(db, 'expenses', id));
      setLoading(false);
    } catch (err) {
      setError(err as Error);
      setLoading(false);
      throw err;
    }
  }, [currentUser]);

  return {
    fetchExpenses,
    addExpense,
    updateExpense,
    deleteExpense,
    loading,
    error
  };
}