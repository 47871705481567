import React from 'react';
import { Helmet } from 'react-helmet-async';
import MainHeader from '../components/MainHeader';
import Footer from '../components/Footer';

export default function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Studio Planner</title>
        <meta 
          name="description" 
          content="Studio Planner's privacy policy explains how we collect, use, and protect your personal information."
        />
      </Helmet>

      <MainHeader />

      <main className="bg-white">
        <div className="container mx-auto px-6 py-12">
          <div className="max-w-3xl mx-auto">
            <div className="prose prose-lg max-w-none">
              <h1 className="text-4xl font-bold text-gray-900 mb-4">Privacy Policy</h1>
              <p className="text-lg text-gray-600 mb-8">Last updated: November 24, 2024</p>

              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">Introduction</h2>
              <p className="text-gray-700 leading-relaxed">
                IN ONES PRIME LTD ("we", "us", or "our"), operating Studio-Planner.com, is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Service.
              </p>

              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">Information We Collect</h2>
              
              <h3 className="text-xl font-semibold text-gray-900 mt-6 mb-3">Personal Information:</h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Name</li>
                <li>Email address</li>
                <li>Billing information</li>
                <li>Profile information</li>
              </ul>

              <h3 className="text-xl font-semibold text-gray-900 mt-6 mb-3">Usage Data:</h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>IP address</li>
                <li>Browser type</li>
                <li>Pages visited</li>
                <li>Time and date of visits</li>
              </ul>

              <h3 className="text-xl font-semibold text-gray-900 mt-6 mb-3">User Content:</h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Artwork images</li>
                <li>Collection information</li>
                <li>Task details</li>
                <li>Expense records</li>
              </ul>

              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">How We Use Your Information</h2>
              <p className="text-gray-700 mb-4">We use your information to:</p>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Provide and maintain our Service</li>
                <li>Improve and personalise user experience</li>
                <li>Process transactions</li>
                <li>Send administrative information</li>
                <li>Respond to inquiries and offer support</li>
                <li>Monitor usage of the Service</li>
              </ul>

              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">Data Sharing and Disclosure</h2>
              <p className="text-gray-700 mb-4">We may share your information with:</p>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Service providers who assist in operating our business</li>
                <li>Legal and regulatory authorities, when required by law</li>
                <li>Third-party advertisers for marketing purposes (with your consent)</li>
              </ul>

              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">Use of Google Analytics</h2>
              <p className="text-gray-700 leading-relaxed">
                We use Google Analytics to track and analyze usage of our Service. Google Analytics may collect information such as your IP address, time of visit, pages visited, and time spent on each page. You can opt-out of Google Analytics by installing the Google Analytics Opt-out Browser Add-on.
              </p>

              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">Use of OpenAI</h2>
              <p className="text-gray-700 leading-relaxed">
                Studio-Planner.com utilises OpenAI technology to assist artists in generating promotional content. This integration allows users to leverage advanced AI capabilities for marketing purposes. When using OpenAI-powered features, Studio-Planner.com adheres to best practices for AI integration.
              </p>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Users are responsible for reviewing and approving any AI-generated content before use</li>
                <li>The service uses the latest OpenAI models to ensure access to advanced features</li>
                <li>Studio-Planner.com does not own the rights to the artwork or photos uploaded by users</li>
              </ul>

              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">Your Rights</h2>
              <p className="text-gray-700 mb-4">You have the right to:</p>
              <ul className="list-disc pl-6 space-y-2 text-gray-700">
                <li>Access your personal data</li>
                <li>Correct inaccurate data</li>
                <li>Request deletion of your data</li>
                <li>Object to processing of your data</li>
                <li>Request restriction of processing</li>
              </ul>

              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">Data Security</h2>
              <p className="text-gray-700 leading-relaxed">
                We implement appropriate technical and organizational measures to protect your personal data against unauthorized or unlawful processing, accidental loss, destruction, or damage.
              </p>

              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">Changes to This Privacy Policy</h2>
              <p className="text-gray-700 leading-relaxed">
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.
              </p>

              <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">Contact Us</h2>
              <p className="text-gray-700">If you have any questions about this Privacy Policy, please contact us at:</p>
              <p className="text-gray-700 mt-4">
                IN ONES PRIME LTD<br />
                Fernleaf Beech,<br />
                East Street<br />
                Farnham<br />
                GU97TH
              </p>

              <p className="text-gray-700 mt-8 pb-8 border-t pt-8">
                By using Studio-Planner.com, you acknowledge that you have read and understood this Privacy Policy.
              </p>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
}