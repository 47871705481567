import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { storage } from './firebase';
import { useAuth } from '../contexts/AuthContext';

export type StorageFolder = 'profile' | 'gallery' | 'expenses';

export function getStoragePath(userId: string, folder: StorageFolder, filename: string) {
  return `${userId}/${folder}/${filename}`;
}

export async function uploadFile(file: File, userId: string, folder: StorageFolder): Promise<string> {
  // Create a unique filename using timestamp and original name
  const timestamp = Date.now();
  const filename = `${timestamp}-${file.name}`;
  const path = getStoragePath(userId, folder, filename);
  
  // Create a reference to the file location
  const storageRef = ref(storage, path);
  
  // Upload the file
  await uploadBytes(storageRef, file);
  
  // Get and return the download URL
  return getDownloadURL(storageRef);
}

export async function deleteFile(url: string): Promise<void> {
  // Get the storage reference from the URL
  const storageRef = ref(storage, url);
  
  // Delete the file
  await deleteObject(storageRef);
}

export function useStorage() {
  const { currentUser } = useAuth();

  const upload = async (file: File, folder: StorageFolder): Promise<string> => {
    if (!currentUser) throw new Error('User not authenticated');
    return uploadFile(file, currentUser.uid, folder);
  };

  const remove = async (url: string): Promise<void> => {
    if (!currentUser) throw new Error('User not authenticated');
    return deleteFile(url);
  };

  return {
    upload,
    remove
  };
}