import { loadStripe } from '@stripe/stripe-js';
import { auth } from './firebase';

export const stripePromise = loadStripe('pk_test_51QOcUuGrdPkCENDc98hxANjgjn6GnnRsJKIOzTKiVjra787idxhU5Wly3GOKZ7Gpvfk7alX3iXk0DjYxBBAgezZQ00iopt32Vp');

export const createCheckoutSession = async (priceId: string) => {
  const currentUser = auth.currentUser;
  if (!currentUser) {
    throw new Error('User must be logged in');
  }

  try {
    const token = await currentUser.getIdToken();
    const response = await fetch('/api/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        priceId,
        userId: currentUser.uid,
      }),
    });

    const contentType = response.headers.get('content-type');
    if (!response.ok) {
      if (contentType && contentType.includes('application/json')) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create checkout session');
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    if (!data.url) {
      throw new Error('Invalid response from server');
    }

    return data;
  } catch (error: any) {
    console.error('Error creating checkout session:', error);
    throw new Error(error.message || 'Failed to create checkout session');
  }
};

export const createPortalSession = async () => {
  const currentUser = auth.currentUser;
  if (!currentUser) {
    throw new Error('User must be logged in');
  }

  try {
    const token = await currentUser.getIdToken();
    const response = await fetch('/api/create-portal-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    const contentType = response.headers.get('content-type');
    if (!response.ok) {
      if (contentType && contentType.includes('application/json')) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create portal session');
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    if (!data.url) {
      throw new Error('Invalid response from server');
    }

    return data;
  } catch (error: any) {
    console.error('Error creating portal session:', error);
    throw new Error(error.message || 'Failed to create portal session');
  }
};