import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Layout from './components/Layout';
import BlogLayout from './components/BlogLayout';
import ErrorBoundary from './components/ErrorBoundary';
import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/AdminRoute';
import AdminLayout from './components/AdminLayout';

// Main Pages
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import Pricing from './pages/Pricing';
import PublicProfile from './pages/PublicProfile';

// Feature Pages
import ArtInventory from './pages/features/ArtInventory';
import CollectionsFeature from './pages/features/Collections';
import PhotoLibrary from './pages/features/PhotoLibrary';
import ExpenseManagement from './pages/features/ExpenseManagement';
import TasksFeature from './pages/features/Tasks';
import OpenCalls from './pages/features/OpenCalls';
import Galleries from './pages/features/Galleries';
import AIPromotions from './pages/features/AIPromotions';

// Blog Pages
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';

// Admin Pages
import AdminDashboard from './pages/admin/Dashboard';
import AdminBlog from './pages/admin/Blog';
import AdminUsers from './pages/admin/Users';
import AdminSubscriptions from './pages/admin/Subscriptions';

// App Pages
import Dashboard from './pages/Dashboard';
import Inventory from './pages/Inventory';
import Collections from './pages/Collections';
import Gallery from './pages/Gallery';
import Expenses from './pages/Expenses';
import Tasks from './pages/Tasks';
import Opportunities from './pages/Opportunities';
import GalleriesApp from './pages/Galleries';
import Promotions from './pages/Promotions';
import Membership from './pages/Membership';
import Profile from './pages/Profile';

// Context Providers
import { AuthProvider } from './contexts/AuthContext';
import { ProfileProvider } from './contexts/ProfileContext';
import { OnboardingProvider } from './contexts/OnboardingContext';

export default function App() {
  return (
    <ErrorBoundary>
      <HelmetProvider>
        <AuthProvider>
          <ProfileProvider>
            <Router>
              <Routes>
                {/* Public Routes */}
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/artist/:id" element={<PublicProfile />} />

                {/* Feature Pages */}
                <Route path="/features/art-inventory" element={<ArtInventory />} />
                <Route path="/features/collections" element={<CollectionsFeature />} />
                <Route path="/features/photo-library" element={<PhotoLibrary />} />
                <Route path="/features/expense-management" element={<ExpenseManagement />} />
                <Route path="/features/tasks" element={<TasksFeature />} />
                <Route path="/features/open-calls" element={<OpenCalls />} />
                <Route path="/features/galleries" element={<Galleries />} />
                <Route path="/features/ai-promotions" element={<AIPromotions />} />

                {/* Blog Routes */}
                <Route path="/blog" element={<BlogLayout />}>
                  <Route index element={<Blog />} />
                  <Route path=":slug" element={<BlogPost />} />
                </Route>

                {/* Admin Routes */}
                <Route path="/admin" element={<AdminRoute><AdminLayout /></AdminRoute>}>
                  <Route index element={<AdminDashboard />} />
                  <Route path="blog" element={<AdminBlog />} />
                  <Route path="users" element={<AdminUsers />} />
                  <Route path="subscriptions" element={<AdminSubscriptions />} />
                </Route>

                {/* Protected App Routes */}
                <Route path="/app" element={
                  <PrivateRoute>
                    <OnboardingProvider>
                      <Layout />
                    </OnboardingProvider>
                  </PrivateRoute>
                }>
                  <Route index element={<Dashboard />} />
                  <Route path="inventory" element={<Inventory />}>
                    <Route path=":artworkId" element={<Inventory />} />
                  </Route>
                  <Route path="collections" element={<Collections />} />
                  <Route path="gallery" element={<Gallery />} />
                  <Route path="expenses" element={<Expenses />} />
                  <Route path="tasks" element={<Tasks />} />
                  <Route path="opportunities" element={<Opportunities />} />
                  <Route path="galleries" element={<GalleriesApp />} />
                  <Route path="promotions" element={<Promotions />} />
                  <Route path="membership" element={<Membership />} />
                  <Route path="profile" element={<Profile />} />
                </Route>

                {/* Catch all */}
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </Router>
          </ProfileProvider>
        </AuthProvider>
      </HelmetProvider>
    </ErrorBoundary>
  );
}