import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useBlog } from '../hooks/useBlog';
import { BlogPost } from '../types';
import LoadingSpinner from '../components/LoadingSpinner';
import { format } from 'date-fns';

export default function Blog() {
  const { fetchPosts, loading } = useBlog();
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const loadPosts = async () => {
      try {
        // Get tag from URL if present
        const tagFromUrl = searchParams.get('tag');
        if (tagFromUrl) {
          setSelectedTag(tagFromUrl);
        }

        const data = await fetchPosts({
          tag: tagFromUrl || undefined
        });
        setPosts(data);
      } catch (err) {
        console.error('Error loading blog posts:', err);
      }
    };
    loadPosts();
  }, [fetchPosts, searchParams]);

  if (loading) {
    return <LoadingSpinner />;
  }

  // Get unique tags from all posts
  const tags = Array.from(new Set(posts.flatMap(post => post.tags)));

  return (
    <>
      <Helmet>
        <title>Blog - Studio Planner</title>
        <meta
          name="description"
          content="Expert insights, tips, and stories about managing your art business"
        />
        <meta
          name="keywords"
          content="art business, studio management, artist tips, art marketing"
        />
        <link rel="canonical" href="https://studio-planner.com/blog" />
      </Helmet>

      <div className="max-w-7xl mx-auto px-6 py-12">
        <div className="max-w-2xl mx-auto text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Studio Planner Blog
          </h1>
          <p className="text-xl text-gray-600">
            Expert insights, tips, and stories about managing your art business
          </p>
        </div>

        {/* Tags */}
        <div className="flex flex-wrap gap-2 justify-center mb-12">
          <button
            onClick={() => setSelectedTag(null)}
            className={`px-4 py-2 rounded-full ${
              !selectedTag
                ? 'bg-indigo-600 text-white'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
          >
            All
          </button>
          {tags.map((tag) => (
            <button
              key={tag}
              onClick={() => setSelectedTag(tag)}
              className={`px-4 py-2 rounded-full ${
                selectedTag === tag
                  ? 'bg-indigo-600 text-white'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              {tag}
            </button>
          ))}
        </div>

        {/* Posts Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {posts
            .filter(post => !selectedTag || post.tags.includes(selectedTag))
            .map((post) => (
              <article
                key={post.id}
                className="bg-white rounded-lg border border-gray-200 overflow-hidden hover:shadow-lg transition-shadow"
              >
                <Link to={`/blog/${post.slug}`} state={{ post }}>
                  <img
                    src={post.coverImage}
                    alt={post.title}
                    className="w-full h-48 object-cover"
                  />
                  <div className="p-6">
                    <div className="flex items-center gap-2 text-sm text-gray-500 mb-3">
                      {post.author.avatar && (
                        <img
                          src={post.author.avatar}
                          alt={post.author.name}
                          className="w-6 h-6 rounded-full"
                        />
                      )}
                      <span>{post.author.name}</span>
                      <span>•</span>
                      <time dateTime={post.publishedAt}>
                        {format(new Date(post.publishedAt), 'MMM d, yyyy')}
                      </time>
                    </div>
                    <h2 className="text-xl font-semibold text-gray-900 mb-2">
                      {post.title}
                    </h2>
                    <p className="text-gray-600 line-clamp-3">
                      {post.excerpt}
                    </p>
                    <div className="flex flex-wrap gap-2 mt-4">
                      {post.tags.map((tag) => (
                        <span
                          key={tag}
                          className="px-2 py-1 bg-gray-100 text-gray-600 text-sm rounded-full"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                </Link>
              </article>
            ))}
        </div>
      </div>
    </>
  );
}