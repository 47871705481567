import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Search, UserCircle, Palette, BoxesIcon, Image, HardDrive, Calendar, Building2, Receipt, Briefcase, Sparkles } from 'lucide-react';
import { collection, query, getDocs, where } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useFirestore } from '../../hooks/useFirestore';
import { Artist } from '../../types';
import LoadingSpinner from '../../components/LoadingSpinner';
import UserProfileModal from '../../components/admin/UserProfileModal';

// Helper function to format bytes
const formatBytes = (bytes: number) => {
  if (!bytes) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

type UserMetrics = {
  totalArtworks: number;
  totalCollections: number;
  totalPhotos: number;
  totalStorage: number;
  totalTasks: number;
  totalOpenCalls: number;
  totalGalleries: number;
  totalExpenses: number;
  totalPromotions: number;
  onboardingComplete: boolean;
};

const DEFAULT_METRICS: UserMetrics = {
  totalArtworks: 0,
  totalCollections: 0,
  totalPhotos: 0,
  totalStorage: 0,
  totalTasks: 0,
  totalOpenCalls: 0,
  totalGalleries: 0,
  totalExpenses: 0,
  totalPromotions: 0,
  onboardingComplete: false
};

export default function AdminUsers() {
  const { list, loading } = useFirestore('artists');
  const [users, setUsers] = useState<Artist[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState<Artist | null>(null);
  const [loadingMetrics, setLoadingMetrics] = useState(false);
  const [userMetrics, setUserMetrics] = useState<Record<string, UserMetrics>>({});

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const data = await list();
        setUsers(data as Artist[]);
        
        // Load metrics for all users
        data.forEach((user: Artist) => {
          loadUserMetrics(user.id);
        });
      } catch (err) {
        console.error('Error loading users:', err);
      }
    };
    loadUsers();
  }, [list]);

  const loadUserMetrics = async (userId: string) => {
    try {
      setLoadingMetrics(true);
      
      // Initialize metrics with default values
      let metrics = { ...DEFAULT_METRICS };

      try {
        // Load all user data in parallel with error handling for each query
        const [
          artworksSnapshot,
          collectionsSnapshot,
          gallerySnapshot,
          tasksSnapshot,
          openCallsSnapshot,
          galleriesSnapshot,
          expensesSnapshot,
          promotionsSnapshot,
          onboardingSnapshot
        ] = await Promise.all([
          getDocs(query(collection(db, 'artworks'), where('artistId', '==', userId))).catch(() => null),
          getDocs(query(collection(db, 'collections'), where('artistId', '==', userId))).catch(() => null),
          getDocs(query(collection(db, 'gallery'), where('artistId', '==', userId))).catch(() => null),
          getDocs(query(collection(db, 'tasks'), where('artistId', '==', userId))).catch(() => null),
          getDocs(query(collection(db, 'opportunities'), where('artistId', '==', userId))).catch(() => null),
          getDocs(query(collection(db, 'galleries'), where('artistId', '==', userId))).catch(() => null),
          getDocs(query(collection(db, 'expenses'), where('artistId', '==', userId))).catch(() => null),
          getDocs(query(collection(db, 'promotions'), where('artistId', '==', userId))).catch(() => null),
          getDocs(query(collection(db, 'onboarding'), where('userId', '==', userId))).catch(() => null)
        ]);

        // Calculate metrics only if the query was successful
        if (artworksSnapshot) metrics.totalArtworks = artworksSnapshot.size;
        if (collectionsSnapshot) metrics.totalCollections = collectionsSnapshot.size;
        if (gallerySnapshot) metrics.totalPhotos = gallerySnapshot.size;
        if (tasksSnapshot) metrics.totalTasks = tasksSnapshot.size;
        if (openCallsSnapshot) metrics.totalOpenCalls = openCallsSnapshot.size;
        if (galleriesSnapshot) metrics.totalGalleries = galleriesSnapshot.size;
        if (expensesSnapshot) metrics.totalExpenses = expensesSnapshot.size;
        if (promotionsSnapshot) metrics.totalPromotions = promotionsSnapshot.size;
        if (onboardingSnapshot) {
          metrics.onboardingComplete = !onboardingSnapshot.empty && 
            onboardingSnapshot.docs[0].data().completed;
        }

        // Calculate total storage (assuming each image is ~2MB on average)
        const averageImageSize = 2 * 1024 * 1024; // 2MB in bytes
        metrics.totalStorage = (
          (artworksSnapshot?.docs.reduce((sum, art) => sum + (art.data().images?.length || 0), 0) || 0) + 
          (gallerySnapshot?.size || 0)
        ) * averageImageSize;

      } catch (err) {
        console.warn('Error loading some metrics:', err);
        // Continue with default values for failed metrics
      }

      setUserMetrics(prev => ({
        ...prev,
        [userId]: metrics
      }));
    } catch (err) {
      console.error('Error loading user metrics:', err);
      // Set default metrics for this user
      setUserMetrics(prev => ({
        ...prev,
        [userId]: DEFAULT_METRICS
      }));
    } finally {
      setLoadingMetrics(false);
    }
  };

  const handleUserClick = (user: Artist) => {
    setSelectedUser(user);
  };

  const filteredUsers = users.filter(user =>
    user.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>User Management | Studio Planner Admin</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold text-gray-900">User Management</h1>
          <div className="relative w-64">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search users..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border rounded-lg"
            />
          </div>
        </div>

        <div className="bg-white rounded-lg border border-gray-200">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-50 border-b border-gray-200">
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Artworks</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Collections</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Photos</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Storage</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tasks</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Open Calls</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Galleries</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Expenses</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Promotions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {filteredUsers.map((user) => {
                  const metrics = userMetrics[user.id] || DEFAULT_METRICS;
                  return (
                    <tr 
                      key={user.id}
                      onClick={() => handleUserClick(user)}
                      className="hover:bg-gray-50 cursor-pointer"
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          {user.avatar ? (
                            <img
                              src={user.avatar}
                              alt={user.name}
                              className="h-10 w-10 rounded-full"
                            />
                          ) : (
                            <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center">
                              <UserCircle className="h-6 w-6 text-gray-400" />
                            </div>
                          )}
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">{user.name}</div>
                            <div className="text-sm text-gray-500">
                              Joined {new Date(user.createdAt).toLocaleDateString()}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{user.email}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center gap-1 text-sm">
                          <Palette className="h-4 w-4 text-gray-400" />
                          {metrics.totalArtworks}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center gap-1 text-sm">
                          <BoxesIcon className="h-4 w-4 text-gray-400" />
                          {metrics.totalCollections}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center gap-1 text-sm">
                          <Image className="h-4 w-4 text-gray-400" />
                          {metrics.totalPhotos}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center gap-1 text-sm">
                          <HardDrive className="h-4 w-4 text-gray-400" />
                          {formatBytes(metrics.totalStorage)}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center gap-1 text-sm">
                          <Calendar className="h-4 w-4 text-gray-400" />
                          {metrics.totalTasks}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center gap-1 text-sm">
                          <Briefcase className="h-4 w-4 text-gray-400" />
                          {metrics.totalOpenCalls}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center gap-1 text-sm">
                          <Building2 className="h-4 w-4 text-gray-400" />
                          {metrics.totalGalleries}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center gap-1 text-sm">
                          <Receipt className="h-4 w-4 text-gray-400" />
                          {metrics.totalExpenses}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center gap-1 text-sm">
                          <Sparkles className="h-4 w-4 text-gray-400" />
                          {metrics.totalPromotions}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <UserProfileModal
        user={selectedUser}
        metrics={selectedUser ? userMetrics[selectedUser.id] : null}
        loading={loadingMetrics}
        onClose={() => setSelectedUser(null)}
      />
    </>
  );
}