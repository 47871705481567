import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { DollarSign, Receipt, PieChart, Calculator, TrendingUp, FileText } from 'lucide-react';
import MainHeader from '../../components/MainHeader';
import Footer from '../../components/Footer';

export default function ExpenseManagement() {
  return (
    <>
      <Helmet>
        <title>Art Business Expense Management Software | Studio Planner</title>
        <meta 
          name="description" 
          content="Track your art business expenses and revenue with our professional financial management tools. Monitor costs, generate reports, and make data-driven decisions."
        />
        <meta 
          name="keywords" 
          content="art business expenses, artist financial management, expense tracking software, art business accounting"
        />
      </Helmet>

      <MainHeader />

      <main>
        {/* Hero Section */}
        <div className="bg-gradient-to-b from-green-50 to-white">
          <div className="container mx-auto px-6 py-24">
            <div className="max-w-3xl mx-auto text-center">
              <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
                Smart Financial Management for Artists
              </h1>
              <p className="text-xl text-gray-600 mb-8">
                Take control of your art business finances. Track expenses, monitor revenue, and make data-driven decisions with our professional tools.
              </p>
              <div className="flex justify-center gap-4">
                <Link
                  to="/register"
                  className="px-8 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Features Grid */}
        <div className="container mx-auto px-6 py-24">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-green-100 text-green-600 rounded-lg flex items-center justify-center mb-4">
                <Receipt className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Expense Tracking</h3>
              <p className="text-gray-600">
                Track all your art business expenses with detailed categorization and receipt management.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-blue-100 text-blue-600 rounded-lg flex items-center justify-center mb-4">
                <DollarSign className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Revenue Management</h3>
              <p className="text-gray-600">
                Monitor sales, commissions, and other income streams in one centralized dashboard.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-purple-100 text-purple-600 rounded-lg flex items-center justify-center mb-4">
                <Calculator className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Cost Analysis</h3>
              <p className="text-gray-600">
                Calculate material costs, overhead, and profit margins for each artwork.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-yellow-100 text-yellow-600 rounded-lg flex items-center justify-center mb-4">
                <PieChart className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Financial Reports</h3>
              <p className="text-gray-600">
                Generate detailed financial reports and visualizations of your business performance.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-red-100 text-red-600 rounded-lg flex items-center justify-center mb-4">
                <FileText className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Tax Preparation</h3>
              <p className="text-gray-600">
                Organize expenses and income for easy tax reporting and documentation.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-pink-100 text-pink-600 rounded-lg flex items-center justify-center mb-4">
                <TrendingUp className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Business Insights</h3>
              <p className="text-gray-600">
                Get actionable insights into your business performance and growth opportunities.
              </p>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="bg-white">
          <div className="container mx-auto px-6 py-24">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-bold text-gray-900 mb-6">
                Ready to take control of your finances?
              </h2>
              <p className="text-xl text-gray-600 mb-8">
                Join other artists who are using Studio Planner to manage their business expenses professionally.
              </p>
              <Link
                to="/register"
                className="inline-block px-8 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
}