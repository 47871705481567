import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Task } from '../types';
import './TaskCalendar.css';

type TaskCalendarProps = {
  tasks: Task[];
  onTaskClick: (task: Task) => void;
  onTaskAdd: (task: Partial<Task>) => void;
  onTaskUpdate?: (taskId: string, updates: Partial<Task>) => void;
  initialView?: 'dayGridMonth' | 'timeGridWeek' | 'timeGridDay';
};

export default function TaskCalendar({ 
  tasks, 
  onTaskClick, 
  onTaskAdd, 
  onTaskUpdate,
  initialView = 'dayGridMonth' // Default to month view if not specified
}: TaskCalendarProps) {
  // Only show tasks that are marked for calendar display
  const calendarTasks = tasks.filter(task => task.showInCalendar);

  const events = calendarTasks.map(task => ({
    id: task.id,
    title: task.title,
    start: task.startDate || task.dueDate,
    end: task.endDate,
    allDay: task.isAllDay,
    backgroundColor: task.status === 'completed' ? '#10B981' : '#EF4444',
    borderColor: task.status === 'completed' ? '#059669' : '#DC2626',
    textColor: '#ffffff',
    extendedProps: { task }
  }));

  const handleDateClick = (arg: any) => {
    // Create a new task with the clicked date
    const date = new Date(arg.date);
    const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    
    onTaskAdd({
      title: '',
      description: '',
      status: 'todo',
      dueDate: localDate.toISOString(),
      startDate: localDate.toISOString(),
      endDate: new Date(localDate.getTime() + 60 * 60 * 1000).toISOString(),
      isAllDay: !arg.allDay,
      showInCalendar: true
    });
  };

  const handleEventClick = (arg: any) => {
    onTaskClick(arg.event.extendedProps.task);
  };

  const handleEventDrop = (arg: any) => {
    if (!onTaskUpdate) return;

    const task = arg.event.extendedProps.task;
    const updates: Partial<Task> = {
      startDate: arg.event.start?.toISOString(),
      endDate: arg.event.end?.toISOString(),
      dueDate: arg.event.start?.toISOString(),
      isAllDay: arg.event.allDay
    };

    onTaskUpdate(task.id, updates);
  };

  return (
    <FullCalendar
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      initialView={initialView}
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      }}
      events={events}
      editable={true}
      selectable={true}
      selectMirror={true}
      dayMaxEvents={true}
      weekends={true}
      firstDay={1} // Start week on Monday
      slotMinTime="07:00:00"
      slotMaxTime="20:00:00"
      scrollTime="07:00:00" // Start scrolled to 7am
      businessHours={{
        daysOfWeek: [1, 2, 3, 4, 5], // Monday - Friday
        startTime: '09:00',
        endTime: '18:00',
      }}
      dateClick={handleDateClick}
      eventClick={handleEventClick}
      eventDrop={handleEventDrop}
      height="auto"
      allDaySlot={true}
      nowIndicator={true}
      eventTimeFormat={{
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      }}
      slotLabelFormat={{
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      }}
      views={{
        timeGridWeek: {
          dayHeaderFormat: { weekday: 'short', day: 'numeric' }
        },
        timeGridDay: {
          dayHeaderFormat: { weekday: 'long', month: 'long', day: 'numeric' }
        }
      }}
    />
  );
}