import { useState, useCallback } from 'react';
import { collection, addDoc, updateDoc, doc, Timestamp, query, where, getDocs, runTransaction } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Collection } from '../types';

export const useCollections = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchCollections = useCallback(async () => {
    if (!currentUser) return [];

    try {
      setLoading(true);
      setError(null);
      const q = query(
        collection(db, 'collections'),
        where('artistId', '==', currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const collections = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate().toISOString(),
        updatedAt: doc.data().updatedAt?.toDate()?.toISOString()
      })) as Collection[];
      setLoading(false);
      return collections;
    } catch (err) {
      setError(err as Error);
      setLoading(false);
      throw err;
    }
  }, [currentUser]);

  const addCollection = useCallback(async (data: Omit<Collection, 'id'>) => {
    if (!currentUser) throw new Error('User not authenticated');

    setLoading(true);
    setError(null);
    try {
      const docRef = await addDoc(collection(db, 'collections'), {
        ...data,
        artistId: currentUser.uid,
        pieces: data.pieces || [],
        createdAt: Timestamp.now(),
      });
      setLoading(false);
      return docRef.id;
    } catch (err) {
      setError(err as Error);
      setLoading(false);
      throw err;
    }
  }, [currentUser]);

  const updateCollection = useCallback(async (id: string, data: Partial<Collection>) => {
    if (!currentUser) throw new Error('User not authenticated');

    setLoading(true);
    setError(null);
    try {
      await runTransaction(db, async (transaction) => {
        const collectionRef = doc(db, 'collections', id);
        const collectionDoc = await transaction.get(collectionRef);
        
        if (!collectionDoc.exists()) {
          throw new Error('Collection not found');
        }

        // Update collection document
        transaction.update(collectionRef, {
          ...data,
          updatedAt: Timestamp.now(),
        });

        // If pieces array is being updated, update artwork documents
        if (data.pieces !== undefined) {
          const oldPieces = collectionDoc.data().pieces || [];
          const newPieces = data.pieces || [];

          // Remove collection from artworks no longer in the collection
          const removedPieces = oldPieces.filter(p => !newPieces.includes(p));
          for (const artworkId of removedPieces) {
            const artworkRef = doc(db, 'artworks', artworkId);
            transaction.update(artworkRef, {
              collectionId: null,
              updatedAt: Timestamp.now()
            });
          }

          // Add collection to new artworks
          const addedPieces = newPieces.filter(p => !oldPieces.includes(p));
          for (const artworkId of addedPieces) {
            const artworkRef = doc(db, 'artworks', artworkId);
            transaction.update(artworkRef, {
              collectionId: id,
              updatedAt: Timestamp.now()
            });
          }
        }
      });
      setLoading(false);
    } catch (err) {
      setError(err as Error);
      setLoading(false);
      throw err;
    }
  }, [currentUser]);

  return {
    fetchCollections,
    addCollection,
    updateCollection,
    loading,
    error
  };
}