import React from 'react';
import { Link } from 'react-router-dom';

export default function BlogFooter() {
  return (
    <footer className="bg-white border-t">
      <div className="container mx-auto px-6 py-12">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          {/* Company & Logo */}
          <div className="md:col-span-2">
            <Link to="/" className="flex items-center gap-2">
              <img src="/logo.svg" alt="Studio Planner" className="h-24" />
            </Link>
            <p className="mt-4 text-sm text-gray-500">
              The all-in-one platform for managing your art business.
              Streamline your workflow, showcase your work, and grow your art business.
            </p>
          </div>

          {/* Features */}
          <div>
            <h3 className="font-semibold text-gray-900 mb-3">Features</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/features/art-inventory" className="text-sm text-gray-500 hover:text-gray-900">
                  Art Inventory
                </Link>
              </li>
              <li>
                <Link to="/features/collections" className="text-sm text-gray-500 hover:text-gray-900">
                  Collections
                </Link>
              </li>
              <li>
                <Link to="/features/photo-library" className="text-sm text-gray-500 hover:text-gray-900">
                  Photo Library
                </Link>
              </li>
              <li>
                <Link to="/features/expense-management" className="text-sm text-gray-500 hover:text-gray-900">
                  Expense Management
                </Link>
              </li>
            </ul>
          </div>

          {/* More Features */}
          <div>
            <h3 className="font-semibold text-gray-900 mb-3">More Features</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/features/tasks" className="text-sm text-gray-500 hover:text-gray-900">
                  Tasks & Calendar
                </Link>
              </li>
              <li>
                <Link to="/features/open-calls" className="text-sm text-gray-500 hover:text-gray-900">
                  Open-Calls
                </Link>
              </li>
              <li>
                <Link to="/features/galleries" className="text-sm text-gray-500 hover:text-gray-900">
                  Galleries & Fairs
                </Link>
              </li>
              <li>
                <Link to="/features/ai-promotions" className="text-sm text-gray-500 hover:text-gray-900">
                  AI Promotions
                </Link>
              </li>
            </ul>
          </div>

          {/* Resources & Legal */}
          <div>
            <h3 className="font-semibold text-gray-900 mb-3">Company</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/blog" className="text-sm text-gray-500 hover:text-gray-900">
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/pricing" className="text-sm text-gray-500 hover:text-gray-900">
                  Pricing
                </Link>
              </li>
              <li>
                <a href="#" className="text-sm text-gray-500 hover:text-gray-900">
                  Documentation
                </a>
              </li>
              <li>
                <a href="#" className="text-sm text-gray-500 hover:text-gray-900">
                  Support
                </a>
              </li>
              <li>
                <a href="#" className="text-sm text-gray-500 hover:text-gray-900">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="#" className="text-sm text-gray-500 hover:text-gray-900">
                  Terms of Service
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-8 pt-8 border-t text-center text-sm text-gray-500">
          © {new Date().getFullYear()} Studio Planner. All rights reserved.
        </div>
      </div>
    </footer>
  );
}