import { useState, useCallback } from 'react';
import { collection, addDoc, updateDoc, doc, deleteDoc, Timestamp, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { GalleryImage } from '../types';

// Helper function to clean data before sending to Firestore
function cleanDataForFirestore(data: any) {
  // Remove undefined values
  const cleaned = Object.entries(data).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      acc[key] = value;
    }
    return acc;
  }, {} as Record<string, any>);

  // Convert any Date objects to Timestamps
  if (cleaned.createdAt && !(cleaned.createdAt instanceof Timestamp)) {
    cleaned.createdAt = Timestamp.fromDate(new Date(cleaned.createdAt));
  }
  if (cleaned.updatedAt && !(cleaned.updatedAt instanceof Timestamp)) {
    cleaned.updatedAt = Timestamp.fromDate(new Date(cleaned.updatedAt));
  }

  // Ensure arrays are defined
  cleaned.tags = cleaned.tags || [];

  // Convert empty strings to null for optional fields
  if (cleaned.description === '') cleaned.description = null;
  if (cleaned.linkedArtworkId === '') cleaned.linkedArtworkId = null;
  if (cleaned.linkedCollectionId === '') cleaned.linkedCollectionId = null;

  return cleaned;
}

export function useGallery() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchImages = useCallback(async () => {
    if (!currentUser) return [];

    try {
      setLoading(true);
      setError(null);
      const q = query(
        collection(db, 'gallery'),
        where('artistId', '==', currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const images = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          createdAt: data.createdAt?.toDate().toISOString(),
          updatedAt: data.updatedAt?.toDate()?.toISOString(),
          tags: data.tags || [] // Ensure tags is always an array
        };
      }) as GalleryImage[];
      setLoading(false);
      return images.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    } catch (err) {
      setError(err as Error);
      setLoading(false);
      throw err;
    }
  }, [currentUser]);

  const addImage = useCallback(async (data: Omit<GalleryImage, 'id' | 'artistId' | 'createdAt'>) => {
    if (!currentUser) throw new Error('User not authenticated');

    setLoading(true);
    setError(null);
    try {
      const cleanedData = cleanDataForFirestore({
        ...data,
        artistId: currentUser.uid,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        tags: data.tags || []
      });

      const docRef = await addDoc(collection(db, 'gallery'), cleanedData);
      setLoading(false);
      return docRef.id;
    } catch (err) {
      setError(err as Error);
      setLoading(false);
      throw err;
    }
  }, [currentUser]);

  const updateImage = useCallback(async (id: string, data: Partial<GalleryImage>) => {
    if (!currentUser) throw new Error('User not authenticated');

    setLoading(true);
    setError(null);
    try {
      const cleanedData = cleanDataForFirestore({
        ...data,
        updatedAt: Timestamp.now()
      });

      const docRef = doc(db, 'gallery', id);
      await updateDoc(docRef, cleanedData);
      setLoading(false);
    } catch (err) {
      setError(err as Error);
      setLoading(false);
      throw err;
    }
  }, [currentUser]);

  const deleteImage = useCallback(async (id: string) => {
    if (!currentUser) throw new Error('User not authenticated');

    setLoading(true);
    setError(null);
    try {
      await deleteDoc(doc(db, 'gallery', id));
      setLoading(false);
    } catch (err) {
      setError(err as Error);
      setLoading(false);
      throw err;
    }
  }, [currentUser]);

  return {
    fetchImages,
    addImage,
    updateImage,
    deleteImage,
    loading,
    error
  };
}