import React, { useState, useEffect } from 'react';
import { X, Tag, Link as LinkIcon, Plus, Search, Download, Copy, Check } from 'lucide-react';
import { GalleryImage } from '../types';
import { useArtworks } from '../hooks/useArtworks';
import { useCollections } from '../hooks/useCollections';

type GalleryFormProps = {
  onClose: () => void;
  onSubmit: (data: Partial<GalleryImage>) => void;
  onDelete?: (id: string) => void;
  initialData?: GalleryImage;
  loading?: boolean;
};

export default function GalleryForm({ onClose, onSubmit, onDelete, initialData, loading }: GalleryFormProps) {
  const { fetchArtworks, addArtwork } = useArtworks();
  const { fetchCollections, addCollection } = useCollections();
  const [artworks, setArtworks] = useState<any[]>([]);
  const [collections, setCollections] = useState<any[]>([]);
  const [artworkSearch, setArtworkSearch] = useState('');
  const [collectionSearch, setCollectionSearch] = useState('');
  const [showArtworkSearch, setShowArtworkSearch] = useState(false);
  const [showCollectionSearch, setShowCollectionSearch] = useState(false);
  const [creatingArtwork, setCreatingArtwork] = useState(false);
  const [creatingCollection, setCreatingCollection] = useState(false);
  const [newTag, setNewTag] = useState('');
  const [copied, setCopied] = useState(false);
  const [formData, setFormData] = useState({
    url: initialData?.url || '',
    title: initialData?.title || '',
    description: initialData?.description || '',
    tags: initialData?.tags || [],
    linkedArtworkId: initialData?.linkedArtworkId || undefined,
    linkedCollectionId: initialData?.linkedCollectionId || undefined
  });

  useEffect(() => {
    const loadData = async () => {
      try {
        const [artworksData, collectionsData] = await Promise.all([
          fetchArtworks(),
          fetchCollections()
        ]);
        setArtworks(artworksData);
        setCollections(collectionsData);
      } catch (err) {
        console.error('Error loading data:', err);
      }
    };
    loadData();
  }, [fetchArtworks, fetchCollections]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleDelete = () => {
    if (!initialData || !onDelete) return;
    
    if (window.confirm('Are you sure you want to delete this photo? This action cannot be undone.')) {
      onDelete(initialData.id);
    }
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = formData.url;
    link.download = formData.title || 'photo';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCopyUrl = async () => {
    try {
      await navigator.clipboard.writeText(formData.url);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy URL:', err);
    }
  };

  const handleAddTag = () => {
    if (!newTag.trim()) return;
    if (!formData.tags.includes(newTag.trim())) {
      setFormData(prev => ({
        ...prev,
        tags: [...prev.tags, newTag.trim()]
      }));
    }
    setNewTag('');
  };

  const handleRemoveTag = (tag: string) => {
    setFormData(prev => ({
      ...prev,
      tags: prev.tags.filter(t => t !== tag)
    }));
  };

  const handleArtworkSelect = (artwork: any) => {
    setFormData(prev => ({
      ...prev,
      linkedArtworkId: artwork.id,
      linkedCollectionId: undefined
    }));
    setShowArtworkSearch(false);
  };

  const handleCollectionSelect = (collection: any) => {
    setFormData(prev => ({
      ...prev,
      linkedCollectionId: collection.id,
      linkedArtworkId: undefined
    }));
    setShowCollectionSearch(false);
  };

  const handleCreateArtwork = async () => {
    if (!artworkSearch.trim()) return;

    try {
      setCreatingArtwork(true);
      const id = await addArtwork({
        title: artworkSearch,
        description: '',
        type: 'other',
        price: 0,
        cost: 0,
        materials: [],
        location: '',
        images: [formData.url],
        status: 'available'
      });

      const updatedArtworks = await fetchArtworks();
      setArtworks(updatedArtworks);
      const newArtwork = updatedArtworks.find(a => a.id === id);
      if (newArtwork) {
        handleArtworkSelect(newArtwork);
      }
    } catch (err) {
      console.error('Error creating artwork:', err);
    } finally {
      setCreatingArtwork(false);
    }
  };

  const handleCreateCollection = async () => {
    if (!collectionSearch.trim()) return;

    try {
      setCreatingCollection(true);
      const id = await addCollection({
        name: collectionSearch,
        description: '',
        pieces: []
      });

      const updatedCollections = await fetchCollections();
      setCollections(updatedCollections);
      const newCollection = updatedCollections.find(c => c.id === id);
      if (newCollection) {
        handleCollectionSelect(newCollection);
      }
    } catch (err) {
      console.error('Error creating collection:', err);
    } finally {
      setCreatingCollection(false);
    }
  };

  const filteredArtworks = artworks.filter(art =>
    art.title?.toLowerCase().includes(artworkSearch.toLowerCase())
  );

  const filteredCollections = collections.filter(col =>
    col.name?.toLowerCase().includes(collectionSearch.toLowerCase())
  );

  return (
    <div className="fixed inset-0 bg-white z-50 overflow-hidden flex flex-col">
      {/* Header */}
      <div className="flex items-center justify-between px-6 py-4 border-b">
        <h2 className="text-xl font-semibold">Edit Photo</h2>
        <button
          onClick={onClose}
          className="p-2 hover:bg-gray-100 rounded-lg"
        >
          <X className="h-5 w-5" />
        </button>
      </div>

      {/* Main content */}
      <form onSubmit={handleSubmit} className="flex-1 flex divide-x divide-gray-200 overflow-hidden">
        {/* Left side - Photo preview */}
        <div className="w-1/2 p-6 overflow-y-auto">
          <div className="sticky top-6">
            <div className="aspect-[3/2] bg-gray-100 rounded-lg overflow-hidden mb-4">
              {formData.url.includes('.mp4') || formData.url.includes('.webm') ? (
                <video
                  src={formData.url}
                  controls
                  className="w-full h-full object-contain"
                />
              ) : (
                <img
                  src={formData.url}
                  alt={formData.title}
                  className="w-full h-full object-contain"
                />
              )}
            </div>

            {/* Photo Actions */}
            <div className="flex gap-2 mb-4">
              <button
                type="button"
                onClick={handleDownload}
                className="flex-1 flex items-center justify-center gap-2 px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
              >
                <Download className="h-4 w-4" />
                Download Photo
              </button>
              <button
                type="button"
                onClick={handleCopyUrl}
                className="flex-1 flex items-center justify-center gap-2 px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
              >
                {copied ? (
                  <>
                    <Check className="h-4 w-4" />
                    Copied!
                  </>
                ) : (
                  <>
                    <Copy className="h-4 w-4" />
                    Copy URL
                  </>
                )}
              </button>
            </div>

            {initialData && onDelete && (
              <button
                type="button"
                onClick={handleDelete}
                className="w-full px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg"
              >
                Delete Photo
              </button>
            )}
          </div>
        </div>

        {/* Right side - Form fields */}
        <div className="w-1/2 p-6 overflow-y-auto">
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Title
              </label>
              <input
                type="text"
                value={formData.title}
                onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Description
              </label>
              <textarea
                value={formData.description}
                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                rows={3}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Link to Artwork
              </label>
              {formData.linkedArtworkId ? (
                <div className="flex items-center justify-between p-2 bg-gray-50 rounded-lg">
                  <span className="text-sm">
                    {artworks.find(a => a.id === formData.linkedArtworkId)?.title}
                  </span>
                  <button
                    type="button"
                    onClick={() => setFormData(prev => ({ ...prev, linkedArtworkId: undefined }))}
                    className="p-1 hover:bg-gray-200 rounded-full"
                  >
                    <X className="h-4 w-4" />
                  </button>
                </div>
              ) : (
                <div className="relative">
                  <input
                    type="text"
                    value={artworkSearch}
                    onChange={(e) => setArtworkSearch(e.target.value)}
                    onFocus={() => setShowArtworkSearch(true)}
                    placeholder="Search or create artwork..."
                    className="w-full pl-9 pr-3 py-2 border rounded-lg"
                  />
                  <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
                  {showArtworkSearch && (
                    <div className="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-48 overflow-y-auto">
                      {filteredArtworks.map((artwork) => (
                        <button
                          key={artwork.id}
                          type="button"
                          onClick={() => handleArtworkSelect(artwork)}
                          className="w-full flex items-center gap-3 p-2 hover:bg-gray-50 text-left"
                        >
                          {artwork.images?.[0] && (
                            <img
                              src={artwork.images[0]}
                              alt={artwork.title}
                              className="h-10 w-10 object-cover rounded"
                            />
                          )}
                          <div>
                            <div className="font-medium">{artwork.title}</div>
                            <div className="text-sm text-gray-500">{artwork.type}</div>
                          </div>
                        </button>
                      ))}
                      {artworkSearch && !filteredArtworks.length && !creatingArtwork && (
                        <button
                          type="button"
                          onClick={() => setCreatingArtwork(true)}
                          className="w-full flex items-center gap-2 p-2 hover:bg-gray-50 text-left text-indigo-600"
                        >
                          <Plus className="h-4 w-4" />
                          Create "{artworkSearch}"
                        </button>
                      )}
                      {creatingArtwork && (
                        <div className="p-2 space-y-2">
                          <p className="text-sm text-gray-500">
                            Create new artwork "{artworkSearch}"?
                          </p>
                          <div className="flex gap-2">
                            <button
                              type="button"
                              onClick={handleCreateArtwork}
                              className="flex-1 px-3 py-1 bg-indigo-600 text-white text-sm rounded-md hover:bg-indigo-700"
                            >
                              Create
                            </button>
                            <button
                              type="button"
                              onClick={() => setCreatingArtwork(false)}
                              className="flex-1 px-3 py-1 border text-sm rounded-md hover:bg-gray-50"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Link to Collection
              </label>
              {formData.linkedCollectionId ? (
                <div className="flex items-center justify-between p-2 bg-gray-50 rounded-lg">
                  <span className="text-sm">
                    {collections.find(c => c.id === formData.linkedCollectionId)?.name}
                  </span>
                  <button
                    type="button"
                    onClick={() => setFormData(prev => ({ ...prev, linkedCollectionId: undefined }))}
                    className="p-1 hover:bg-gray-200 rounded-full"
                  >
                    <X className="h-4 w-4" />
                  </button>
                </div>
              ) : (
                <div className="relative">
                  <input
                    type="text"
                    value={collectionSearch}
                    onChange={(e) => setCollectionSearch(e.target.value)}
                    onFocus={() => setShowCollectionSearch(true)}
                    placeholder="Search or create collection..."
                    className="w-full pl-9 pr-3 py-2 border rounded-lg"
                  />
                  <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
                  {showCollectionSearch && (
                    <div className="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-48 overflow-y-auto">
                      {filteredCollections.map((collection) => (
                        <button
                          key={collection.id}
                          type="button"
                          onClick={() => handleCollectionSelect(collection)}
                          className="w-full flex items-center gap-3 p-2 hover:bg-gray-50 text-left"
                        >
                          {collection.coverImage && (
                            <img
                              src={collection.coverImage}
                              alt={collection.name}
                              className="h-10 w-10 object-cover rounded"
                            />
                          )}
                          <div>
                            <div className="font-medium">{collection.name}</div>
                            <div className="text-sm text-gray-500">
                              {collection.pieces?.length || 0} pieces
                            </div>
                          </div>
                        </button>
                      ))}
                      {collectionSearch && !filteredCollections.length && !creatingCollection && (
                        <button
                          type="button"
                          onClick={() => setCreatingCollection(true)}
                          className="w-full flex items-center gap-2 p-2 hover:bg-gray-50 text-left text-indigo-600"
                        >
                          <Plus className="h-4 w-4" />
                          Create "{collectionSearch}"
                        </button>
                      )}
                      {creatingCollection && (
                        <div className="p-2 space-y-2">
                          <p className="text-sm text-gray-500">
                            Create new collection "{collectionSearch}"?
                          </p>
                          <div className="flex gap-2">
                            <button
                              type="button"
                              onClick={handleCreateCollection}
                              className="flex-1 px-3 py-1 bg-indigo-600 text-white text-sm rounded-md hover:bg-indigo-700"
                            >
                              Create
                            </button>
                            <button
                              type="button"
                              onClick={() => setCreatingCollection(false)}
                              className="flex-1 px-3 py-1 border text-sm rounded-md hover:bg-gray-50"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Tags
              </label>
              <div className="space-y-2">
                <div className="flex flex-wrap gap-2">
                  {formData.tags.map((tag) => (
                    <span
                      key={tag}
                      className="inline-flex items-center gap-1 px-2 py-1 bg-gray-100 rounded-full text-sm"
                    >
                      <Tag className="h-3 w-3" />
                      {tag}
                      <button
                        type="button"
                        onClick={() => handleRemoveTag(tag)}
                        className="p-0.5 hover:bg-gray-200 rounded-full"
                      >
                        <X className="h-3 w-3" />
                      </button>
                    </span>
                  ))}
                </div>
                <div className="flex gap-2">
                  <input
                    type="text"
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && (e.preventDefault(), handleAddTag())}
                    className="flex-1 px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                    placeholder="Add a tag..."
                  />
                  <button
                    type="button"
                    onClick={handleAddTag}
                    className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>

            <div className="flex justify-end gap-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 border rounded-lg hover:bg-gray-50"
                disabled={loading}
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={loading}
                className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
              >
                {loading ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}