import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../../contexts/AuthContext';

export default function AdminDashboard() {
  const { currentUser } = useAuth();

  return (
    <>
      <Helmet>
        <title>Admin Dashboard | Studio Planner</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <div className="space-y-6">
        <h1 className="text-2xl font-bold text-gray-900">Admin Dashboard</h1>
        <div className="bg-white rounded-lg border border-gray-200 p-6">
          <p className="text-gray-600">Welcome, {currentUser?.email}</p>
        </div>
      </div>
    </>
  );
}