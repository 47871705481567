import { useState, useCallback } from 'react';
import { collection, addDoc, updateDoc, doc, deleteDoc, Timestamp, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Opportunity } from '../types';

function cleanDataForFirestore(data: any) {
  const cleaned = { ...data };

  // Convert date strings to Timestamps
  if (cleaned.deadline) {
    cleaned.deadline = Timestamp.fromDate(new Date(cleaned.deadline));
  }
  if (cleaned.createdAt && !(cleaned.createdAt instanceof Timestamp)) {
    cleaned.createdAt = Timestamp.fromDate(new Date(cleaned.createdAt));
  }
  if (cleaned.updatedAt && !(cleaned.updatedAt instanceof Timestamp)) {
    cleaned.updatedAt = Timestamp.fromDate(new Date(cleaned.updatedAt));
  }

  // Ensure arrays are defined
  cleaned.tasks = cleaned.tasks || [];

  // Remove undefined values
  Object.keys(cleaned).forEach(key => {
    if (cleaned[key] === undefined) {
      delete cleaned[key];
    }
  });

  return cleaned;
}

export function useOpportunities() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchOpportunities = useCallback(async () => {
    if (!currentUser) return [];

    try {
      setLoading(true);
      setError(null);
      const q = query(
        collection(db, 'opportunities'),
        where('artistId', '==', currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const opportunities = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate().toISOString(),
        updatedAt: doc.data().updatedAt?.toDate()?.toISOString(),
        deadline: doc.data().deadline?.toDate().toISOString(),
      })) as Opportunity[];
      return opportunities;
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  const addOpportunity = useCallback(async (data: Omit<Opportunity, 'id' | 'artistId' | 'createdAt'>) => {
    if (!currentUser) throw new Error('User not authenticated');

    try {
      setLoading(true);
      setError(null);
      const cleanedData = cleanDataForFirestore({
        ...data,
        artistId: currentUser.uid,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        tasks: data.tasks || []
      });

      const docRef = await addDoc(collection(db, 'opportunities'), cleanedData);
      return docRef.id;
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  const updateOpportunity = useCallback(async (id: string, data: Partial<Opportunity>) => {
    if (!currentUser) throw new Error('User not authenticated');

    try {
      setLoading(true);
      setError(null);
      const cleanedData = cleanDataForFirestore({
        ...data,
        updatedAt: Timestamp.now()
      });

      const docRef = doc(db, 'opportunities', id);
      await updateDoc(docRef, cleanedData);
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  const deleteOpportunity = useCallback(async (id: string) => {
    if (!currentUser) throw new Error('User not authenticated');

    try {
      setLoading(true);
      setError(null);
      await deleteDoc(doc(db, 'opportunities', id));
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  return {
    fetchOpportunities,
    addOpportunity,
    updateOpportunity,
    deleteOpportunity,
    loading,
    error
  };
}