import React from 'react';
import { Outlet } from 'react-router-dom';
import BlogHeader from './BlogHeader';
import BlogFooter from './BlogFooter';

export default function BlogLayout() {
  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <BlogHeader />
      <main className="flex-1">
        <Outlet />
      </main>
      <BlogFooter />
    </div>
  );
}