import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Building2, Users, Globe, Calendar, MessageSquare, BarChart, Network } from 'lucide-react';
import MainHeader from '../../components/MainHeader';
import Footer from '../../components/Footer';

export default function Galleries() {
  return (
    <>
      <Helmet>
        <title>Gallery & Art Fair Management Software | Studio Planner</title>
        <meta 
          name="description" 
          content="Professional gallery and art fair management software for artists. Build relationships with galleries, track art fair participation, and grow your art business."
        />
        <meta 
          name="keywords" 
          content="gallery management software, art fair tracking, gallery relationships, artist gallery tools, art fair management"
        />
      </Helmet>

      <MainHeader />

      <main>
        {/* Hero Section */}
        <div className="bg-gradient-to-b from-slate-50 to-white">
          <div className="container mx-auto px-6 py-24">
            <div className="max-w-3xl mx-auto text-center">
              <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
                Manage Gallery Relationships & Art Fairs
              </h1>
              <p className="text-xl text-gray-600 mb-8">
                Build and maintain professional relationships with galleries, track art fair participation, and expand your art business network with our comprehensive management tools.
              </p>
              <div className="flex justify-center gap-4">
                <Link
                  to="/register"
                  className="px-8 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Features Grid */}
        <div className="container mx-auto px-6 py-24">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-slate-100 text-slate-600 rounded-lg flex items-center justify-center mb-4">
                <Building2 className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Gallery Directory</h3>
              <p className="text-gray-600">
                Maintain a comprehensive directory of galleries with detailed profiles and contact information.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-blue-100 text-blue-600 rounded-lg flex items-center justify-center mb-4">
                <Users className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Contact Management</h3>
              <p className="text-gray-600">
                Track gallery contacts, conversations, and relationship history in one place.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-green-100 text-green-600 rounded-lg flex items-center justify-center mb-4">
                <Globe className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Art Fair Planning</h3>
              <p className="text-gray-600">
                Plan and manage art fair participations with comprehensive event tracking.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-purple-100 text-purple-600 rounded-lg flex items-center justify-center mb-4">
                <Calendar className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Exhibition Calendar</h3>
              <p className="text-gray-600">
                Keep track of exhibition schedules, openings, and important gallery events.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-pink-100 text-pink-600 rounded-lg flex items-center justify-center mb-4">
                <MessageSquare className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Communication Log</h3>
              <p className="text-gray-600">
                Document all gallery communications and follow-ups in a structured format.
              </p>
            </div>

            <div className="p-6 bg-white rounded-xl border border-gray-200">
              <div className="w-12 h-12 bg-orange-100 text-orange-600 rounded-lg flex items-center justify-center mb-4">
                <Network className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-semibold mb-2">Network Growth</h3>
              <p className="text-gray-600">
                Expand your gallery network with strategic relationship management tools.
              </p>
            </div>
          </div>
        </div>

        {/* Benefits Section */}
        <div className="bg-gray-50">
          <div className="container mx-auto px-6 py-24">
            <div className="max-w-3xl mx-auto">
              <h2 className="text-3xl font-bold text-gray-900 text-center mb-12">
                Why Choose Our Gallery Management Tools?
              </h2>
              <div className="space-y-8">
                <div className="flex gap-4">
                  <div className="flex-shrink-0 w-12 h-12 bg-indigo-100 text-indigo-600 rounded-lg flex items-center justify-center">
                    <Building2 className="h-6 w-6" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Professional Organization</h3>
                    <p className="text-gray-600">
                      Keep all your gallery relationships organized and accessible in one professional system.
                    </p>
                  </div>
                </div>

                <div className="flex gap-4">
                  <div className="flex-shrink-0 w-12 h-12 bg-indigo-100 text-indigo-600 rounded-lg flex items-center justify-center">
                    <Calendar className="h-6 w-6" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Strategic Planning</h3>
                    <p className="text-gray-600">
                      Plan your exhibition schedule and art fair participation strategically.
                    </p>
                  </div>
                </div>

                <div className="flex gap-4">
                  <div className="flex-shrink-0 w-12 h-12 bg-indigo-100 text-indigo-600 rounded-lg flex items-center justify-center">
                    <BarChart className="h-6 w-6" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Growth Insights</h3>
                    <p className="text-gray-600">
                      Track your gallery relationships and measure the success of your exhibitions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="bg-white">
          <div className="container mx-auto px-6 py-24">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-bold text-gray-900 mb-6">
                Ready to grow your gallery network?
              </h2>
              <p className="text-xl text-gray-600 mb-8">
                Join other artists who are using Studio Planner to manage their gallery relationships professionally.
              </p>
              <Link
                to="/register"
                className="inline-block px-8 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
}