import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Plus, Palette, BarChart, TrendingUp, DollarSign } from 'lucide-react';
import PageHeader from '../components/PageHeader';
import SearchBar from '../components/SearchBar';
import Card from '../components/Card';
import ViewToggle from '../components/ViewToggle';
import SlidePanel from '../components/SlidePanel';
import ArtPieceForm from '../components/ArtPieceForm';
import ArtPieceDetails from '../components/ArtPieceDetails';
import FilterMenu, { countActiveFilters } from '../components/FilterMenu';
import Pagination from '../components/Pagination';
import { ArtPiece } from '../types';
import { useArtworks } from '../hooks/useArtworks';
import LoadingSpinner from '../components/LoadingSpinner';
import { useProfile } from '../contexts/ProfileContext';
import StatsCard from '../components/StatsCard';

const ITEMS_PER_PAGE = 10;

export default function Inventory() {
  const { artworkId } = useParams();
  const navigate = useNavigate();
  const { profile } = useProfile();
  const { fetchArtworks, addArtwork, updateArtwork, deleteArtwork, loading: artworksLoading } = useArtworks();
  const [artworks, setArtworks] = useState<ArtPiece[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [view, setView] = useState<'grid' | 'list'>('list');
  const [showForm, setShowForm] = useState(false);
  const [selectedArtwork, setSelectedArtwork] = useState<ArtPiece | null>(null);
  const [editingArtwork, setEditingArtwork] = useState<ArtPiece | null>(null);
  const [showFilters, setShowFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    types: [],
    locations: [],
    status: [],
    collections: [],
    priceRange: { min: null, max: null },
    costRange: { min: null, max: null },
  });

  useEffect(() => {
    const loadArtworks = async () => {
      try {
        const data = await fetchArtworks();
        setArtworks(data);
      } catch (err) {
        console.error('Error fetching artworks:', err);
      }
    };
    loadArtworks();
  }, [fetchArtworks]);

  useEffect(() => {
    if (artworkId) {
      const artwork = artworks.find(a => a.id === artworkId);
      if (artwork) {
        setSelectedArtwork(artwork);
      }
    }
  }, [artworkId, artworks]);

  // Calculate KPIs
  const totalArtworks = artworks.length;
  const totalValue = artworks.reduce((sum, art) => sum + (art.price || 0), 0);
  const soldArtworks = artworks.filter(art => art.status === 'sold');
  const totalSold = soldArtworks.length;
  const soldValue = soldArtworks.reduce((sum, art) => sum + (art.price || 0), 0);

  const stats = [
    {
      label: 'Total Artworks',
      value: totalArtworks,
      icon: BarChart,
      color: 'bg-blue-100 text-blue-600'
    },
    {
      label: 'Total Value',
      value: `${profile.currency.symbol}${totalValue.toLocaleString()}`,
      icon: TrendingUp,
      color: 'bg-green-100 text-green-600'
    },
    {
      label: 'Total Sold',
      value: `${totalSold} (${profile.currency.symbol}${soldValue.toLocaleString()})`,
      icon: DollarSign,
      color: 'bg-indigo-100 text-indigo-600',
      change: totalArtworks ? `${Math.round((totalSold / totalArtworks) * 100)}%` : 'N/A'
    }
  ];

  const handleCloseArtwork = () => {
    setSelectedArtwork(null);
    navigate('/app/inventory');
  };

  const handleAddArtwork = async (data: Omit<ArtPiece, 'id'>) => {
    try {
      setLoading(true);
      const id = await addArtwork(data);
      const newArtwork = { ...data, id } as ArtPiece;
      setArtworks([...artworks, newArtwork]);
      setShowForm(false);
    } catch (err) {
      console.error('Error adding artwork:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleEditArtwork = async (data: Omit<ArtPiece, 'id'>) => {
    if (!editingArtwork) return;
    
    try {
      setLoading(true);
      await updateArtwork(editingArtwork.id, data);
      const updatedArtworks = artworks.map(artwork =>
        artwork.id === editingArtwork.id
          ? { ...artwork, ...data }
          : artwork
      );
      setArtworks(updatedArtworks);
      setEditingArtwork(null);
    } catch (err) {
      console.error('Error updating artwork:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteArtwork = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this artwork?')) {
      return;
    }

    try {
      setLoading(true);
      await deleteArtwork(id);
      setArtworks(artworks.filter(artwork => artwork.id !== id));
      setSelectedArtwork(null);
    } catch (err) {
      console.error('Error deleting artwork:', err);
    } finally {
      setLoading(false);
    }
  };

  // Filter artworks based on search and filters
  const filteredArtworks = artworks.filter(art => {
    const matchesSearch = 
      (art.title?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
      (art.description?.toLowerCase() || '').includes(searchTerm.toLowerCase());

    const matchesType = filters.types.length === 0 || filters.types.includes(art.type);
    const matchesLocation = filters.locations.length === 0 || filters.locations.includes(art.location);
    const matchesStatus = filters.status.length === 0 || filters.status.includes(art.status);
    const matchesCollection = filters.collections.length === 0 || 
      (art.collectionId && filters.collections.includes(art.collectionId));

    const matchesPriceRange = 
      (!filters.priceRange.min || art.price >= filters.priceRange.min) &&
      (!filters.priceRange.max || art.price <= filters.priceRange.max);

    const matchesCostRange = 
      (!filters.costRange.min || art.cost >= filters.costRange.min) &&
      (!filters.costRange.max || art.cost <= filters.costRange.max);

    return matchesSearch && matchesType && matchesLocation && 
           matchesStatus && matchesCollection && matchesPriceRange && matchesCostRange;
  });

  const totalPages = Math.ceil(filteredArtworks.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const paginatedArtworks = filteredArtworks.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  if (artworksLoading) {
    return <LoadingSpinner />;
  }

  const locations = Array.from(new Set(artworks.map(art => art.location)));
  const collections = [
    { id: '1', name: 'Spring Collection' },
    { id: '2', name: 'Summer Collection' }
  ];

  return (
    <div className="space-y-6">
      <PageHeader 
        title="Art Inventory" 
        buttonLabel="Add Artwork"
        buttonIcon={Plus}
        onButtonClick={() => setShowForm(true)}
      />

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {stats.map((stat) => (
          <StatsCard key={stat.label} {...stat} />
        ))}
      </div>

      <div className="flex items-center justify-between gap-4">
        <div className="flex-1">
          <SearchBar 
            placeholder="Search artworks..." 
            onSearch={setSearchTerm}
            onFilterClick={() => setShowFilters(true)}
            activeFilters={countActiveFilters(filters)}
            showFilter={true}
          />
        </div>
        <ViewToggle 
          view={view} 
          onViewChange={setView}
        />
      </div>

      {view === 'grid' ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {paginatedArtworks.map((artwork) => (
            <Card 
              key={artwork.id}
              onClick={() => setSelectedArtwork(artwork)}
            >
              <img
                src={artwork.images[0] || 'https://via.placeholder.com/400x300'}
                alt={artwork.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900">{artwork.title}</h3>
                    <p className="text-sm text-gray-500">{artwork.materials?.join(', ')}</p>
                  </div>
                  <span className={`px-2 py-1 text-sm font-medium rounded-full ${
                    artwork.status === 'available' ? 'text-green-700 bg-green-100' :
                    artwork.status === 'sold' ? 'text-red-700 bg-red-100' :
                    'text-yellow-700 bg-yellow-100'
                  }`}>
                    {artwork.status.charAt(0).toUpperCase() + artwork.status.slice(1)}
                  </span>
                </div>
                <div className="mt-4">
                  <span className="text-lg font-bold text-gray-900">
                    {profile.currency.symbol}{artwork.price?.toLocaleString()}
                  </span>
                </div>
              </div>
            </Card>
          ))}
        </div>
      ) : (
        <div className="bg-white rounded-lg border border-gray-200">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-50 border-b border-gray-200">
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Image</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {paginatedArtworks.map((artwork) => (
                  <tr 
                    key={artwork.id}
                    onClick={() => setSelectedArtwork(artwork)}
                    className="hover:bg-gray-50 cursor-pointer"
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <img
                        src={artwork.images[0] || 'https://via.placeholder.com/100x100'}
                        alt={artwork.title}
                        className="h-12 w-12 object-cover rounded-lg"
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">{artwork.title}</div>
                      <div className="text-sm text-gray-500">{artwork.materials?.join(', ')}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{artwork.type.split(' - ')[1]}</div>
                      <div className="text-xs text-gray-500">{artwork.type.split(' - ')[0]}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {profile.currency.symbol}{artwork.price?.toLocaleString()}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{artwork.location}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                        artwork.status === 'available' ? 'text-green-700 bg-green-100' :
                        artwork.status === 'sold' ? 'text-red-700 bg-red-100' :
                        'text-yellow-700 bg-yellow-100'
                      }`}>
                        {artwork.status.charAt(0).toUpperCase() + artwork.status.slice(1)}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
            totalItems={filteredArtworks.length}
            itemsPerPage={ITEMS_PER_PAGE}
          />
        </div>
      )}

      <FilterMenu
        isOpen={showFilters}
        onClose={() => setShowFilters(false)}
        filters={filters}
        onChange={setFilters}
        locations={locations}
        collections={collections}
        artworks={artworks}
      />

      <SlidePanel
        isOpen={!!selectedArtwork}
        onClose={handleCloseArtwork}
        title="Artwork Details"
      >
        {selectedArtwork && (
          <ArtPieceDetails
            artwork={selectedArtwork}
            onEdit={() => {
              setEditingArtwork(selectedArtwork);
              setSelectedArtwork(null);
            }}
            onDelete={() => handleDeleteArtwork(selectedArtwork.id)}
          />
        )}
      </SlidePanel>

      <SlidePanel
        isOpen={showForm || !!editingArtwork}
        onClose={() => {
          setShowForm(false);
          setEditingArtwork(null);
        }}
        title={editingArtwork ? 'Edit Artwork' : 'New Artwork'}
      >
        <ArtPieceForm
          onClose={() => {
            setShowForm(false);
            setEditingArtwork(null);
          }}
          onSubmit={editingArtwork ? handleEditArtwork : handleAddArtwork}
          initialData={editingArtwork}
          loading={loading}
        />
      </SlidePanel>
    </div>
  );
}