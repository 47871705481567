import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Collection, GalleryImage } from '../types';
import { Edit2, Image, Plus, Tag } from 'lucide-react';
import { useProfile } from '../contexts/ProfileContext';
import { useGallery } from '../hooks/useGallery';

type ArtPieceDetailsProps = {
  artwork: ArtPiece;
  onEdit: () => void;
  onDelete: () => void;
};

export default function ArtPieceDetails({ artwork, onEdit }: ArtPieceDetailsProps) {
  const navigate = useNavigate();
  const { profile } = useProfile();
  const { fetchImages } = useGallery();
  const [linkedImages, setLinkedImages] = useState<GalleryImage[]>([]);
  const [hoveredVideo, setHoveredVideo] = useState<string | null>(null);

  useEffect(() => {
    const loadLinkedImages = async () => {
      try {
        const allImages = await fetchImages();
        const images = allImages.filter(img => img.linkedArtworkId === artwork.id);
        setLinkedImages(images);
      } catch (err) {
        console.error('Error loading linked images:', err);
      }
    };
    loadLinkedImages();
  }, [artwork.id, fetchImages]);

  const handleImageClick = (imageId: string) => {
    navigate('/app/gallery', { state: { selectedImageId: imageId } });
  };

  const handleVideoMouseEnter = (url: string) => {
    setHoveredVideo(url);
    // Find and play the video
    const video = document.querySelector(`video[src="${url}"]`) as HTMLVideoElement;
    if (video) {
      video.play().catch(err => console.error('Error playing video:', err));
    }
  };

  const handleVideoMouseLeave = (url: string) => {
    setHoveredVideo(null);
    // Find and pause the video
    const video = document.querySelector(`video[src="${url}"]`) as HTMLVideoElement;
    if (video) {
      video.pause();
      video.currentTime = 0;
    }
  };

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-start justify-between">
        <div>
          <h2 className="text-2xl font-bold text-gray-900">{artwork.title}</h2>
          <p className="text-gray-500 mt-1">{artwork.description}</p>
          <p className="text-sm text-gray-400 mt-1">
            Created on {new Date(artwork.createdAt).toLocaleDateString()}
          </p>
        </div>
        <button
          onClick={onEdit}
          className="inline-flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
        >
          <Edit2 className="h-4 w-4" />
          Edit
        </button>
      </div>

      {/* Main Image */}
      <div className="aspect-[3/2] bg-gray-100 rounded-lg overflow-hidden">
        {artwork.images[0] ? (
          <img
            src={artwork.images[0]}
            alt={artwork.title}
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <Image className="h-12 w-12 text-gray-400" />
          </div>
        )}
      </div>

      {/* Additional Images */}
      {artwork.images.length > 1 && (
        <div className="grid grid-cols-4 gap-4">
          {artwork.images.slice(1).map((image, index) => (
            <div key={index} className="aspect-square bg-gray-100 rounded-lg overflow-hidden">
              <img
                src={image}
                alt={`${artwork.title} - ${index + 2}`}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </div>
      )}

      {/* Details and Pricing Grid */}
      <div className="grid grid-cols-2 gap-6">
        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-4">Details</h3>
          <dl className="space-y-4">
            <div>
              <dt className="text-sm font-medium text-gray-500">Type</dt>
              <dd className="text-sm text-gray-900 mt-1 capitalize">{artwork.type}</dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Materials</dt>
              <dd className="text-sm text-gray-900 mt-1">{artwork.materials?.join(', ') || 'No materials listed'}</dd>
            </div>
            {artwork.size && (
              <div>
                <dt className="text-sm font-medium text-gray-500">Size</dt>
                <dd className="text-sm text-gray-900 mt-1">{artwork.size}</dd>
              </div>
            )}
            <div>
              <dt className="text-sm font-medium text-gray-500">Location</dt>
              <dd className="text-sm text-gray-900 mt-1">{artwork.location}</dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Status</dt>
              <dd className="mt-1">
                <span className={`inline-flex px-2 py-1 text-xs font-medium rounded-full ${
                  artwork.status === 'available' ? 'text-green-700 bg-green-100' :
                  artwork.status === 'sold' ? 'text-red-700 bg-red-100' :
                  'text-yellow-700 bg-yellow-100'
                }`}>
                  {artwork.status.charAt(0).toUpperCase() + artwork.status.slice(1)}
                </span>
              </dd>
            </div>
          </dl>
        </div>

        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-4">Pricing</h3>
          <dl className="space-y-4">
            <div>
              <dt className="text-sm font-medium text-gray-500">Price</dt>
              <dd className="text-2xl font-bold text-gray-900 mt-1">
                {profile.currency.symbol}{artwork.price?.toLocaleString() || '0'}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Cost</dt>
              <dd className="text-lg text-gray-900 mt-1">
                {profile.currency.symbol}{artwork.cost?.toLocaleString() || '0'}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Profit Margin</dt>
              <dd className="text-lg text-gray-900 mt-1">
                {artwork.price && artwork.cost ? 
                  `${Math.round(((artwork.price - artwork.cost) / artwork.price) * 100)}%` :
                  'N/A'
                }
              </dd>
            </div>
          </dl>
        </div>
      </div>

      {/* Measurements */}
      {artwork.measurements && Object.keys(artwork.measurements).length > 0 && (
        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-4">Measurements</h3>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {Object.entries(artwork.measurements).map(([key, value]) => (
              <div key={key} className="bg-gray-50 p-4 rounded-lg">
                <dt className="text-sm font-medium text-gray-500 capitalize">{key}</dt>
                <dd className="text-lg font-semibold text-gray-900 mt-1">{value}</dd>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Shop Link */}
      {artwork.shopLink && (
        <div className="pt-6 border-t">
          <a
            href={artwork.shopLink}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center gap-2 px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
          >
            <Plus className="h-4 w-4" />
            View Product Page
          </a>
        </div>
      )}

      {/* Gallery Images */}
      {linkedImages.length > 0 && (
        <div className="pt-8 border-t">
          <h3 className="text-lg font-semibold text-gray-900 mb-4">Gallery Images</h3>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {linkedImages.map((image) => {
              const isVideo = image.url.includes('.mp4') || image.url.includes('.webm');
              return (
                <div
                  key={image.id}
                  onClick={() => handleImageClick(image.id)}
                  className="aspect-square bg-gray-100 rounded-lg overflow-hidden cursor-pointer group relative"
                  onMouseEnter={() => isVideo && handleVideoMouseEnter(image.url)}
                  onMouseLeave={() => isVideo && handleVideoMouseLeave(image.url)}
                >
                  {isVideo ? (
                    <video
                      src={image.url}
                      className="w-full h-full object-cover"
                      muted
                      playsInline
                      loop
                    />
                  ) : (
                    <img
                      src={image.url}
                      alt={image.title}
                      className="w-full h-full object-cover group-hover:opacity-75 transition-opacity"
                    />
                  )}
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all">
                    <div className="opacity-0 group-hover:opacity-100 transition-opacity text-white text-center p-4">
                      <p className="font-medium">{image.title}</p>
                      <p className="text-sm">Click to view in Gallery</p>
                    </div>
                  </div>
                  {image.tags?.length > 0 && (
                    <div className="absolute bottom-0 left-0 right-0 p-2 bg-gradient-to-t from-black/50 to-transparent">
                      <div className="flex flex-wrap gap-1">
                        {image.tags.slice(0, 2).map((tag, index) => (
                          <span
                            key={index}
                            className="inline-flex items-center gap-1 px-2 py-1 bg-black/30 backdrop-blur-sm rounded-full text-white text-xs"
                          >
                            <Tag className="h-3 w-3" />
                            {tag}
                          </span>
                        ))}
                        {image.tags.length > 2 && (
                          <span className="inline-flex items-center px-2 py-1 bg-black/30 backdrop-blur-sm rounded-full text-white text-xs">
                            +{image.tags.length - 2}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}