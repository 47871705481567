import React, { useState, useEffect } from 'react';
import { Plus, ExternalLink, Calendar, CheckCircle2, XCircle, Clock, Briefcase } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import SearchBar from '../components/SearchBar';
import SlidePanel from '../components/SlidePanel';
import OpportunityForm from '../components/OpportunityForm';
import { useOpportunities } from '../hooks/useOpportunities';
import { useTasks } from '../hooks/useTasks';
import { Opportunity } from '../types';
import LoadingSpinner from '../components/LoadingSpinner';
import StatsCard from '../components/StatsCard';

export default function Opportunities() {
  const navigate = useNavigate();
  const { fetchOpportunities, addOpportunity, updateOpportunity, deleteOpportunity, loading: opportunitiesLoading } = useOpportunities();
  const { fetchTasks } = useTasks();
  const [opportunities, setOpportunities] = useState<Opportunity[]>([]);
  const [tasks, setTasks] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [editingOpportunity, setEditingOpportunity] = useState<Opportunity | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [opportunitiesData, tasksData] = await Promise.all([
          fetchOpportunities(),
          fetchTasks()
        ]);
        setOpportunities(opportunitiesData);
        setTasks(tasksData);
      } catch (err) {
        console.error('Error loading data:', err);
      }
    };
    loadData();
  }, [fetchOpportunities, fetchTasks]);

  const handleAddOpportunity = async (data: Omit<Opportunity, 'id'>) => {
    try {
      setLoading(true);
      const id = await addOpportunity(data);
      const newOpportunity = { ...data, id } as Opportunity;
      setOpportunities([...opportunities, newOpportunity]);
      setShowForm(false);
    } catch (err) {
      console.error('Error adding opportunity:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleEditOpportunity = async (data: Partial<Opportunity>) => {
    if (!editingOpportunity) return;
    
    try {
      setLoading(true);
      await updateOpportunity(editingOpportunity.id, data);
      setOpportunities(prevOpportunities => 
        prevOpportunities.map(opp =>
          opp.id === editingOpportunity.id ? { ...opp, ...data } : opp
        )
      );
      setEditingOpportunity(null);
    } catch (err) {
      console.error('Error updating opportunity:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteOpportunity = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this opportunity?')) {
      return;
    }

    try {
      setLoading(true);
      await deleteOpportunity(id);
      setOpportunities(opportunities.filter(opp => opp.id !== id));
      setEditingOpportunity(null);
    } catch (err) {
      console.error('Error deleting opportunity:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleTaskClick = (taskId: string) => {
    navigate('/app/tasks', { state: { selectedTaskId: taskId } });
  };

  // Filter opportunities based on search term
  const filteredOpportunities = opportunities.filter(opp =>
    opp.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    opp.galleryName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    opp.description?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate KPIs
  const totalOpportunities = opportunities.length;
  const appliedOpportunities = opportunities.filter(opp => opp.applied).length;
  const acceptedOpportunities = opportunities.filter(opp => opp.accepted).length;
  const pendingDeadlines = opportunities.filter(opp => 
    opp.deadline && 
    !opp.applied && 
    new Date(opp.deadline) > new Date()
  ).length;

  const stats = [
    {
      label: 'Total Opportunities',
      value: totalOpportunities,
      icon: Briefcase,
      color: 'bg-blue-100 text-blue-600'
    },
    {
      label: 'Applied',
      value: appliedOpportunities,
      icon: CheckCircle2,
      color: 'bg-green-100 text-green-600',
      change: totalOpportunities ? `${Math.round((appliedOpportunities / totalOpportunities) * 100)}%` : 'N/A'
    },
    {
      label: 'Accepted',
      value: acceptedOpportunities,
      icon: CheckCircle2,
      color: 'bg-indigo-100 text-indigo-600',
      change: appliedOpportunities ? `${Math.round((acceptedOpportunities / appliedOpportunities) * 100)}%` : 'N/A'
    },
    {
      label: 'Pending Deadlines',
      value: pendingDeadlines,
      icon: Clock,
      color: 'bg-yellow-100 text-yellow-600'
    }
  ];

  if (opportunitiesLoading) {
    return <LoadingSpinner />;
  }

  // Show empty state if no opportunities exist
  if (opportunities.length === 0) {
    return (
      <div className="space-y-6">
        <PageHeader 
          title="Opportunities" 
          buttonLabel="Add Opportunity"
          buttonIcon={Plus}
          onButtonClick={() => setShowForm(true)}
        />

        {/* Stats Cards - Show zero state */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          {stats.map((stat) => (
            <StatsCard key={stat.label} {...stat} />
          ))}
        </div>

        <div className="bg-white rounded-lg border border-gray-200 p-8">
          <div className="max-w-xl mx-auto text-center">
            <Briefcase className="h-12 w-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">No opportunities yet</h3>
            <p className="text-gray-500 mb-6">
              Start tracking exhibition opportunities, gallery submissions, art fairs,
              and other opportunities to showcase your work.
            </p>
            <button
              onClick={() => setShowForm(true)}
              className="inline-flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
            >
              <Plus className="h-5 w-5" />
              Add Your First Opportunity
            </button>
          </div>
        </div>

        <SlidePanel
          isOpen={showForm}
          onClose={() => setShowForm(false)}
          title="New Opportunity"
        >
          <OpportunityForm
            onClose={() => setShowForm(false)}
            onSubmit={handleAddOpportunity}
            loading={loading}
          />
        </SlidePanel>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <PageHeader 
        title="Opportunities" 
        buttonLabel="Add Opportunity"
        buttonIcon={Plus}
        onButtonClick={() => setShowForm(true)}
      />

      {/* Stats */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        {stats.map((stat) => (
          <StatsCard key={stat.label} {...stat} />
        ))}
      </div>

      <SearchBar 
        placeholder="Search opportunities..." 
        onSearch={setSearchTerm}
      />

      <div className="bg-white rounded-lg border border-gray-200">
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-50 border-b border-gray-200">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Gallery</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Deadline</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tasks</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {filteredOpportunities.map((opportunity) => {
                const linkedTasks = tasks.filter(task => 
                  task.relatedOpportunityId === opportunity.id
                );
                const completedTasks = linkedTasks.filter(task => 
                  task.status === 'completed'
                ).length;

                return (
                  <tr 
                    key={opportunity.id}
                    onClick={() => setEditingOpportunity(opportunity)}
                    className="hover:bg-gray-50 cursor-pointer"
                  >
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        <div>
                          <div className="font-medium text-gray-900">{opportunity.name}</div>
                          <div className="text-sm text-gray-500 mt-1">{opportunity.description}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-900">{opportunity.galleryName}</div>
                      {opportunity.link && (
                        <a
                          href={opportunity.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className="inline-flex items-center gap-1 text-sm text-indigo-600 hover:text-indigo-700 mt-1"
                        >
                          <ExternalLink className="h-3 w-3" />
                          View Details
                        </a>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {opportunity.deadline ? (
                        <span className={
                          new Date(opportunity.deadline) < new Date()
                            ? 'text-red-600'
                            : 'text-gray-900'
                        }>
                          {new Date(opportunity.deadline).toLocaleDateString()}
                        </span>
                      ) : (
                        <span className="text-gray-400">No deadline</span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center gap-2">
                        {opportunity.applied ? (
                          <span className="inline-flex items-center gap-1 px-2 py-1 rounded-full text-sm font-medium bg-green-100 text-green-700">
                            <CheckCircle2 className="h-4 w-4" />
                            Applied
                          </span>
                        ) : (
                          <span className="inline-flex items-center gap-1 px-2 py-1 rounded-full text-sm font-medium bg-yellow-100 text-yellow-700">
                            <Clock className="h-4 w-4" />
                            Not Applied
                          </span>
                        )}
                        {opportunity.accepted !== undefined && (
                          opportunity.accepted ? (
                            <span className="inline-flex items-center gap-1 px-2 py-1 rounded-full text-sm font-medium bg-indigo-100 text-indigo-700">
                              <CheckCircle2 className="h-4 w-4" />
                              Accepted
                            </span>
                          ) : (
                            <span className="inline-flex items-center gap-1 px-2 py-1 rounded-full text-sm font-medium bg-red-100 text-red-700">
                              <XCircle className="h-4 w-4" />
                              Rejected
                            </span>
                          )
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      {linkedTasks.length > 0 ? (
                        <div className="space-y-2">
                          <div className="text-sm text-gray-500">
                            {completedTasks} of {linkedTasks.length} tasks completed
                          </div>
                          <div className="h-1.5 bg-gray-200 rounded-full overflow-hidden">
                            <div
                              className="h-full bg-indigo-600 transition-all"
                              style={{ width: `${(completedTasks / linkedTasks.length) * 100}%` }}
                            />
                          </div>
                        </div>
                      ) : (
                        <span className="text-gray-400">No tasks</span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <SlidePanel
        isOpen={showForm || !!editingOpportunity}
        onClose={() => {
          setShowForm(false);
          setEditingOpportunity(null);
        }}
        title={editingOpportunity ? 'Edit Opportunity' : 'New Opportunity'}
      >
        <OpportunityForm
          onClose={() => {
            setShowForm(false);
            setEditingOpportunity(null);
          }}
          onSubmit={editingOpportunity ? handleEditOpportunity : handleAddOpportunity}
          onDelete={editingOpportunity ? () => handleDeleteOpportunity(editingOpportunity.id) : undefined}
          initialData={editingOpportunity}
          loading={loading}
          linkedTasks={tasks.filter(task => 
            task.relatedOpportunityId === editingOpportunity?.id
          )}
          onTaskClick={handleTaskClick}
        />
      </SlidePanel>
    </div>
  );
}