import React, { useState } from 'react';
import { X, Check, Crown, MessageSquare } from 'lucide-react';
import { PLANS } from '../lib/stripe-utils';
import ReviewForm from './ReviewForm';

type PricingOverlayProps = {
  isOpen: boolean;
  onClose: () => void;
  onUpgrade: (planId: string) => Promise<void>;
  currentPlan: string;
  billingPeriod: 'monthly' | 'yearly';
  loading?: boolean;
};

export default function PricingOverlay({ 
  isOpen, 
  onClose, 
  onUpgrade,
  currentPlan,
  billingPeriod,
  loading 
}: PricingOverlayProps) {
  const [showReviewForm, setShowReviewForm] = useState(false);

  if (!isOpen) return null;

  const plans = [
    {
      ...PLANS.student,
      highlight: currentPlan === PLANS.student.id
    },
    {
      ...PLANS.artist,
      price: billingPeriod === 'yearly' ? PLANS.artist.yearlyPrice : PLANS.artist.price,
      id: billingPeriod === 'yearly' ? PLANS.artist.yearlyId! : PLANS.artist.id,
      highlight: currentPlan === PLANS.artist.id || currentPlan === PLANS.artist.yearlyId
    },
    {
      ...PLANS.professional,
      price: billingPeriod === 'yearly' ? PLANS.professional.yearlyPrice : PLANS.professional.price,
      id: billingPeriod === 'yearly' ? PLANS.professional.yearlyId! : PLANS.professional.id,
      highlight: currentPlan === PLANS.professional.id || currentPlan === PLANS.professional.yearlyId
    }
  ];

  const handleUpgradeClick = () => {
    alert('We are still developing Studio-Planner. We are not ready to take payments yet. But in the meantime please enjoy the Free features.');
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-xl shadow-xl max-w-5xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6 border-b sticky top-0 bg-white z-10">
          <div className="flex items-center justify-between">
            <div>
              <h2 className="text-2xl font-bold text-gray-900">Choose Your Plan</h2>
              <p className="text-gray-500 mt-1">Select the perfect plan for your needs</p>
            </div>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-lg"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>

        <div className="p-6">
          {/* Development Notice */}
          <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 mb-8">
            <p className="text-blue-700">
              We are still developing Studio-Planner. We are not ready to take payments yet. 
              But in the meantime please enjoy the Free features.
            </p>
            <button
              onClick={() => setShowReviewForm(true)}
              className="inline-flex items-center gap-2 mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              <MessageSquare className="h-4 w-4" />
              Leave Review or Suggestion
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {plans.map((plan) => (
              <div
                key={plan.id}
                className={`relative rounded-xl border ${
                  plan.highlight
                    ? 'border-indigo-600 shadow-lg'
                    : 'border-gray-200'
                }`}
              >
                {plan.highlight && (
                  <div className="absolute -top-4 left-1/2 -translate-x-1/2">
                    <span className="inline-flex items-center gap-1 px-3 py-1 bg-indigo-600 text-white text-sm rounded-full">
                      <Crown className="h-4 w-4" />
                      Current Plan
                    </span>
                  </div>
                )}

                <div className="p-6">
                  <h3 className="text-xl font-semibold text-gray-900">{plan.name}</h3>
                  <div className="mt-4">
                    {plan.price === 0 ? (
                      <span className="text-4xl font-bold text-green-600">FREE</span>
                    ) : (
                      <>
                        <span className="text-4xl font-bold">£{plan.price}</span>
                        <span className="text-gray-500">/{billingPeriod === 'yearly' ? 'year' : 'month'}</span>
                      </>
                    )}
                  </div>

                  <ul className="mt-6 space-y-4">
                    {plan.features.map((feature, index) => (
                      <li key={index} className="flex items-start gap-2">
                        <Check className="h-5 w-5 text-green-500 flex-shrink-0 mt-0.5" />
                        <span className="text-gray-600">{feature}</span>
                      </li>
                    ))}
                  </ul>

                  <button
                    onClick={handleUpgradeClick}
                    disabled={plan.highlight || loading}
                    className={`w-full mt-8 px-4 py-2 rounded-lg ${
                      plan.highlight
                        ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                        : 'bg-indigo-600 text-white hover:bg-indigo-700'
                    }`}
                  >
                    {loading ? 'Processing...' : plan.highlight ? 'Current Plan' : 'Upgrade'}
                  </button>
                </div>
              </div>
            ))}
          </div>

          <p className="text-center text-sm text-gray-500 mt-8">
            All paid plans include a 14-day free trial. No credit card required.
          </p>
        </div>
      </div>

      {showReviewForm && (
        <ReviewForm onClose={() => setShowReviewForm(false)} />
      )}
    </div>
  );
}