import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Plus, Pencil, Trash2, Eye } from 'lucide-react';
import { useBlog } from '../../hooks/useBlog';
import { BlogPost } from '../../types';
import LoadingSpinner from '../../components/LoadingSpinner';
import BlogPostForm from '../../components/BlogPostForm';
import SlidePanel from '../../components/SlidePanel';
import SearchBar from '../../components/SearchBar';
import { format, isValid, parseISO } from 'date-fns';

export default function AdminBlog() {
  const { fetchPosts, addPost, updatePost, deletePost, loading } = useBlog();
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [editingPost, setEditingPost] = useState<BlogPost | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadPosts = async () => {
      try {
        const data = await fetchPosts();
        setPosts(data);
      } catch (err) {
        console.error('Error loading blog posts:', err);
        setError('Failed to load blog posts. Please try again.');
      }
    };
    loadPosts();
  }, [fetchPosts]);

  const handleAddPost = async (data: Omit<BlogPost, 'id'>) => {
    try {
      const id = await addPost(data);
      const newPost = { ...data, id } as BlogPost;
      setPosts([newPost, ...posts]);
      setShowForm(false);
    } catch (err) {
      console.error('Error adding blog post:', err);
      setError('Failed to add blog post. Please try again.');
    }
  };

  const handleEditPost = async (data: Partial<BlogPost>) => {
    if (!editingPost) return;
    
    try {
      await updatePost(editingPost.id, data);
      setPosts(prevPosts => 
        prevPosts.map(post =>
          post.id === editingPost.id ? { ...post, ...data } : post
        )
      );
      setEditingPost(null);
    } catch (err) {
      console.error('Error updating blog post:', err);
      setError('Failed to update blog post. Please try again.');
    }
  };

  const handleDeletePost = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this blog post?')) {
      return;
    }

    try {
      await deletePost(id);
      setPosts(posts.filter(post => post.id !== id));
      setEditingPost(null);
    } catch (err) {
      console.error('Error deleting blog post:', err);
      setError('Failed to delete blog post. Please try again.');
    }
  };

  const formatDate = (dateString: string) => {
    try {
      // First try parsing as ISO string
      const date = parseISO(dateString);
      if (!isValid(date)) {
        return 'Invalid date';
      }
      return format(date, 'MMM d, yyyy');
    } catch (err) {
      console.warn('Error formatting date:', err);
      return 'Invalid date';
    }
  };

  const filteredPosts = posts.filter(post =>
    post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    post.excerpt.toLowerCase().includes(searchTerm.toLowerCase()) ||
    post.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  if (loading && posts.length === 0) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>Blog Management | Studio Planner Admin</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold text-gray-900">Blog Posts</h1>
          <button
            onClick={() => setShowForm(true)}
            className="inline-flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
          >
            <Plus className="h-5 w-5" />
            New Post
          </button>
        </div>

        {error && (
          <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg">
            {error}
          </div>
        )}

        <SearchBar 
          placeholder="Search blog posts..." 
          onSearch={setSearchTerm}
        />

        <div className="bg-white rounded-lg border border-gray-200">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-50 border-b border-gray-200">
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Author</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tags</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Published</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {filteredPosts.map((post) => (
                  <tr key={post.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        <img
                          src={post.coverImage}
                          alt={post.title}
                          className="h-10 w-10 rounded object-cover mr-3"
                        />
                        <div>
                          <div className="font-medium text-gray-900">{post.title}</div>
                          <div className="text-sm text-gray-500 line-clamp-1">{post.excerpt}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        {post.author.avatar && (
                          <img
                            src={post.author.avatar}
                            alt={post.author.name}
                            className="h-6 w-6 rounded-full mr-2"
                          />
                        )}
                        <span className="text-sm text-gray-900">{post.author.name}</span>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex flex-wrap gap-1">
                        {post.tags.map((tag) => (
                          <span
                            key={tag}
                            className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800"
                          >
                            {tag}
                          </span>
                        ))}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(post.publishedAt)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => setEditingPost(post)}
                          className="p-1 hover:bg-gray-100 rounded"
                          title="Edit"
                        >
                          <Pencil className="h-4 w-4 text-gray-500" />
                        </button>
                        <a
                          href={`/blog/${post.slug}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="p-1 hover:bg-gray-100 rounded"
                          title="View"
                        >
                          <Eye className="h-4 w-4 text-gray-500" />
                        </a>
                        <button
                          onClick={() => handleDeletePost(post.id)}
                          className="p-1 hover:bg-red-100 rounded"
                          title="Delete"
                        >
                          <Trash2 className="h-4 w-4 text-red-500" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <SlidePanel
        isOpen={showForm || !!editingPost}
        onClose={() => {
          setShowForm(false);
          setEditingPost(null);
        }}
        title={editingPost ? 'Edit Blog Post' : 'New Blog Post'}
        width="lg"
      >
        <BlogPostForm
          onClose={() => {
            setShowForm(false);
            setEditingPost(null);
          }}
          onSubmit={editingPost ? handleEditPost : handleAddPost}
          onDelete={editingPost ? () => handleDeletePost(editingPost.id) : undefined}
          initialData={editingPost}
        />
      </SlidePanel>
    </>
  );
}