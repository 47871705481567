import { useState, useCallback } from 'react';
import { collection, addDoc, Timestamp, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Review, ReviewInput } from '../types';
import { reviewSchema } from '../lib/validation';

export function useReviews() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const addReview = useCallback(async (data: ReviewInput) => {
    if (!currentUser) throw new Error('User not authenticated');

    try {
      // Validate input data
      const validatedData = reviewSchema.parse(data);

      setLoading(true);
      setError(null);
      
      // Create review document with only defined fields
      const reviewData = {
        ...validatedData,
        userId: currentUser.uid,
        userName: currentUser.displayName || 'Anonymous',
        status: 'pending',
        createdAt: Timestamp.now()
      };

      // Only add userAvatar if it exists
      if (currentUser.photoURL) {
        reviewData.userAvatar = currentUser.photoURL;
      }

      const docRef = await addDoc(collection(db, 'reviews'), reviewData);
      return docRef.id;
    } catch (err) {
      const error = err instanceof Error ? err : new Error('Failed to submit review');
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  const fetchReviews = useCallback(async () => {
    if (!currentUser) return [];

    try {
      setLoading(true);
      setError(null);
      const q = query(
        collection(db, 'reviews'),
        where('userId', '==', currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const reviews = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate().toISOString()
      })) as Review[];
      return reviews;
    } catch (err) {
      const error = err instanceof Error ? err : new Error('Failed to fetch reviews');
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  return {
    addReview,
    fetchReviews,
    loading,
    error
  };
}