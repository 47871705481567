import React, { useState, useEffect } from 'react';
import { Instagram, Newspaper, Megaphone, Copy, Check, ChevronDown, ChevronUp, Trash2, Palette, BoxesIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import LoadingSpinner from '../components/LoadingSpinner';
import PromotionStep2 from '../components/PromotionStep2';
import PromotionStep3 from '../components/PromotionStep3';
import { usePromotions } from '../hooks/usePromotions';
import { useArtworks } from '../hooks/useArtworks';
import { useCollections } from '../hooks/useCollections';

export default function Promotions() {
  const { fetchPromotions, addPromotion, deletePromotion, generateContent, loading } = usePromotions();
  const { fetchArtworks } = useArtworks();
  const { fetchCollections } = useCollections();
  const [promotions, setPromotions] = useState<any[]>([]);
  const [artworks, setArtworks] = useState<Record<string, any>>({});
  const [collections, setCollections] = useState<Record<string, any>>({});
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedType, setSelectedType] = useState<'instagram' | 'blog' | 'pr'>();
  const [selectedArtwork, setSelectedArtwork] = useState<any>(null);
  const [selectedCollection, setSelectedCollection] = useState<any>(null);
  const [generatedContent, setGeneratedContent] = useState('');
  const [generating, setGenerating] = useState(false);
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const [copiedId, setCopiedId] = useState<string | null>(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [promotionsData, artworksData, collectionsData] = await Promise.all([
          fetchPromotions(),
          fetchArtworks(),
          fetchCollections()
        ]);
        setPromotions(promotionsData);

        // Create maps for linked items
        const artworksMap = artworksData.reduce((acc: Record<string, any>, artwork: any) => {
          acc[artwork.id] = artwork;
          return acc;
        }, {});
        setArtworks(artworksMap);

        const collectionsMap = collectionsData.reduce((acc: Record<string, any>, collection: any) => {
          acc[collection.id] = collection;
          return acc;
        }, {});
        setCollections(collectionsMap);
      } catch (err) {
        console.error('Error loading data:', err);
      }
    };
    loadData();
  }, [fetchPromotions, fetchArtworks, fetchCollections]);

  const handleGenerate = async (tone: 'playful' | 'informative' | 'excited' | 'artistic', followUpPrompt?: string) => {
    try {
      setGenerating(true);
      const content = await generateContent(
        selectedType!,
        tone,
        selectedArtwork,
        selectedCollection,
        followUpPrompt
      );
      setGeneratedContent(content || '');
    } catch (err) {
      console.error('Error generating content:', err);
      alert('Failed to generate content. Please try again.');
    } finally {
      setGenerating(false);
    }
  };

  const handleSave = async () => {
    try {
      const promotionData = {
        title: selectedArtwork?.title || selectedCollection?.name || 'Untitled',
        type: selectedType!,
        content: generatedContent,
        linkedArtworkId: selectedArtwork?.id || null,
        linkedCollectionId: selectedCollection?.id || null,
        status: 'draft',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      await addPromotion(promotionData);
      setCurrentStep(0);
      setSelectedType(undefined);
      setSelectedArtwork(null);
      setSelectedCollection(null);
      setGeneratedContent('');
      
      // Refresh promotions list
      const updatedPromotions = await fetchPromotions();
      setPromotions(updatedPromotions);
    } catch (err) {
      console.error('Error saving promotion:', err);
      alert('Failed to save promotion. Please try again.');
    }
  };

  const handleDelete = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this promotion?')) return;

    try {
      await deletePromotion(id);
      setPromotions(promotions.filter(p => p.id !== id));
    } catch (err) {
      console.error('Error deleting promotion:', err);
      alert('Failed to delete promotion. Please try again.');
    }
  };

  const handleCopy = async (content: string, id: string) => {
    try {
      await navigator.clipboard.writeText(content);
      setCopiedId(id);
      setTimeout(() => setCopiedId(null), 2000);
    } catch (err) {
      console.error('Failed to copy content:', err);
    }
  };

  // Show loading spinner while data is being fetched
  if (loading) {
    return <LoadingSpinner />;
  }

  // Show empty state if no artworks or collections exist
  if (Object.keys(artworks).length === 0 && Object.keys(collections).length === 0) {
    return (
      <div className="space-y-6">
        <PageHeader title="Promotions" />
        
        <div className="bg-white rounded-lg border border-gray-200 p-8">
          <div className="max-w-xl mx-auto text-center">
            <Megaphone className="h-12 w-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">No Content to Promote</h3>
            <p className="text-gray-500 mb-8">
              To start creating promotions, you'll need to add some artwork or collections first.
              This will help us generate engaging content about your work.
            </p>
            <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
              <Link
                to="/app/inventory"
                className="inline-flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 w-full sm:w-auto justify-center"
              >
                <Palette className="h-5 w-5" />
                Add Artwork
              </Link>
              <Link
                to="/app/collections"
                className="inline-flex items-center gap-2 px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50 w-full sm:w-auto justify-center"
              >
                <BoxesIcon className="h-5 w-5" />
                Create Collection
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (currentStep === 0) {
    return (
      <div className="space-y-6">
        <PageHeader title="Promotions" />

        <div className="space-y-4">
          {/* Quick Create Tiles */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
            <button
              onClick={() => {
                setSelectedType('instagram');
                setCurrentStep(2);
              }}
              className="p-6 bg-gradient-to-br from-pink-500 to-purple-600 rounded-lg text-white text-left hover:opacity-90 transition-opacity"
            >
              <Instagram className="h-8 w-8 mb-4" />
              <h3 className="text-lg font-semibold mb-2">Instagram Post</h3>
              <p className="text-sm opacity-90">Create engaging social media content</p>
            </button>

            <button
              onClick={() => {
                setSelectedType('blog');
                setCurrentStep(2);
              }}
              className="p-6 bg-gradient-to-br from-blue-500 to-cyan-600 rounded-lg text-white text-left hover:opacity-90 transition-opacity"
            >
              <Newspaper className="h-8 w-8 mb-4" />
              <h3 className="text-lg font-semibold mb-2">Blog Post</h3>
              <p className="text-sm opacity-90">Write detailed articles about your work</p>
            </button>

            <button
              onClick={() => {
                setSelectedType('pr');
                setCurrentStep(2);
              }}
              className="p-6 bg-gradient-to-br from-purple-500 to-indigo-600 rounded-lg text-white text-left hover:opacity-90 transition-opacity"
            >
              <Megaphone className="h-8 w-8 mb-4" />
              <h3 className="text-lg font-semibold mb-2">Press Release</h3>
              <p className="text-sm opacity-90">Announce news and achievements</p>
            </button>
          </div>

          {/* Promotions List */}
          {promotions.length > 0 ? (
            <div className="space-y-4">
              {promotions.map((promotion) => (
                <div
                  key={promotion.id}
                  className="bg-white rounded-lg border border-gray-200 overflow-hidden"
                >
                  <div className="p-4">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-3">
                        {promotion.type === 'instagram' ? (
                          <Instagram className="h-5 w-5 text-pink-600" />
                        ) : promotion.type === 'blog' ? (
                          <Newspaper className="h-5 w-5 text-blue-600" />
                        ) : (
                          <Megaphone className="h-5 w-5 text-purple-600" />
                        )}
                        <h3 className="font-medium">{promotion.title}</h3>
                        <span className={`px-2 py-0.5 text-xs rounded-full ${
                          promotion.status === 'published'
                            ? 'bg-green-100 text-green-700'
                            : 'bg-gray-100 text-gray-700'
                        }`}>
                          {promotion.status}
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => handleCopy(promotion.content, promotion.id)}
                          className="p-2 hover:bg-gray-100 rounded-lg"
                        >
                          {copiedId === promotion.id ? (
                            <Check className="h-5 w-5 text-green-600" />
                          ) : (
                            <Copy className="h-5 w-5 text-gray-400" />
                          )}
                        </button>
                        <button
                          onClick={() => handleDelete(promotion.id)}
                          className="p-2 hover:bg-red-50 text-red-600 rounded-lg"
                        >
                          <Trash2 className="h-5 w-5" />
                        </button>
                        <button
                          onClick={() => setExpandedId(expandedId === promotion.id ? null : promotion.id)}
                          className="p-2 hover:bg-gray-100 rounded-lg"
                        >
                          {expandedId === promotion.id ? (
                            <ChevronUp className="h-5 w-5" />
                          ) : (
                            <ChevronDown className="h-5 w-5" />
                          )}
                        </button>
                      </div>
                    </div>
                    {expandedId === promotion.id && (
                      <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                        <pre className="whitespace-pre-wrap text-sm">{promotion.content}</pre>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-12 bg-white rounded-lg border border-gray-200">
              <Megaphone className="h-12 w-12 text-gray-400 mx-auto mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No promotions found</h3>
              <p className="text-gray-500">Get started by creating your first promotion.</p>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (currentStep === 2) {
    return (
      <PromotionStep2
        type={selectedType!}
        artworks={artworks}
        collections={collections}
        onSelect={(artwork, collection) => {
          setSelectedArtwork(artwork);
          setSelectedCollection(collection);
          setCurrentStep(3);
        }}
        onBack={() => setCurrentStep(0)}
      />
    );
  }

  if (currentStep === 3) {
    return (
      <PromotionStep3
        type={selectedType!}
        artwork={selectedArtwork}
        collection={selectedCollection}
        onGenerate={handleGenerate}
        onSave={handleSave}
        onBack={() => setCurrentStep(2)}
        generatedContent={generatedContent}
        loading={generating}
      />
    );
  }

  return null;
}