import { StripeSubscription } from '../types/stripe';

export const PLANS = {
  student: {
    id: 'price_1QRFUJGrdPkCENDcnG95E7J0',
    name: 'Student/Graduate',
    productId: 'prod_RJpwYixEd0hhiI',
    price: 0,
    period: 'month',
    features: [
      '500MB Storage',
      'Up to 100 Artworks',
      'Up to 10 Collections',
      '10 AI Promotions/month'
    ]
  },
  artist: {
    id: 'price_1QRFEzGrdPkCENDcpeddEQCI',
    name: 'Artist',
    productId: 'prod_RJpxklOir4JVhy',
    price: 9,
    period: 'month',
    yearlyId: 'price_1QRFEzGrdPkCENDcpeddEQCI',
    yearlyPrice: 90,
    features: [
      '2GB Storage',
      'Up to 200 Artworks',
      'Up to 15 Collections',
      '30 AI Promotions/month'
    ]
  },
  professional: {
    id: 'price_1QRFcyGrdPkCENDcou7NGVHk',
    name: 'Professional Artist',
    productId: 'prod_RJpyz4MqGurGWw',
    price: 25,
    period: 'month',
    yearlyId: 'price_1QRFcyGrdPkCENDcou7NGVHk',
    yearlyPrice: 250,
    features: [
      '5GB Storage',
      'Up to 500 Artworks',
      'Up to 20 Collections',
      '50 AI Promotions/month'
    ]
  }
};

export const getSubscriptionStatus = (subscription: StripeSubscription | null) => {
  if (!subscription) return 'inactive';
  
  switch (subscription.status) {
    case 'active':
    case 'trialing':
      return 'active';
    case 'past_due':
      return 'past_due';
    case 'canceled':
    case 'incomplete':
    case 'incomplete_expired':
    case 'unpaid':
    default:
      return 'inactive';
  }
};

export const getCurrentPlan = (subscription: StripeSubscription | null) => {
  if (!subscription) return PLANS.student;

  const priceId = subscription.price?.id;
  if (!priceId) return PLANS.student;

  if (priceId === PLANS.professional.id || priceId === PLANS.professional.yearlyId) {
    return PLANS.professional;
  } else if (priceId === PLANS.artist.id || priceId === PLANS.artist.yearlyId) {
    return PLANS.artist;
  }

  return PLANS.student;
};