import React from 'react';
import { LucideIcon } from 'lucide-react';

type PageHeaderProps = {
  title: string;
  buttonLabel?: string;
  buttonIcon?: LucideIcon;
  onButtonClick?: () => void;
};

export default function PageHeader({ 
  title, 
  buttonLabel, 
  buttonIcon: ButtonIcon, 
  onButtonClick 
}: PageHeaderProps) {
  return (
    <div className="flex justify-between items-center">
      <h1 className="text-2xl font-bold text-gray-900">{title}</h1>
      {buttonLabel && (
        <button
          onClick={onButtonClick}
          className="inline-flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
        >
          {ButtonIcon && <ButtonIcon className="h-5 w-5" />}
          {buttonLabel}
        </button>
      )}
    </div>
  );
}