import React from 'react';
import { getMeasurementFields, getMeasurementLabel } from '../lib/artwork-types';

type MeasurementsFormProps = {
  type: string;
  measurements: Record<string, number>;
  onChange: (field: string, value: number) => void;
  className?: string;
};

export default function MeasurementsForm({ 
  type, 
  measurements, 
  onChange,
  className = ''
}: MeasurementsFormProps) {
  // Get the actual type without category prefix
  const actualType = type.split(' - ')[1];
  const fields = getMeasurementFields(actualType);

  if (fields.length === 0) {
    return null;
  }

  return (
    <div className={`grid grid-cols-2 md:grid-cols-3 gap-4 ${className}`}>
      {fields.map(field => (
        <div key={field}>
          <label className="block text-xs text-gray-500 mb-1">
            {getMeasurementLabel(actualType, field)}
          </label>
          <input
            type="number"
            value={measurements[field] || ''}
            onChange={(e) => onChange(field, Number(e.target.value))}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
            step="0.1"
            min="0"
          />
        </div>
      ))}
    </div>
  );
}