import React from 'react';
import { Search, Filter } from 'lucide-react';

type SearchBarProps = {
  placeholder?: string;
  onSearch?: (value: string) => void;
  onFilterClick?: () => void;
  activeFilters?: number;
  showFilter?: boolean;
};

export default function SearchBar({ 
  placeholder = "Search...", 
  onSearch,
  onFilterClick,
  activeFilters = 0,
  showFilter = false
}: SearchBarProps) {
  return (
    <div className="flex gap-4">
      <div className="flex-1 relative">
        <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
        <input
          type="text"
          placeholder={placeholder}
          onChange={(e) => onSearch?.(e.target.value)}
          className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
        />
      </div>
      {showFilter && onFilterClick && (
        <button 
          onClick={onFilterClick}
          className="inline-flex items-center gap-2 px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors relative"
        >
          <Filter className="h-5 w-5" />
          Filter
          {activeFilters > 0 && (
            <span className="absolute -top-2 -right-2 w-5 h-5 bg-indigo-600 text-white text-xs rounded-full flex items-center justify-center">
              {activeFilters}
            </span>
          )}
        </button>
      )}
    </div>
  );
}