import React, { useState, useEffect } from 'react';
import { Plus, ArrowDownAZ, Calendar, CheckCircle2, Clock } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import SearchBar from '../components/SearchBar';
import TaskList from '../components/TaskList';
import TaskCalendar from '../components/TaskCalendar';
import TaskDashboard from '../components/TaskDashboard';
import SlidePanel from '../components/SlidePanel';
import TaskForm from '../components/TaskForm';
import { Task } from '../types';
import { useTasks } from '../hooks/useTasks';
import LoadingSpinner from '../components/LoadingSpinner';
import StatsCard from '../components/StatsCard';

type SortOption = 'recentToOld' | 'oldToRecent' | 'upcoming';

export default function Tasks() {
  const { fetchTasks, addTask, updateTask, deleteTask, loading: tasksLoading } = useTasks();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [view, setView] = useState<'list' | 'calendar'>('list');
  const [searchTerm, setSearchTerm] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [editingTask, setEditingTask] = useState<Task | null>(null);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState<SortOption>('recentToOld');
  const [showSortMenu, setShowSortMenu] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const loadTasks = async () => {
      try {
        const data = await fetchTasks();
        setTasks(data);

        // Check if we need to open a specific task
        const state = location.state as { selectedTaskId?: string; showForm?: boolean };
        if (state?.selectedTaskId) {
          const task = data.find(t => t.id === state.selectedTaskId);
          if (task) {
            setEditingTask(task);
          }
        }
        if (state?.showForm) {
          setShowForm(true);
        }
      } catch (err) {
        console.error('Error loading tasks:', err);
      }
    };
    loadTasks();
  }, [fetchTasks, location]);

  const handleAddTask = async (data: Omit<Task, 'id'>) => {
    try {
      setLoading(true);
      const id = await addTask(data);
      const newTask = { ...data, id } as Task;
      setTasks(prevTasks => {
        const updatedTasks = [...prevTasks, newTask];
        return sortTasks(updatedTasks, sortBy);
      });
      setShowForm(false);
    } catch (err) {
      console.error('Error adding task:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleEditTask = async (data: Partial<Task>) => {
    if (!editingTask) return;
    
    try {
      setLoading(true);
      await updateTask(editingTask.id, data);
      setTasks(prevTasks => {
        const updatedTasks = prevTasks.map(task =>
          task.id === editingTask.id ? { ...task, ...data } : task
        );
        return sortTasks(updatedTasks, sortBy);
      });
      setEditingTask(null);
    } catch (err) {
      console.error('Error updating task:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteTask = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this task?')) {
      return;
    }

    try {
      setLoading(true);
      await deleteTask(id);
      setTasks(prevTasks => {
        const updatedTasks = prevTasks.filter(task => task.id !== id);
        return sortTasks(updatedTasks, sortBy);
      });
      setEditingTask(null);
    } catch (err) {
      console.error('Error deleting task:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async (taskId: string, status: Task['status']) => {
    try {
      await updateTask(taskId, { status });
      setTasks(prevTasks => {
        const updatedTasks = prevTasks.map(task =>
          task.id === taskId ? { ...task, status } : task
        );
        return sortTasks(updatedTasks, sortBy);
      });
    } catch (err) {
      console.error('Error updating task status:', err);
    }
  };

  const handleCalendarToggle = async (taskId: string, showInCalendar: boolean) => {
    try {
      await updateTask(taskId, { showInCalendar });
      setTasks(prevTasks => 
        prevTasks.map(task =>
          task.id === taskId ? { ...task, showInCalendar } : task
        )
      );
    } catch (err) {
      console.error('Error updating task calendar status:', err);
    }
  };

  const sortTasks = (tasksToSort: Task[], sortOption: SortOption) => {
    return [...tasksToSort].sort((a, b) => {
      switch (sortOption) {
        case 'recentToOld':
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        case 'oldToRecent':
          return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        case 'upcoming':
          if (!a.dueDate && !b.dueDate) return 0;
          if (!a.dueDate) return 1;
          if (!b.dueDate) return -1;
          return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
        default:
          return 0;
      }
    });
  };

  const handleSortChange = (newSort: SortOption) => {
    setSortBy(newSort);
    setTasks(prevTasks => sortTasks(prevTasks, newSort));
    setShowSortMenu(false);
  };

  // Filter tasks based on search term and completion status
  const filteredTasks = tasks
    .filter(task => 
      (showCompleted || task.status !== 'completed') &&
      (task.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
       task.description?.toLowerCase().includes(searchTerm.toLowerCase()))
    );

  if (tasksLoading) {
    return <LoadingSpinner />;
  }

  // Calculate task statistics
  const totalTasks = tasks.length;
  const completedTasks = tasks.filter(t => t.status === 'completed').length;
  const pendingTasks = tasks.filter(t => t.status === 'todo').length;
  const overdueTasks = tasks.filter(t => {
    if (!t.dueDate || t.status === 'completed') return false;
    return new Date(t.dueDate) < new Date();
  }).length;

  const stats = [
    {
      label: 'Total Tasks',
      value: totalTasks,
      icon: Calendar,
      color: 'bg-blue-100 text-blue-600'
    },
    {
      label: 'Completed',
      value: completedTasks,
      icon: CheckCircle2,
      color: 'bg-green-100 text-green-600',
      change: totalTasks ? `${Math.round((completedTasks / totalTasks) * 100)}%` : 'N/A'
    },
    {
      label: 'Overdue',
      value: overdueTasks,
      icon: Clock,
      color: 'bg-red-100 text-red-600'
    }
  ];

  // Show empty state if no tasks exist
  if (tasks.length === 0) {
    return (
      <div className="space-y-6">
        <PageHeader 
          title="Tasks" 
          buttonLabel="Add Task"
          buttonIcon={Plus}
          onButtonClick={() => setShowForm(true)}
        />

        {/* Stats Cards - Show zero state */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {stats.map((stat) => (
            <StatsCard key={stat.label} {...stat} />
          ))}
        </div>

        <div className="bg-white rounded-lg border border-gray-200 p-8">
          <div className="max-w-xl mx-auto text-center">
            <Calendar className="h-12 w-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">No tasks yet</h3>
            <p className="text-gray-500 mb-6">
              Start organizing your work by creating tasks. Track deadlines, set reminders,
              and manage your art business efficiently.
            </p>
            <button
              onClick={() => setShowForm(true)}
              className="inline-flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
            >
              <Plus className="h-5 w-5" />
              Create Your First Task
            </button>
          </div>
        </div>

        <SlidePanel
          isOpen={showForm}
          onClose={() => setShowForm(false)}
          title="New Task"
        >
          <TaskForm
            onClose={() => setShowForm(false)}
            onSubmit={handleAddTask}
            loading={loading}
          />
        </SlidePanel>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <PageHeader 
        title="Tasks" 
        buttonLabel="Add Task"
        buttonIcon={Plus}
        onButtonClick={() => setShowForm(true)}
      />

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {stats.map((stat) => (
          <StatsCard key={stat.label} {...stat} />
        ))}
      </div>

      <div className="flex items-center justify-between gap-4">
        <div className="flex-1">
          <SearchBar 
            placeholder="Search tasks..." 
            onSearch={setSearchTerm}
            showFilter={false}
          />
        </div>
        <div className="flex items-center gap-4">
          <div className="relative">
            <button
              onClick={() => setShowSortMenu(!showSortMenu)}
              className="flex items-center gap-2 px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              <ArrowDownAZ className="h-4 w-4" />
              Sort by {
                sortBy === 'recentToOld' ? 'Recent to Oldest' :
                sortBy === 'oldToRecent' ? 'Oldest to Recent' :
                'Upcoming First'
              }
            </button>
            {showSortMenu && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 py-1 z-10">
                <button
                  onClick={() => handleSortChange('recentToOld')}
                  className={`w-full px-4 py-2 text-left hover:bg-gray-50 ${
                    sortBy === 'recentToOld' ? 'text-indigo-600' : 'text-gray-700'
                  }`}
                >
                  Recent to Oldest
                </button>
                <button
                  onClick={() => handleSortChange('oldToRecent')}
                  className={`w-full px-4 py-2 text-left hover:bg-gray-50 ${
                    sortBy === 'oldToRecent' ? 'text-indigo-600' : 'text-gray-700'
                  }`}
                >
                  Oldest to Recent
                </button>
                <button
                  onClick={() => handleSortChange('upcoming')}
                  className={`w-full px-4 py-2 text-left hover:bg-gray-50 ${
                    sortBy === 'upcoming' ? 'text-indigo-600' : 'text-gray-700'
                  }`}
                >
                  Upcoming First
                </button>
              </div>
            )}
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={() => setView('list')}
              className={`p-2 rounded-lg ${
                view === 'list'
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'text-gray-500 hover:bg-gray-100'
              }`}
            >
              List
            </button>
            <button
              onClick={() => setView('calendar')}
              className={`p-2 rounded-lg ${
                view === 'calendar'
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'text-gray-500 hover:bg-gray-100'
              }`}
            >
              Calendar
            </button>
          </div>
        </div>
      </div>

      {view === 'list' ? (
        <TaskList
          tasks={filteredTasks}
          onTaskClick={setEditingTask}
          onStatusChange={handleStatusChange}
          onTaskUpdate={handleCalendarToggle}
          showCompleted={showCompleted}
          onToggleShowCompleted={() => setShowCompleted(!showCompleted)}
        />
      ) : (
        <TaskCalendar
          tasks={tasks}
          onTaskClick={setEditingTask}
          onTaskAdd={handleAddTask}
          onTaskUpdate={handleEditTask}
        />
      )}

      <SlidePanel
        isOpen={showForm || !!editingTask}
        onClose={() => {
          setShowForm(false);
          setEditingTask(null);
        }}
        title={editingTask ? 'Edit Task' : 'New Task'}
      >
        <TaskForm
          onClose={() => {
            setShowForm(false);
            setEditingTask(null);
          }}
          onSubmit={editingTask ? handleEditTask : handleAddTask}
          onDelete={editingTask ? () => handleDeleteTask(editingTask.id) : undefined}
          initialData={editingTask}
          loading={loading}
        />
      </SlidePanel>
    </div>
  );
}