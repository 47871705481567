import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useBlog } from '../hooks/useBlog';
import { BlogPost as BlogPostType } from '../types';
import LoadingSpinner from '../components/LoadingSpinner';
import BlogPostHero from '../components/blog/BlogPostHero';
import BlogPostContent from '../components/blog/BlogPostContent';
import ErrorMessage from '../components/ErrorMessage';

export default function BlogPost() {
  const { slug } = useParams<{ slug: string }>();
  const { fetchPostBySlug, loading } = useBlog();
  const [post, setPost] = useState<BlogPostType | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const loadPost = async () => {
      if (!slug) {
        setNotFound(true);
        return;
      }

      try {
        const data = await fetchPostBySlug(slug);
        if (!data) {
          setNotFound(true);
          return;
        }
        setPost(data);
      } catch (err: any) {
        console.error('Error loading blog post:', err);
        if (err.message === 'Post not found') {
          setNotFound(true);
        } else {
          setError('Failed to load blog post');
        }
      }
    };
    loadPost();
  }, [fetchPostBySlug, slug]);

  if (notFound) {
    return <Navigate to="/blog" replace />;
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error || !post) {
    return <ErrorMessage message={error || 'Content not available'} />;
  }

  return (
    <>
      <Helmet>
        <title>{post.seo.title}</title>
        <meta name="description" content={post.seo.description} />
        <meta name="keywords" content={post.seo.keywords.join(', ')} />
        {post.seo.ogImage && <meta property="og:image" content={post.seo.ogImage} />}
        <meta property="og:title" content={post.seo.title} />
        <meta property="og:description" content={post.seo.description} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.seo.title} />
        <meta name="twitter:description" content={post.seo.description} />
        {post.seo.ogImage && <meta name="twitter:image" content={post.seo.ogImage} />}
        <link rel="canonical" href={`https://studio-planner.com/blog/${post.slug}`} />
      </Helmet>

      <article className="py-12">
        <BlogPostHero
          title={post.title}
          excerpt={post.excerpt}
          coverImage={post.coverImage}
          author={post.author}
          publishedAt={post.publishedAt}
        />
        <BlogPostContent
          content={post.content}
          tags={post.tags}
          author={post.author}
        />
      </article>
    </>
  );
}