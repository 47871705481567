import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Collection, GalleryImage } from '../types';
import { Edit2, Image, Plus } from 'lucide-react';
import { useProfile } from '../contexts/ProfileContext';
import { useGallery } from '../hooks/useGallery';
import { useState, useEffect } from 'react';

type CollectionDetailsProps = {
  collection: Collection;
  artworks: Record<string, any>;
  onEdit: () => void;
};

export default function CollectionDetails({ collection, artworks, onEdit }: CollectionDetailsProps) {
  const navigate = useNavigate();
  const { profile } = useProfile();
  const { fetchImages } = useGallery();
  const [linkedImages, setLinkedImages] = useState<GalleryImage[]>([]);

  useEffect(() => {
    const loadLinkedImages = async () => {
      try {
        const allImages = await fetchImages();
        const images = allImages.filter(img => img.linkedCollectionId === collection.id);
        setLinkedImages(images);
      } catch (err) {
        console.error('Error loading linked images:', err);
      }
    };
    loadLinkedImages();
  }, [collection.id, fetchImages]);

  // Ensure pieces is always an array
  const pieces = collection.pieces || [];
  
  // Filter out any invalid artwork IDs
  const collectionPieces = pieces
    .map(id => artworks[id])
    .filter(Boolean);

  const totalValue = collectionPieces.reduce((sum, art) => sum + (art.price || 0), 0);

  const handleArtworkClick = (artworkId: string) => {
    navigate(`/app/inventory/${artworkId}`);
  };

  const handleImageClick = (imageId: string) => {
    navigate('/app/gallery', { state: { selectedImageId: imageId } });
  };

  return (
    <div className="space-y-6">
      <div className="flex items-start justify-between">
        <div>
          <h2 className="text-2xl font-bold text-gray-900">{collection.name}</h2>
          <p className="text-gray-500 mt-1">{collection.description}</p>
        </div>
        <button
          onClick={onEdit}
          className="inline-flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
        >
          <Edit2 className="h-4 w-4" />
          Edit
        </button>
      </div>

      <div className="aspect-[3/2] bg-gray-100 rounded-lg overflow-hidden">
        {collection.coverImage ? (
          <img
            src={collection.coverImage}
            alt={collection.name}
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <Image className="h-12 w-12 text-gray-400" />
          </div>
        )}
      </div>

      <div className="grid grid-cols-2 gap-6">
        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-4">Details</h3>
          <dl className="space-y-4">
            <div>
              <dt className="text-sm font-medium text-gray-500">Total Value</dt>
              <dd className="text-2xl font-bold text-gray-900 mt-1">
                {profile.currency.symbol}{totalValue.toLocaleString()}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Number of Pieces</dt>
              <dd className="text-lg text-gray-900 mt-1">
                {pieces.length} pieces
              </dd>
            </div>
          </dl>
        </div>

        {collection.artistStatement && (
          <div>
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Artist Statement</h3>
            <p className="text-gray-600 whitespace-pre-wrap">{collection.artistStatement}</p>
          </div>
        )}
      </div>

      {/* Gallery Images */}
      {linkedImages.length > 0 && (
        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-4">Gallery Images</h3>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {linkedImages.map((image) => (
              <div
                key={image.id}
                onClick={() => handleImageClick(image.id)}
                className="aspect-square bg-gray-100 rounded-lg overflow-hidden cursor-pointer group relative"
              >
                {image.url.includes('.mp4') || image.url.includes('.webm') ? (
                  <video
                    src={image.url}
                    className="w-full h-full object-cover"
                    muted
                    loop
                    onMouseOver={e => (e.target as HTMLVideoElement).play()}
                    onMouseOut={e => (e.target as HTMLVideoElement).pause()}
                  />
                ) : (
                  <img
                    src={image.url}
                    alt={image.title || 'Gallery image'}
                    className="w-full h-full object-cover group-hover:opacity-75 transition-opacity"
                  />
                )}
                {image.title && (
                  <div className="absolute bottom-0 left-0 right-0 p-2 bg-gradient-to-t from-black/50 to-transparent">
                    <p className="text-sm text-white truncate">{image.title}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      <div>
        <h3 className="text-lg font-semibold text-gray-900 mb-4">Pieces in Collection</h3>
        {collectionPieces.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {collectionPieces.map((piece) => (
              <div
                key={piece.id}
                onClick={() => handleArtworkClick(piece.id)}
                className="bg-white rounded-lg border border-gray-200 overflow-hidden hover:shadow-md transition-shadow cursor-pointer"
              >
                <div className="flex items-center p-4">
                  <div className="h-16 w-16 flex-shrink-0">
                    {piece.images?.[0] ? (
                      <img
                        src={piece.images[0]}
                        alt={piece.title}
                        className="h-16 w-16 object-cover rounded-lg"
                      />
                    ) : (
                      <div className="h-16 w-16 bg-gray-100 rounded-lg flex items-center justify-center">
                        <Image className="h-6 w-6 text-gray-400" />
                      </div>
                    )}
                  </div>
                  <div className="ml-4">
                    <h4 className="text-sm font-medium text-gray-900">{piece.title}</h4>
                    <p className="text-sm text-gray-500">{piece.materials?.join(', ') || 'No materials listed'}</p>
                    <p className="text-sm font-medium text-gray-900 mt-1">
                      {profile.currency.symbol}{(piece.price || 0).toLocaleString()}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-500">No pieces in this collection yet.</p>
        )}
      </div>
    </div>
  );
}