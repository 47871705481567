import { useState, useCallback, useEffect } from 'react';
import { doc, setDoc, getDoc, Timestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Artist } from '../types';

export function useArtist() {
  const { currentUser } = useAuth();
  const [artist, setArtist] = useState<Artist | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchArtist = useCallback(async () => {
    if (!currentUser) return;

    try {
      setLoading(true);
      setError(null);
      const docRef = doc(db, 'artists', currentUser.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setArtist({ id: docSnap.id, ...docSnap.data() } as Artist);
      }
      setLoading(false);
    } catch (err) {
      setError(err as Error);
      setLoading(false);
    }
  }, [currentUser]);

  const createArtist = useCallback(async (data: Omit<Artist, 'id' | 'createdAt' | 'updatedAt'>) => {
    if (!currentUser) throw new Error('User not authenticated');

    try {
      setLoading(true);
      setError(null);
      const now = Timestamp.now();
      const artistData = {
        ...data,
        createdAt: now,
        updatedAt: now,
      };

      await setDoc(doc(db, 'artists', currentUser.uid), artistData);
      
      setArtist({ id: currentUser.uid, ...artistData } as Artist);
      setLoading(false);
    } catch (err) {
      setError(err as Error);
      setLoading(false);
      throw err;
    }
  }, [currentUser]);

  const updateArtist = useCallback(async (data: Partial<Artist>) => {
    if (!currentUser) throw new Error('User not authenticated');

    try {
      setLoading(true);
      setError(null);
      const docRef = doc(db, 'artists', currentUser.uid);
      await setDoc(docRef, {
        ...data,
        updatedAt: Timestamp.now(),
      }, { merge: true });

      if (artist) {
        setArtist({ ...artist, ...data });
      }
      setLoading(false);
    } catch (err) {
      setError(err as Error);
      setLoading(false);
      throw err;
    }
  }, [currentUser, artist]);

  useEffect(() => {
    fetchArtist();
  }, [fetchArtist]);

  return {
    artist,
    loading,
    error,
    createArtist,
    updateArtist
  };
}