import React, { useState, useEffect } from 'react';
import { Plus, Tag, Link as LinkIcon, Image } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import SearchBar from '../components/SearchBar';
import ViewToggle from '../components/ViewToggle';
import GalleryFilterMenu from '../components/GalleryFilterMenu';
import GalleryForm from '../components/GalleryForm';
import MultiFileUpload from '../components/MultiFileUpload';
import Pagination from '../components/Pagination';
import { useGallery } from '../hooks/useGallery';
import { GalleryImage } from '../types';
import LoadingSpinner from '../components/LoadingSpinner';

const ITEMS_PER_PAGE = 12;

// Helper function to truncate text from the end
function truncateText(text: string, maxLength: number = 30): string {
  if (!text) return '';
  if (text.length <= maxLength) return text;
  return '...' + text.slice(-maxLength);
}

export default function Gallery() {
  const location = useLocation();
  const { fetchImages, addImage, updateImage, deleteImage, loading } = useGallery();
  const [images, setImages] = useState<GalleryImage[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [editingImage, setEditingImage] = useState<GalleryImage | null>(null);
  const [view, setView] = useState<'grid' | 'list'>('grid');
  const [showFilters, setShowFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    tags: [],
    linkedTypes: [],
    dateRange: { start: null, end: null }
  });

  useEffect(() => {
    const loadImages = async () => {
      try {
        const data = await fetchImages();
        setImages(data);

        // Handle direct navigation to image
        const state = location.state as { selectedImageId?: string };
        if (state?.selectedImageId) {
          const image = data.find(img => img.id === state.selectedImageId);
          if (image) {
            setEditingImage(image);
          }
        }
      } catch (err) {
        console.error('Error loading images:', err);
      }
    };
    loadImages();
  }, [fetchImages, location]);

  const handleAddImages = async (urls: string[]) => {
    if (urls.length === 0) return;

    try {
      const newImages = await Promise.all(urls.map(url => {
        const filename = url.split('/').pop()?.split('?')[0] || 'Untitled';
        const title = filename.replace(/\.[^/.]+$/, '').replace(/[-_]/g, ' ');
        
        return addImage({
          url,
          title: title.charAt(0).toUpperCase() + title.slice(1),
          description: '',
          tags: [],
          linkedArtworkId: undefined,
          linkedCollectionId: undefined
        });
      }));

      const validNewImages = newImages.filter((id): id is string => id !== undefined);
      if (validNewImages.length > 0) {
        const updatedImages = await fetchImages();
        setImages(updatedImages);
        setShowForm(false);
      }
    } catch (err) {
      console.error('Error adding images:', err);
    }
  };

  const handleUpdateImage = async (data: Partial<GalleryImage>) => {
    if (!editingImage) return;

    try {
      await updateImage(editingImage.id, data);
      setImages(prev => prev.map(img => 
        img.id === editingImage.id ? { ...img, ...data } : img
      ));
      setEditingImage(null);
    } catch (err) {
      console.error('Error updating image:', err);
    }
  };

  const handleDeleteImage = async (id: string) => {
    try {
      await deleteImage(id);
      setImages(prev => prev.filter(img => img.id !== id));
      setEditingImage(null);
    } catch (err) {
      console.error('Error deleting image:', err);
    }
  };

  // Get all unique tags for filter menu
  const allTags = Array.from(new Set(images.flatMap(img => img.tags || [])));

  // Filter images based on search and filters
  const filteredImages = images.filter(image => {
    const matchesSearch = 
      (image.title?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
      (image.description?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
      (image.tags || []).some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()));

    const matchesTags = filters.tags.length === 0 || 
      (image.tags || []).some(tag => filters.tags.includes(tag));

    const matchesLinkedTypes = filters.linkedTypes.length === 0 ||
      (filters.linkedTypes.includes('artwork') && image.linkedArtworkId) ||
      (filters.linkedTypes.includes('collection') && image.linkedCollectionId);

    const matchesDateRange = 
      (!filters.dateRange.start || new Date(image.createdAt) >= new Date(filters.dateRange.start)) &&
      (!filters.dateRange.end || new Date(image.createdAt) <= new Date(filters.dateRange.end));

    return matchesSearch && matchesTags && matchesLinkedTypes && matchesDateRange;
  });

  const totalPages = Math.ceil(filteredImages.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const paginatedImages = filteredImages.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  if (loading && images.length === 0) {
    return <LoadingSpinner />;
  }

  if (images.length === 0) {
    return (
      <div className="space-y-6">
        <PageHeader 
          title="Photos"
          buttonLabel="Add Photos"
          buttonIcon={Plus}
          onButtonClick={() => setShowForm(true)}
        />
        
        <div className="bg-white rounded-lg border border-gray-200 p-8">
          <div className="max-w-xl mx-auto">
            <div className="text-center mb-8">
              <Image className="h-12 w-12 text-gray-400 mx-auto mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No photos yet</h3>
              <p className="text-gray-500 mb-6">
                Get started by uploading photos and videos of your artwork. You can drag and drop files or click to browse.
              </p>
            </div>
            
            <MultiFileUpload
              folder="gallery"
              onFilesUpload={handleAddImages}
              allowVideo={true}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <PageHeader 
        title="Photos" 
        buttonLabel="Add Photos"
        buttonIcon={Plus}
        onButtonClick={() => setShowForm(true)}
      />

      <div className="flex items-center justify-between gap-4">
        <div className="flex-1">
          <SearchBar 
            placeholder="Search photos..." 
            onSearch={setSearchTerm}
            onFilterClick={() => setShowFilters(true)}
            activeFilters={filters.tags.length + filters.linkedTypes.length + (filters.dateRange.start || filters.dateRange.end ? 1 : 0)}
            showFilter={true}
          />
        </div>
        <ViewToggle 
          view={view} 
          onViewChange={setView}
        />
      </div>

      {view === 'grid' ? (
        <div className="space-y-6">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {paginatedImages.map((image) => (
              <div
                key={image.id}
                onClick={() => setEditingImage(image)}
                className="aspect-square bg-gray-100 rounded-lg overflow-hidden cursor-pointer group relative"
              >
                {image.url.includes('.mp4') || image.url.includes('.webm') ? (
                  <video
                    src={image.url}
                    className="w-full h-full object-cover"
                    muted
                    loop
                    onMouseOver={e => (e.target as HTMLVideoElement).play()}
                    onMouseOut={e => (e.target as HTMLVideoElement).pause()}
                  />
                ) : (
                  <img
                    src={image.url}
                    alt={image.title || 'Gallery image'}
                    className="w-full h-full object-cover group-hover:opacity-75 transition-opacity"
                  />
                )}
                {(image.linkedArtworkId || image.linkedCollectionId || (image.tags && image.tags.length > 0)) && (
                  <div className="absolute bottom-0 left-0 right-0 p-2 bg-gradient-to-t from-black/50 to-transparent">
                    <div className="flex flex-wrap gap-1">
                      {image.linkedArtworkId && (
                        <span className="inline-flex items-center gap-1 px-2 py-1 bg-black/30 backdrop-blur-sm rounded-full text-white text-xs">
                          <LinkIcon className="h-3 w-3" />
                          Artwork
                        </span>
                      )}
                      {image.linkedCollectionId && (
                        <span className="inline-flex items-center gap-1 px-2 py-1 bg-black/30 backdrop-blur-sm rounded-full text-white text-xs">
                          <LinkIcon className="h-3 w-3" />
                          Collection
                        </span>
                      )}
                      {image.tags?.slice(0, 2).map((tag, index) => (
                        <span
                          key={index}
                          className="inline-flex items-center gap-1 px-2 py-1 bg-black/30 backdrop-blur-sm rounded-full text-white text-xs"
                        >
                          <Tag className="h-3 w-3" />
                          {tag}
                        </span>
                      ))}
                      {image.tags && image.tags.length > 2 && (
                        <span className="inline-flex items-center px-2 py-1 bg-black/30 backdrop-blur-sm rounded-full text-white text-xs">
                          +{image.tags.length - 2}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>

          {filteredImages.length > ITEMS_PER_PAGE && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
              totalItems={filteredImages.length}
              itemsPerPage={ITEMS_PER_PAGE}
            />
          )}
        </div>
      ) : (
        <div className="bg-white rounded-lg border border-gray-200">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-50 border-b border-gray-200">
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Photo</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Links</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tags</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {paginatedImages.map((image) => (
                  <tr 
                    key={image.id}
                    onClick={() => setEditingImage(image)}
                    className="hover:bg-gray-50 cursor-pointer"
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      {image.url.includes('.mp4') || image.url.includes('.webm') ? (
                        <video
                          src={image.url}
                          className="h-16 w-16 object-cover rounded-lg"
                          muted
                        />
                      ) : (
                        <img
                          src={image.url}
                          alt={image.title || 'Gallery image'}
                          className="h-16 w-16 object-cover rounded-lg"
                        />
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div 
                        className="text-sm font-medium text-gray-900"
                        title={image.title} // Show full title on hover
                      >
                        {truncateText(image.title, 30)}
                      </div>
                      {image.description && (
                        <div className="text-sm text-gray-500 line-clamp-1">{image.description}</div>
                      )}
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex flex-wrap gap-1">
                        {image.linkedArtworkId && (
                          <span className="inline-flex items-center gap-1 px-2 py-1 bg-blue-100 text-blue-700 rounded-full text-xs">
                            Artwork
                          </span>
                        )}
                        {image.linkedCollectionId && (
                          <span className="inline-flex items-center gap-1 px-2 py-1 bg-purple-100 text-purple-700 rounded-full text-xs">
                            Collection
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex flex-wrap gap-1">
                        {image.tags?.map((tag, index) => (
                          <span
                            key={index}
                            className="inline-flex items-center gap-1 px-2 py-1 bg-gray-100 rounded-full text-xs"
                          >
                            {tag}
                          </span>
                        ))}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {new Date(image.createdAt).toLocaleDateString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {filteredImages.length > ITEMS_PER_PAGE && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
              totalItems={filteredImages.length}
              itemsPerPage={ITEMS_PER_PAGE}
            />
          )}
        </div>
      )}

      <GalleryFilterMenu
        isOpen={showFilters}
        onClose={() => setShowFilters(false)}
        filters={filters}
        onChange={setFilters}
        tags={allTags}
      />

      {showForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-6">
          <div className="bg-white rounded-lg w-full max-w-xl">
            <div className="p-6">
              <MultiFileUpload
                folder="gallery"
                onFilesUpload={handleAddImages}
                allowVideo={true}
              />
            </div>
          </div>
        </div>
      )}

      {editingImage && (
        <GalleryForm
          initialData={editingImage}
          onClose={() => setEditingImage(null)}
          onSubmit={handleUpdateImage}
          onDelete={handleDeleteImage}
        />
      )}
    </div>
  );
}