import React, { useState } from 'react';
import { ArrowLeft, Instagram, Newspaper, Megaphone, Sparkles, Loader } from 'lucide-react';
import { useProfile } from '../contexts/ProfileContext';

type PromotionStep3Props = {
  type: 'instagram' | 'blog' | 'pr';
  artwork: any;
  collection: any;
  onGenerate: (tone: 'playful' | 'informative' | 'excited' | 'artistic', followUpPrompt?: string) => void;
  onSave: () => void;
  onBack: () => void;
  generatedContent: string;
  loading: boolean;
};

export default function PromotionStep3({
  type,
  artwork,
  collection,
  onGenerate,
  onSave,
  onBack,
  generatedContent,
  loading
}: PromotionStep3Props) {
  const { profile } = useProfile();
  const [selectedTone, setSelectedTone] = useState<'playful' | 'informative' | 'excited' | 'artistic'>('informative');
  const [followUpPrompt, setFollowUpPrompt] = useState('');
  const [additionalRequirements, setAdditionalRequirements] = useState('');

  const tones = [
    { id: 'playful', label: 'Playful', description: 'Light-hearted and fun' },
    { id: 'informative', label: 'Informative', description: 'Professional and detailed' },
    { id: 'excited', label: 'Excited', description: 'Enthusiastic and energetic' },
    { id: 'artistic', label: 'Artistic', description: 'Poetic and creative' }
  ];

  const TypeIcon = type === 'instagram' ? Instagram : type === 'blog' ? Newspaper : Megaphone;

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-4">
        <button
          onClick={onBack}
          className="p-2 hover:bg-gray-100 rounded-lg"
        >
          <ArrowLeft className="h-5 w-5" />
        </button>
        <h2 className="text-2xl font-bold">Generate Content</h2>
      </div>

      <div className="grid grid-cols-2 gap-6">
        <div className="space-y-6">
          <div className="bg-white rounded-lg border p-6 space-y-4">
            <div className="flex items-center gap-3">
              <TypeIcon className={`h-6 w-6 ${
                type === 'instagram' ? 'text-pink-600' :
                type === 'blog' ? 'text-blue-600' :
                'text-purple-600'
              }`} />
              <h3 className="text-lg font-semibold">
                {type === 'instagram' ? 'Instagram Post' :
                 type === 'blog' ? 'Blog Post' :
                 'Press Release'}
              </h3>
            </div>

            {artwork ? (
              <>
                <div className="aspect-square bg-gray-100 rounded-lg overflow-hidden">
                  {artwork.images?.[0] && (
                    <img
                      src={artwork.images[0]}
                      alt={artwork.title}
                      className="w-full h-full object-cover"
                    />
                  )}
                </div>
                <div>
                  <h4 className="font-medium">{artwork.title}</h4>
                  <p className="text-sm text-gray-500">{artwork.description}</p>
                  {artwork.measurements && Object.keys(artwork.measurements).length > 0 && (
                    <p className="text-sm text-gray-500 mt-2">
                      {Object.entries(artwork.measurements)
                        .filter(([_, value]) => value)
                        .map(([key, value]) => `${key}: ${value}`)
                        .join(', ')}
                    </p>
                  )}
                  <p className="text-lg font-bold mt-2">
                    {profile.currency.symbol}{artwork.price?.toLocaleString()}
                  </p>
                </div>
              </>
            ) : collection && (
              <>
                <div className="aspect-video bg-gray-100 rounded-lg overflow-hidden">
                  {collection.coverImage && (
                    <img
                      src={collection.coverImage}
                      alt={collection.name}
                      className="w-full h-full object-cover"
                    />
                  )}
                </div>
                <div>
                  <h4 className="font-medium">{collection.name}</h4>
                  <p className="text-sm text-gray-500">{collection.description}</p>
                  {collection.artistStatement && (
                    <p className="text-sm text-gray-500 mt-2">{collection.artistStatement}</p>
                  )}
                </div>
              </>
            )}
          </div>

          <div className="bg-white rounded-lg border p-6 space-y-4">
            <h3 className="text-lg font-semibold">Tone of Voice</h3>
            <div className="grid grid-cols-2 gap-3">
              {tones.map(tone => (
                <button
                  key={tone.id}
                  onClick={() => setSelectedTone(tone.id as any)}
                  className={`p-3 border rounded-lg text-left transition-colors ${
                    selectedTone === tone.id
                      ? 'border-indigo-600 bg-indigo-50'
                      : 'hover:bg-gray-50'
                  }`}
                >
                  <div className="font-medium">{tone.label}</div>
                  <div className="text-sm text-gray-500">{tone.description}</div>
                </button>
              ))}
            </div>
          </div>

          <div className="bg-white rounded-lg border p-6 space-y-4">
            <h3 className="text-lg font-semibold">Additional Requirements</h3>
            <textarea
              value={additionalRequirements}
              onChange={(e) => setAdditionalRequirements(e.target.value)}
              placeholder="Any specific requirements or preferences?"
              rows={3}
              className="w-full px-3 py-2 border rounded-lg"
            />
            <button
              onClick={() => onGenerate(selectedTone, additionalRequirements)}
              disabled={loading}
              className="w-full flex items-center justify-center gap-2 px-4 py-3 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-lg hover:from-indigo-700 hover:to-purple-700 disabled:opacity-50 transition-all"
            >
              {loading ? (
                <>
                  <Loader className="h-5 w-5 animate-spin" />
                  Generating with AI Studio...
                </>
              ) : (
                <>
                  <Sparkles className="h-5 w-5" />
                  Generate with AI Studio
                </>
              )}
            </button>
          </div>
        </div>

        <div className="bg-white rounded-lg border p-6 space-y-4">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-semibold">Generated Content</h3>
            {generatedContent && (
              <button
                onClick={onSave}
                className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
              >
                Save Promotion
              </button>
            )}
          </div>

          {generatedContent ? (
            <>
              <div className="prose prose-sm max-w-none">
                <div className="whitespace-pre-wrap">{generatedContent}</div>
              </div>

              <div className="pt-4 border-t">
                <h4 className="font-medium mb-2">Follow-up Prompt</h4>
                <textarea
                  value={followUpPrompt}
                  onChange={(e) => setFollowUpPrompt(e.target.value)}
                  placeholder="Want to modify the generated content? Add your instructions here..."
                  rows={3}
                  className="w-full px-3 py-2 border rounded-lg mb-3"
                />
                <button
                  onClick={() => onGenerate(selectedTone, followUpPrompt)}
                  disabled={loading || !followUpPrompt}
                  className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 disabled:opacity-50"
                >
                  {loading ? (
                    <>
                      <Loader className="h-4 w-4 animate-spin" />
                      Regenerating...
                    </>
                  ) : (
                    'Regenerate with Follow-up'
                  )}
                </button>
              </div>
            </>
          ) : (
            <div className="h-[200px] flex items-center justify-center text-gray-500">
              Generated content will appear here
            </div>
          )}
        </div>
      </div>
    </div>
  );
}