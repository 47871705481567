import { useState, useCallback } from 'react';
import { collection, addDoc, updateDoc, doc, deleteDoc, Timestamp, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Task } from '../types';

function cleanDataForFirestore(data: any) {
  const cleaned = { ...data };

  // Convert date strings to Timestamps
  if (cleaned.dueDate) {
    cleaned.dueDate = Timestamp.fromDate(new Date(cleaned.dueDate));
  }
  if (cleaned.startDate) {
    cleaned.startDate = Timestamp.fromDate(new Date(cleaned.startDate));
  }
  if (cleaned.endDate) {
    cleaned.endDate = Timestamp.fromDate(new Date(cleaned.endDate));
  }
  if (cleaned.createdAt && !(cleaned.createdAt instanceof Timestamp)) {
    cleaned.createdAt = Timestamp.fromDate(new Date(cleaned.createdAt));
  }
  if (cleaned.updatedAt && !(cleaned.updatedAt instanceof Timestamp)) {
    cleaned.updatedAt = Timestamp.fromDate(new Date(cleaned.updatedAt));
  }

  // Ensure arrays are defined
  cleaned.files = cleaned.files || [];
  cleaned.links = cleaned.links || [];
  cleaned.tags = cleaned.tags || [];

  // Set default values for boolean fields
  cleaned.isAllDay = cleaned.isAllDay ?? true;
  cleaned.showInCalendar = cleaned.showInCalendar ?? false;

  // Remove undefined values
  Object.keys(cleaned).forEach(key => {
    if (cleaned[key] === undefined) {
      delete cleaned[key];
    }
  });

  return cleaned;
}

export function useTasks() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchTasks = useCallback(async () => {
    if (!currentUser) return [];

    try {
      setLoading(true);
      setError(null);
      const q = query(
        collection(db, 'tasks'),
        where('artistId', '==', currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const tasks = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          dueDate: data.dueDate?.toDate().toISOString(),
          startDate: data.startDate?.toDate().toISOString(),
          endDate: data.endDate?.toDate().toISOString(),
          createdAt: data.createdAt?.toDate().toISOString(),
          updatedAt: data.updatedAt?.toDate()?.toISOString(),
          links: data.links || [],
          files: data.files || [],
          tags: data.tags || [],
          isAllDay: data.isAllDay ?? true,
          showInCalendar: data.showInCalendar ?? false
        };
      }) as Task[];
      return tasks;
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  const addTask = useCallback(async (data: Omit<Task, 'id' | 'artistId' | 'createdAt'>) => {
    if (!currentUser) throw new Error('User not authenticated');

    try {
      setLoading(true);
      setError(null);
      const cleanedData = cleanDataForFirestore({
        ...data,
        artistId: currentUser.uid,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now()
      });

      const docRef = await addDoc(collection(db, 'tasks'), cleanedData);
      return docRef.id;
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  const updateTask = useCallback(async (id: string, data: Partial<Task>) => {
    if (!currentUser) throw new Error('User not authenticated');

    try {
      setLoading(true);
      setError(null);
      const cleanedData = cleanDataForFirestore({
        ...data,
        updatedAt: Timestamp.now()
      });

      const docRef = doc(db, 'tasks', id);
      await updateDoc(docRef, cleanedData);
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  const deleteTask = useCallback(async (id: string) => {
    if (!currentUser) throw new Error('User not authenticated');

    try {
      setLoading(true);
      setError(null);
      await deleteDoc(doc(db, 'tasks', id));
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  return {
    fetchTasks,
    addTask,
    updateTask,
    deleteTask,
    loading,
    error
  };
}