import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Artist } from '../types';
import LoadingSpinner from '../components/LoadingSpinner';
import ErrorMessage from '../components/ErrorMessage';
import PublicProfilePreview from '../components/PublicProfilePreview';

export default function PublicProfile() {
  const { id } = useParams<{ id: string }>();
  const [profile, setProfile] = useState<Artist | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadProfile = async () => {
      if (!id) {
        setError('Profile ID is required');
        setLoading(false);
        return;
      }

      try {
        const docRef = doc(db, 'artists', id);
        const docSnap = await getDoc(docRef);
        
        if (!docSnap.exists()) {
          setError('Profile not found');
          setLoading(false);
          return;
        }

        const profileData = docSnap.data() as Artist;
        
        if (!profileData.isPublic) {
          setError('This profile is private');
          setLoading(false);
          return;
        }

        setProfile({ id: docSnap.id, ...profileData });
      } catch (err) {
        console.error('Error loading profile:', err);
        setError('Failed to load profile');
      } finally {
        setLoading(false);
      }
    };

    loadProfile();
  }, [id]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error || !profile) {
    return <ErrorMessage message={error || 'Profile not found'} />;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container-fluid px-6 py-12">
        <PublicProfilePreview profile={profile} />
      </div>
    </div>
  );
}