import React, { useState, useEffect } from 'react';
import { Collection, GalleryImage } from '../types';
import { Image, Upload } from 'lucide-react';
import { useGallery } from '../hooks/useGallery';
import { useStorage } from '../lib/storage';
import { useProfile } from '../contexts/ProfileContext';

type CollectionFormProps = {
  onClose: () => void;
  onSubmit: (data: Partial<Collection>) => void;
  initialData?: Partial<Collection>;
  loading?: boolean;
};

export default function CollectionForm({ onClose, onSubmit, initialData, loading }: CollectionFormProps) {
  const { profile, updateProfile } = useProfile();
  const { fetchImages, addImage } = useGallery();
  const { upload } = useStorage();
  const [linkedImages, setLinkedImages] = useState<GalleryImage[]>([]);
  const [uploadingCover, setUploadingCover] = useState(false);
  const [formData, setFormData] = useState({
    name: initialData?.name || '',
    description: initialData?.description || '',
    artistStatement: initialData?.artistStatement || '',
    coverImage: initialData?.coverImage || '',
    isFeatured: profile.featuredCollectionId === initialData?.id
  });

  useEffect(() => {
    const loadLinkedImages = async () => {
      if (!initialData?.id) return;
      
      try {
        const allImages = await fetchImages();
        const images = allImages.filter(img => img.linkedCollectionId === initialData.id);
        setLinkedImages(images);
      } catch (err) {
        console.error('Error loading linked images:', err);
      }
    };
    loadLinkedImages();
  }, [initialData?.id, fetchImages]);

  const handleCoverImageUpload = async (file: File) => {
    try {
      setUploadingCover(true);
      // Upload to storage
      const url = await upload(file, 'gallery');
      
      // Add to gallery
      const imageData = {
        url,
        title: `Cover image for ${formData.name || 'collection'}`,
        description: '',
        tags: ['cover'],
        linkedCollectionId: initialData?.id
      };
      
      await addImage(imageData);
      
      // Update form data
      setFormData(prev => ({ ...prev, coverImage: url }));
      
      // Refresh linked images if we have a collection ID
      if (initialData?.id) {
        const allImages = await fetchImages();
        const images = allImages.filter(img => img.linkedCollectionId === initialData.id);
        setLinkedImages(images);
      }
    } catch (err) {
      console.error('Error uploading cover image:', err);
      alert('Failed to upload cover image. Please try again.');
    } finally {
      setUploadingCover(false);
    }
  };

  const handleSetCoverImage = (imageUrl: string) => {
    setFormData(prev => ({ ...prev, coverImage: imageUrl }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Update featured collection in profile if changed
    if (initialData?.id && formData.isFeatured !== (profile.featuredCollectionId === initialData.id)) {
      await updateProfile({
        featuredCollectionId: formData.isFeatured ? initialData.id : null
      });
    }
    
    // Submit collection data
    onSubmit({
      name: formData.name,
      description: formData.description,
      artistStatement: formData.artistStatement,
      coverImage: formData.coverImage
    });
  };

  return (
    <form onSubmit={handleSubmit} className="p-6 space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Cover Image
        </label>
        <div className="space-y-4">
          <input
            type="file"
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (file) handleCoverImageUpload(file);
            }}
            className="hidden"
            id="cover-upload"
          />
          
          <div 
            onClick={() => document.getElementById('cover-upload')?.click()}
            className="h-64 bg-gray-100 rounded-lg overflow-hidden mb-4 cursor-pointer group relative"
          >
            {formData.coverImage ? (
              <>
                <img
                  src={formData.coverImage}
                  alt="Collection cover"
                  className="w-full h-full object-cover group-hover:opacity-75 transition-opacity"
                />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all">
                  <Upload className="h-8 w-8 text-white opacity-0 group-hover:opacity-100 transition-opacity" />
                </div>
              </>
            ) : (
              <div className="w-full h-full flex flex-col items-center justify-center group-hover:bg-gray-200 transition-colors">
                <Image className="h-12 w-12 text-gray-400 mb-2" />
                <span className="text-sm text-gray-500">Click to add cover image</span>
              </div>
            )}
            {uploadingCover && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="animate-spin rounded-full h-8 w-8 border-2 border-white border-t-transparent"></div>
              </div>
            )}
          </div>

          {/* Gallery Images */}
          {linkedImages.length > 0 && (
            <div>
              <h4 className="text-sm font-medium text-gray-700 mb-2">Gallery Images</h4>
              <div className="grid grid-cols-4 gap-4">
                {linkedImages.map((image) => (
                  <div
                    key={image.id}
                    className="relative group aspect-square"
                  >
                    <img
                      src={image.url}
                      alt={image.title || 'Gallery image'}
                      className={`w-full h-full object-cover rounded-lg ${
                        formData.coverImage === image.url ? 'ring-2 ring-indigo-600' : ''
                      }`}
                    />
                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all rounded-lg">
                      <button
                        type="button"
                        onClick={() => handleSetCoverImage(image.url)}
                        className={`px-3 py-1 bg-white rounded-full text-sm font-medium ${
                          formData.coverImage === image.url
                            ? 'text-indigo-600'
                            : 'text-gray-700 opacity-0 group-hover:opacity-100'
                        } transition-opacity`}
                      >
                        {formData.coverImage === image.url ? 'Cover Image' : 'Set as Cover'}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Name
        </label>
        <input
          type="text"
          value={formData.name}
          onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Description
        </label>
        <textarea
          value={formData.description}
          onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
          rows={3}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
          placeholder="Brief description of the collection"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Artist Statement
        </label>
        <textarea
          value={formData.artistStatement}
          onChange={(e) => setFormData(prev => ({ ...prev, artistStatement: e.target.value }))}
          rows={4}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
          placeholder="Share your artistic vision and inspiration for this collection"
        />
      </div>

      {initialData?.id && (
        <div>
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={formData.isFeatured}
              onChange={(e) => setFormData(prev => ({ ...prev, isFeatured: e.target.checked }))}
              className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <span className="text-sm text-gray-700">Feature this collection on my public profile</span>
          </label>
          <p className="text-sm text-gray-500 mt-1 ml-6">
            This collection will be showcased on your public artist profile page
          </p>
        </div>
      )}

      <div className="flex justify-end gap-3 mt-6">
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 border rounded-lg hover:bg-gray-50"
          disabled={loading}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={loading || uploadingCover}
          className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
        >
          {loading ? 'Saving...' : initialData ? 'Save Changes' : 'Create Collection'}
        </button>
      </div>
    </form>
  );
}