import React, { useState } from 'react';
import { UserCircle } from 'lucide-react';
import PageHeader from '../components/PageHeader';
import { useProfile } from '../contexts/ProfileContext';
import { useAuth } from '../contexts/AuthContext';
import FileUpload from '../components/FileUpload';
import PublicProfileToggle from '../components/PublicProfileToggle';

export default function Profile() {
  const { profile, updateProfile, loading } = useProfile();
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState({
    name: profile.name || '',
    bio: profile.bio || '',
    artistStatement: profile.artistStatement || '',
    websiteUrl: profile.websiteUrl || '',
    linkedinUrl: profile.linkedinUrl || '',
    instagramUrl: profile.instagramUrl || '',
    pinterestUrl: profile.pinterestUrl || '',
    avatar: profile.avatar || '',
    companyLogo: profile.companyLogo || '',
    currency: {
      code: profile.currency?.code || 'USD',
      symbol: profile.currency?.symbol || '$'
    },
    isPublic: profile.isPublic || false
  });

  const handleInputChange = (field: string, value: any) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleCurrencyChange = (code: string) => {
    const symbol = code === 'USD' ? '$' : 
                  code === 'EUR' ? '€' : 
                  code === 'GBP' ? '£' : '$';
    
    setFormData(prev => ({
      ...prev,
      currency: { code, symbol }
    }));
  };

  const handleSave = async () => {
    try {
      await updateProfile(formData);
    } catch (error) {
      console.error('Error saving profile:', error);
    }
  };

  return (
    <div className="space-y-6">
      <PageHeader title="Profile" />
      
      <div className="bg-white rounded-lg shadow-sm border border-gray-200">
        <div className="p-6 space-y-8">
          {/* Public Profile Toggle */}
          <PublicProfileToggle
            isPublic={formData.isPublic}
            onChange={(isPublic) => handleInputChange('isPublic', isPublic)}
            profileId={currentUser?.uid}
          />

          {/* Profile Header */}
          <div className="flex items-start gap-6">
            <div className="flex-shrink-0 w-32">
              <FileUpload
                folder="profile"
                currentUrl={formData.avatar}
                onFileUpload={(url) => handleInputChange('avatar', url)}
                onFileRemove={() => handleInputChange('avatar', '')}
                accept="image/*"
                maxSize={2}
              />
              <p className="text-xs text-gray-500 mt-2 text-center">
                Profile Photo
              </p>
            </div>

            <div className="flex-1">
              <h2 className="text-xl font-semibold text-gray-900">Artist Profile</h2>
              <p className="text-gray-500">Manage your professional information</p>
            </div>
          </div>

          {/* Basic Information */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-gray-900">Basic Information</h3>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Artist Name
              </label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => handleInputChange('name', e.target.value)}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                placeholder="Your artist or business name"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Bio
              </label>
              <textarea
                value={formData.bio}
                onChange={(e) => handleInputChange('bio', e.target.value)}
                rows={3}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                placeholder="Tell us about yourself and your work"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Artist Statement
              </label>
              <textarea
                value={formData.artistStatement}
                onChange={(e) => handleInputChange('artistStatement', e.target.value)}
                rows={4}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                placeholder="Share your artistic vision and approach"
              />
            </div>
          </div>

          {/* Online Presence */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-gray-900">Online Presence</h3>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Website URL
              </label>
              <input
                type="url"
                value={formData.websiteUrl}
                onChange={(e) => handleInputChange('websiteUrl', e.target.value)}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                placeholder="https://your-website.com"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                LinkedIn Profile
              </label>
              <input
                type="url"
                value={formData.linkedinUrl}
                onChange={(e) => handleInputChange('linkedinUrl', e.target.value)}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                placeholder="https://linkedin.com/in/your-profile"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Instagram Profile
              </label>
              <input
                type="url"
                value={formData.instagramUrl}
                onChange={(e) => handleInputChange('instagramUrl', e.target.value)}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                placeholder="https://instagram.com/your-profile"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Pinterest Profile
              </label>
              <input
                type="url"
                value={formData.pinterestUrl}
                onChange={(e) => handleInputChange('pinterestUrl', e.target.value)}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                placeholder="https://pinterest.com/your-profile"
              />
            </div>
          </div>

          {/* Branding */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-gray-900">Branding</h3>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Company Logo
              </label>
              <div className="w-48">
                <FileUpload
                  folder="profile"
                  currentUrl={formData.companyLogo}
                  onFileUpload={(url) => handleInputChange('companyLogo', url)}
                  onFileRemove={() => handleInputChange('companyLogo', '')}
                  accept="image/*"
                  maxSize={2}
                />
              </div>
            </div>
          </div>

          {/* Currency Settings */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-gray-900">Currency Settings</h3>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Display Currency
              </label>
              <select
                value={formData.currency.code}
                onChange={(e) => handleCurrencyChange(e.target.value)}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
              >
                <option value="USD">USD ($)</option>
                <option value="EUR">EUR (€)</option>
                <option value="GBP">GBP (£)</option>
              </select>
            </div>
          </div>

          {/* Save Button */}
          <div className="flex justify-end pt-6 border-t">
            <button
              onClick={handleSave}
              disabled={loading}
              className="px-6 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
            >
              {loading ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}